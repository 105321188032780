














































/**
 * Input for Vue.js
 *
 * @copyright Wallboard Inc. 2019
 * @version V1.0.1
 * @lastModified Gabor Fabian 2021.May.04.
 *
 * @author  Gabor Fabian, David Fabian
 * @utf-8   ő
 */
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { IModelValue, InputTypeTypes } from '@/services/common/form-validate.service';
import wbInputGrowl from '../wb-input-growl/wb-input-growl.vue';

    enum StatusTypes {
        success = 'success',
        danger = 'error',
    }

@Component({
	components : { wbInputGrowl, },
})
export default class WbInput extends Vue {
	public statusTypes = StatusTypes;

	@Prop()
	public model!: IModelValue;

	@Prop({ default : '', })
	public reference!: string;

	@Prop({ default : '', })
	public iconLeft!: string;

	@Prop({ default : '', })
	public textLeft!: string;

	@Prop({ default : '', })
	public iconRight!: string;

	@Prop({ default : '', })
	public textRight!: string;

	// Output onInput: EventEmitter
	// Output onEnter: EventEmitter

	public inputType = 'text';

	public created(): void {
		// eslint-disable-next-line no-console
		console.log('%c INIT uiElements.Inputs.WbInput ', 'background: blue; color: #FFF');

		if (this.model.type === InputTypeTypes.password) {
			this.inputType = 'password';
		}
	}

	public destroyed(): void {
		// eslint-disable-next-line no-console
		console.info('%c DESTROY uiElements.Inputs.WbInput ', 'background: purple; color: #FFF');
	}

	// Event is InputEvent
	public handleInput(event: Event): void {
		const inputValue = (event.target as HTMLInputElement).value;
		if (this.model.type === 'int') {
			(event.target as HTMLInputElement).value = this.filterInt(inputValue);
		} else if (this.model.type === 'uInt') {
			(event.target as HTMLInputElement).value = this.filterInt(inputValue, true);
		} else if (this.model.type === 'float') {
			(event.target as HTMLInputElement).value = this.filterFloat(inputValue);
		} else if (this.model.type === 'uFloat') {
			(event.target as HTMLInputElement).value = this.filterFloat(inputValue, true);
		}

		this.$emit('on-input', (event.target as HTMLInputElement).value);
	}

	public handleKeyDown(event: KeyboardEvent): void {
		if (event.which === 13) {
			this.$emit('on-enter');
		}
	}

	private filterInt(value: string | string, unsigned = false): string {
		const patternZeros = /^(0+[1-9][0-9]*)$/;
		const pattern = (unsigned === true) ? /^(0|[1-9][0-9]*)$/ : /^(-|0|0-|-*[1-9][0-9]*)$/;

		let filteredValue: number | string = +value;

		// Filter n first zero
		if (patternZeros.test(value)) {
			value = value.replace(/^[0]+/g, '');
			filteredValue = +value;
		}

		if (unsigned === false && (value === '0-' || value === '-')) {
			filteredValue = '-';
		} else if (!pattern.test(value) && value !== '') {
			// Filter all character except 0-9 and -
			const signOfTheNumber = (value.charAt(0) === '-' && unsigned === false) ? -1 : 1;

			filteredValue = +value.replace(/[^0-9]/g, '');
			filteredValue = signOfTheNumber * filteredValue;
		}

		return '' + filteredValue;
	}

	private filterFloat(value: string | string, unsigned = false): string {
		const patternZeros = /^(0+[1-9][0-9]*)$/;
		const pattern = (unsigned === true)
			? /^(0|0\.[0-9]*|[1-9][0-9]*|[1-9][0-9]*\.[0-9]*)$/
			: /^(-|0|0-|-{0,1}[1-9][0-9]*|-{0,1}[1-9][0-9]*\.[0-9]*|-{0,1}0\.[0-9]*)$/;
		const patternPart = (unsigned === true)
			? /^(0\.0*|[1-9][0-9]*\.0*|0\.0*|[1-9][0-9]*\.|0\.0*|[1-9][0-9]*\.[0-9]*0+)$/
			: /^(-|-0|-{0,1}0\.0*|-{0,1}[1-9][0-9]*\.|-{0,1}[1-9][0-9]*\.[0-9]*0+)$/;

		let filteredValue: number | string = +value;

		// Filter n first zero
		if (patternZeros.test(value)) {
			value = value.replace(/^[0]+/g, '');
			filteredValue = +value;
		}

		// Fix , as .
		if (value.indexOf(',') > -1) {
			value = value.replace(/,/g, '.');
		}

		if (unsigned === false && (value === '0-' || value === '-')) {
			// returns -
			filteredValue = '-';
		} else if (patternPart.test(value)) {
			// returns -, -0, 0.0..., -0.0...
			filteredValue = value;
		} else if (!pattern.test(value) && value !== '') {
			// Filter all character except 0-9, - and first dot
			const signOfTheNumber = (value.charAt(0) === '-' && unsigned === false) ? -1 : 1;
			const firstDot = value.search(/\./) + 1; // Index of first occurance of (.)

			// Clear all dots except the first
			value = value.substr(0, firstDot) + value.slice(firstDot).replace(/\./g, '');

			// Clear all character except 0-9, .
			filteredValue = +value.replace(/[^0-9.]/g, '');
			filteredValue = signOfTheNumber * filteredValue;
		}

		return '' + filteredValue;
	}

	@Watch('model', { immediate : true, deep : true, })
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	private onModelChanged(val: IModelValue, oldVal: IModelValue) {
		if (val.focus !== undefined && val.focus === true) {
			(this.$refs.input as HTMLInputElement).focus();
		}
	}
}
