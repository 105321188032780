































import { Component, Vue } from 'vue-property-decorator';
import { ButtonStatusTypes, PanelHeaderStatusTypes } from '@/services/common/ui.service';

@Component({
	name : 'wb-logout-modal',
	components : {
	},
})
export default class WbLogoutModal extends Vue {
	public panelHeaderStatusTypes = PanelHeaderStatusTypes;
	public buttonStatusTypes = ButtonStatusTypes;

	/**
	 * CREATED
	 */
	public created(): void {
		// eslint-disable-next-line no-console
		console.info('%c CREATE WbLogoutModal ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbLogoutModal ', 'background: purple; color: #FFF');
	}

	public logout(): void {
		this.$auth.logout();
	}
}
