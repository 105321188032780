/**
 * StoreService for Vue.js
 * @copyright Wallboard Inc. 2019
 * @version V1.0.0
 * @author Gabor Fabian, Tamas Dreissiger
 */

import localForage from 'localforage';

export interface IPersistentStoreService {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setState: (moduleName: string, key: string, value: any) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	getState: (moduleName: string, key: string) => any;
	init: () => Promise<void>;
	clear: () => Promise<void>;
}

interface IState {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: any;
}

export class PersistentStoreService implements IPersistentStoreService {
	private state: IState = {};

	public constructor() {
		localForage.config({
			driver : localForage.INDEXEDDB, // Force WebSQL; same as using setDriver()
			name : 'wbSensorsApp',
			version : 1.0,
			size : 4980736, // Size of database, in bytes. WebSQL-only for now.
			storeName : 'wbSensorStore', // Should be alphanumeric, with underscores.
			description : '...',
		});

		// eslint-disable-next-line no-console
		console.info('%c INIT SERVICE PERSISTENT STORE ', 'background: green; color: #FFF');
	}

	public setState(moduleName = 'global', key: string, value: boolean | string | number): void {
		const fullKey = `${moduleName}.${key}`;

		this.state[fullKey as keyof IState] = value;

		localForage.setItem(fullKey, value)
			.catch((err) => {
				// eslint-disable-next-line no-console
				console.error(err);
			});
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public getState(moduleName = 'global', key: string): any {
		const fullKey = `${moduleName}.${key}`;
		return this.state[fullKey as keyof IState];
	}

	public clear(): Promise<void> {
		return localForage.clear().then(() => {
			this.state = {};
		});
	}

	public init(): Promise<void> {
		return localForage.iterate((value, key) => {
			this.state[key as keyof IState] = value;
		});
	}
}
