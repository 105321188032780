











/**
 * Button Text
 *
 * @copyright Wallboard Inc. 2019
 * @version V1.0.0
 * @author  Gabor Fabian, David Fabian
 * @utf-8   ő
 */
import { Component, Vue, Prop } from 'vue-property-decorator';
import { StatusTypes, SizeTypes } from '@/services/common/ui.service';

@Component
export default class WbButtonText extends Vue {
	@Prop({ default : '', })
	public label!: string;

	@Prop({ default : '', })
	public icon!: string;

	@Prop({ default : true, })
	public underline!: boolean;

	@Prop({ default : '', })
	public reference!: string;

	@Prop({ default : StatusTypes.primary, })
	public status!: string;

	@Prop({ default : SizeTypes.normal, })
	public size!: string;

	/**
	 * CREATED
	 */
	public created(): void {
		// eslint-disable-next-line no-console
		console.log('%c INIT uiElements.WbButtonText ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		// eslint-disable-next-line no-console
		console.info('%c DESTROY uiElements.WbButtonText ', 'background: purple; color: #FFF');
	}
}
