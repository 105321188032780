

























/**
 * Checkbox
 *
 * @copyright Wallboard Inc. 2019
 * @version V1.0.0
 * @author  Gabor Fabian, David Fabian
 * @utf-8   ő
 */
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

import { IModelValue } from '@/services/common/form-validate.service';
import wbInputGrowl from '../wb-input-growl/wb-input-growl.vue';

enum StatusTypes {
	success = 'success',
	danger = 'error',
}

@Component({
	components : { wbInputGrowl, },
})
export default class WbCheckbox extends Vue {
	public statusTypes = StatusTypes;

	@Prop()
	public model!: IModelValue;

	@Prop({ default : '', })
	public reference!: string;

	// Output onInput: EventEmitter
	/**
	 * CREATED
	 */
	public created(): void {
		// eslint-disable-next-line no-console
		console.info('%c INIT uiElements.Inputs.WbCheckbox ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		// eslint-disable-next-line no-console
		console.info('%c DESTROY uiElements.Inputs.WbCheckbox ', 'background: purple; color: #FFF');
	}

	@Emit('on-input')
	public onInput(): string | number | boolean {
		return !this.model.value;
	}
}
