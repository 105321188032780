import { Observable } from 'rxjs';

import { HttpService } from '@/services/common/http.service';

import { IListDto } from '@/services/dtos/shared.dto';

export enum SystemLogTypes{
	INFO = 'info',
	ERROR = 'error',
	CRON = 'cron'
}

enum UserRoles {
	SUPERADMIN = '0',
	ADMIN = '1',
	USER = '2'
}

export enum UserLogTypes {
	ACTIVE_TIME_SPENT = 'activeTimeSpent',
	FIRMWARE_BURNT = 'firmwareBurnt',
	CONFIG_SAVED = 'configSaved',
	CONFIG_LOADED = 'configLoaded',
	CONFIG_DOWNLOADED = 'configDownloaded',
	CONFIG_UPLOADED = 'configUploaded',
	CONFIG_UPLOADED_TO_DEVICE = 'configUploadedToDevice',
	DEVICE_CONNECTED = 'deviceConnected',
	LOGGED_IN = 'loggedIn',
	LOGGED_OUT = 'loggedOut',
	BALANCE_CHANGED = 'balanceChanged',
	FLASHER_DOWNLOADED = 'flasherDownloaded',
	BALANCE_TOP_UP_REQUESTED = 'balanceTopUpRequested',
	DEVICE_REGISTERED ='deviceRegistered',
	USER_MODIFIED = 'userModified',
	USER_PASSWORD_CHANGED = 'userPasswordChanged',
}

enum DeviceType {
	AI = 'ai',
	atom = 'atom',
	DN_73 = 'dn_73',
	DN_74 = 'dn_74',
	DN_75 = 'dn_75',
	DN_76 = 'dn_76',
	m5stack = 'm5stack',
	m5stick = 'm5stick',
	m5stickC = 'm5stick_c',
	m5stackFire = 'm5stack_fire',
	m5stackPlus = 'm5stack_plus',
	m5stackUsbHost = 'm5stack_usb_host',
	m5stackPlusUsbHost = 'm5stack_plus_usb_host',
}
// TODO: Need interface for each use-case!! (FE, BE)
interface IUserLogDetails {
	[key: string]: any;
}

export interface IUserLog {
	id: number;
	user: {
		id: string;
		email: string;
		firstname: string;
		lastname: string;
		organization: string;
		role: UserRoles;
		enabled: boolean;
		isVerified: boolean;
		createdAt: Date;
		updatedAt: Date;
	};
	type: UserLogTypes;
	amount: number;
	details: IUserLogDetails | null;
	note: string;
	createdAt: Date;
}

// <editor-fold desc="DTOs">
export interface IListSystemLogDto extends IListDto {
	type?: SystemLogTypes;
}
export interface IListUserLogDto extends IListDto {
	type?: UserLogTypes;
}
// </editor-fold desc="DTOs">

// <editor-fold desc="Payloads">
export interface ISystemAdditionalData {
	name: string;
	email: string;
	error?: string;
}

export interface ISystemLog {
	id: number;
	type: SystemLogTypes;
	description: string;
	value: string | null;
	additionalData: ISystemAdditionalData | null;
	createdAt: Date;
}

export interface IListSystemLogPayload {
	count: number;
	systemLogs: ISystemLog[];
}

export interface IListUserLogPayload {
	count: number;
	userLogs: IUserLog[];
}
// </editor-fold desc="Payloads">

export interface ILogApiService {
	getSystemLog(
		listSystemLogDto: Partial<IListSystemLogDto> & Pick<IListSystemLogDto, 'page' | 'pageLength'>
	): Observable<IListSystemLogPayload>;
	getUserLog(
		listUserLogDto: Partial<IListUserLogDto> & Pick<IListUserLogDto, 'page' | 'pageLength'>
	): Observable<IListUserLogPayload>;
}

export class LogApiService implements ILogApiService {
	private $http: HttpService;

	private serviceRootUrl = '/api/log';

	constructor(_http: HttpService) {
		this.$http = _http;
	}

	public getSystemLog(
		listSystemLogDto: Partial<IListSystemLogDto> & Pick<IListSystemLogDto, 'page' | 'pageLength'>
	): Observable<IListSystemLogPayload> {
		return this.$http.get(
			'getSystemLog',
			`${this.serviceRootUrl}/system`,
			{
				params : listSystemLogDto,
			}
		);
	}

	public getUserLog(
		listUserLogDto: Partial<IListUserLogDto> & Pick<IListUserLogDto, 'page' | 'pageLength'>
	): Observable<IListUserLogPayload> {
		return this.$http.get(
			'getUserLog',
			`${this.serviceRootUrl}/user`,
			{
				params : listUserLogDto,
			}
		);
	}
}
