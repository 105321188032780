/**
 * ToasterStoreService for Vue.js
 * @copyright Wallboard Inc. 2019
 * @basedOn toaster.service
 * @version V1.0.0
 * @author Gabor Fabian, Gergo Fucskar
 */
import { BehaviorSubject, Observable } from 'rxjs';

export interface IToast {
    id?: number;
    message: string;
    type?: string;
    duration?: number;
}

export interface IToasterStoreService {
	toasts$: Observable<IToast[]>;
    create(toast: IToast): void;
    remove(toast: IToast, now: boolean): void;
}

export class ToasterStoreService implements IToasterStoreService {
	/**
	 * State
	 */

	private toasts = new BehaviorSubject<IToast[]>([]);

	/**
	 * Selectors
	 */

	public toasts$ = this.toasts.asObservable();

	/**
	 * CONSTRUCTOR
	 */
	public constructor() {
		// eslint-disable-next-line no-console
		console.log('%c INIT SERVICE TOASTER ', 'background: green; color: #FFF');

		this.fixScope();
		this.initState();
	}

	private fixScope(): void {
		this.create = this.create.bind(this);
		this.remove = this.remove.bind(this);
	}

	/* eslint-disable-next-line @typescript-eslint/no-empty-function */
	private initState(): void {

	}

	/**
	 * Updaters
	 */

	private setToasts(toasts: IToast[]) {
		this.toasts.next(toasts);
	}

	/**
	 * Getters
	 */

	private getToasts(): IToast[] {
		return this.toasts.getValue();
	}

	/**
	 * Effects
	 */

	public create(toast: IToast): void {
		const toasts = this.getToasts();

		const newToast = {
			id : toast.id || new Date().getTime() + Math.floor(Math.random() * (1000000 - 1 + 1) + 1),
			message : toast.message,
			type : toast.type || 'primary',
			duration : toast.duration || 5000,
		};
		toasts.push(newToast);

		this.setToasts(toasts);

		this.remove(newToast, false);
	}

	public remove(toast: IToast, now: boolean): void {
		const duration = now ? 0 : toast.duration;
		setTimeout(() => {
			let toasts = this.getToasts();
			toasts = toasts.filter((toastItem: IToast) => toastItem.id !== toast.id);

			this.setToasts(toasts);
		}, duration);
	}

	/**
	 * Helper
	 */
}
