/**
 * EventBus service for Vue.js
 * @copyright Wallboard Inc. 2019
 * @version V1.0.0
 * @author Gabor Fabian
 */

// <editor-fold desc="Enums: ">

// </editor-fold desc="Enums: ">

// <editor-fold desc="Interface: IRegionalFormatService">

export interface IRegionalFormatService {
    formatTime(timeStamp: number | Date): string;
}

// </editor-fold desc="Interface: IRegionalFormatService">

export class RegionalFormatService implements IRegionalFormatService {
	/**
	 * CONSTRUCTOR
	 */
	public constructor() {
		// eslint-disable-next-line no-console
		console.log('%c INIT SERVICE REGIONAL FORMAT', 'background: green; color: #FFF');
	}

	public formatTime(timeStamp: number | Date): string {
		if (timeStamp) {
			const date = new Date(timeStamp);
			let finalDate = '';
			finalDate += date.getFullYear();
			finalDate += '-';
			finalDate += (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
			finalDate += '-';
			finalDate += date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
			finalDate += ' ';
			finalDate += date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
			finalDate += ':';
			finalDate += date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
			return finalDate;
		} else {
			return '\u00A0';
		}
	}
}
