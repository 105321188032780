


























/**
 * Modal
 *
 * @copyright Wallboard Inc. 2019
 * @version V1.1.0
 * @lastModified Gabor Fabian 2021.May.27.
 *
 * @author  Gabor Fabian, David Fabian
 * @utf-8   ő
 */
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ModalStatusTypes } from '@/services/common/ui.service';
import WbTransformable from '../../wb-transformable/wb-transformable.vue';
import { Portal } from '@linusborg/vue-simple-portal';

export enum PositionTypes {
	center,
	end,
}

export enum ColorTypes {
	primary,
	warning,
	danger,
}

@Component({
	name : 'wb-modal',
	components : {
		WbTransformable,
		Portal,
	},
})
export default class WbModal extends Vue {
	@Prop({ default : '', })
	public modalClassName!: string;

	@Prop({ default : ModalStatusTypes.primary, })
	public status!: ModalStatusTypes;

	@Prop({ default : '450px', })
	public minWidth!: string;

	@Prop({ default : '150px', })
	public minHeight!: string;

	@Prop({ default : 'auto', })
	public width!: string;

	@Prop({ default : 'auto', })
	public height!: string;

	@Prop({ default : '90vw', })
	public maxWidth!: string;

	@Prop({ default : '90vh', })
	public maxHeight!: string;

	/**
	 * CREATED
	 */
	public created(): void {
		// eslint-disable-next-line no-console
		console.log('%c INIT uiElements.WbModal ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		// eslint-disable-next-line no-console
		console.info('%c DESTROY uiElements.WbModal ', 'background: purple; color: #FFF');
	}

	public close(/* e: MouseEvent */): void{
		this.$emit('on-close');
	}
}
