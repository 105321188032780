import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { HttpService } from '@/services/common/http.service';
import { IPersistentStoreService } from '@/services/common/persistent-store.service';
import { IToasterStoreService } from '@/services/common/toaster-store.service';

import {
	IBalanceApiService,
	ITopUpCustomerBalanceDto,
	IWithdrawCustomerBalanceDto,
	IRequestTopUpDto,
	IListUserPaymentHistoryPayload,
	IBalancePayload,
	IListUserPaymentHistoryDto
} from './api/balance-api.service';
import { IRequestPayload } from '@/services/dtos/shared.dto';
import { ResponseMessage } from '@/services/interfaces/message.interface';
import {
	BalanceRequestTypes, IBalanceRequestApiService,
	IChangeStatusDto, IListBalanceRequestDto,
	IListBalanceRequestPayload
} from '@/services/api/balance-request-api.service';

export interface IBalanceStoreService {
	paymentHistories$: Observable<IListUserPaymentHistoryPayload | null>;
	balance$: Observable<IBalancePayload | null>;
	balanceRequest$: Observable<IListBalanceRequestPayload | null>;
	pendingBalanceRequestCount$: Observable<number>;
	requestResult$: Observable<IRequestPayload | null>;

	topUpCustomerBalance(topUp: ITopUpCustomerBalanceDto): void;

	withdrawCustomerBalance(withdraw: IWithdrawCustomerBalanceDto): void;

	requestTopUp(topUp: IRequestTopUpDto): void;

	changeRequestStatusToGrant(changeStatusDto: IChangeStatusDto): void;

	changeRequestStatusToReject(changeStatusDto: IChangeStatusDto): void;

	listOneUserPaymentHistory(listOneUserPaymentHistory: IListUserPaymentHistoryDto): void;

	listBalanceRequest(listBalanceRequest: IListBalanceRequestDto): void;

	getPendingBalanceRequestCount(): void;

	getOwnBalance(): void;

	resetPaymentHistory(): void;

	resetRequestResult(): void;
}

export class BalanceStoreService implements IBalanceStoreService {
	private $store: IPersistentStoreService;
	private $http: HttpService;
	private $toaster: IToasterStoreService;
	private $balanceApiService: IBalanceApiService;
	private $balanceRequestService: IBalanceRequestApiService;

	private moduleName = 'BalanceService';

	// TODO: MISSING responseMessage, we need fix it
	private lang: Omit<ResponseMessage, 'internal_server_error' | 'cannot_give_privilege_to_user' | 'email_already_in_use'> = {
		already_verified : 'This email address has already been verified.',
		invalid_old_password : 'Please check your current password and try again.',
		password_too_short : 'Your new password must be at least 8 characters long.',
		password_weak : 'Your password is too weak. Please include at least 2 uppercase letters, 2 digits, and 2 special characters.',
		invalid_flasher : 'The selected app was not found.',
		insufficient_privilege : 'You do not have sufficient privileges to perform this action.',
		insufficient_role : 'Your current role does not have the necessary privileges for this action.',
	}

	/**
	 * State
	 *
	 * Can be behaviour subject or variable.
	 */

	private paymentHistories = new BehaviorSubject<IListUserPaymentHistoryPayload | null>(null);
	private balanceRequests = new BehaviorSubject<IListBalanceRequestPayload | null>(null);
	private pendingBalanceRequestCount = new BehaviorSubject<number>(0);
	private balance = new BehaviorSubject<IBalancePayload | null>(null);
	private requestResult = new BehaviorSubject<IRequestPayload | null>(null);

	/**
	 * Selectors
	 *
	 * Always public.
	 * For the changes needs to be subscribed.
	 */

	public paymentHistories$ = this.paymentHistories.asObservable();
	public balanceRequest$ = this.balanceRequests.asObservable();
	public pendingBalanceRequestCount$ = this.pendingBalanceRequestCount.asObservable();
	public balance$ = this.balance.asObservable();
	public requestResult$ = this.requestResult.asObservable();

	/**
	 * CONSTRUCTOR
	 */
	constructor(
		_store: IPersistentStoreService,
		_http: HttpService,
		_toaster: IToasterStoreService,
		_balanceApiService: IBalanceApiService,
		_balanceRequestApiService: IBalanceRequestApiService
	) {
		// eslint-disable-next-line no-console
		console.info('%c INIT STORE-SERVICE BALANCE ', 'background: green; color: #FFF');

		// Get Params
		this.$store = _store;
		this.$http = _http;
		this.$balanceApiService = _balanceApiService;
		this.$balanceRequestService = _balanceRequestApiService;
		this.$toaster = _toaster;

		this.fixScope();
		this.initState();
	}

	private fixScope(): void {
		// I know it is empty
	}

	private initState(): void {
		// I know it is empty
	}

	/**
	 * Updaters
	 */

	public resetRequestResult(): void {
		this.requestResult.next(null);
	}

	private setRequestResult(requestPayload: IRequestPayload) {
		this.requestResult.next(requestPayload);
	}

	public resetPaymentHistory(): void {
		this.paymentHistories.next(null);
	}

	private setPaymentHistory(listPaymentHistory: IListUserPaymentHistoryPayload): void {
		this.paymentHistories.next(listPaymentHistory);
	}

	private setBalanceRequest(listBalanceRequest: IListBalanceRequestPayload): void {
		this.balanceRequests.next(listBalanceRequest);
	}

	private setPendingBalanceRequestCounter(listBalanceRequest: IListBalanceRequestPayload): void {
		this.pendingBalanceRequestCount.next(listBalanceRequest.count);
	}

	public resetBalance(): void {
		this.balance.next(null);
	}

	private setBalance(balancePayload: IBalancePayload): void {
		this.balance.next(balancePayload);
	}

	/**
	 * Getters
	 */

	/**
	 * Effects
	 */

	public topUpCustomerBalance(topUp: ITopUpCustomerBalanceDto): void {
		this.$balanceApiService.topUpCustomerBalance(topUp).pipe(
			tap((/* balance: ITopUpCustomerPayload */) => {
				this.setRequestResult({
					status : 'success',
					actor : 'topUp',
				});

				this.$toaster.create({
					type : 'success',
					message : 'Balance is changed',
				});
			}),
			catchError((error) => {
				const code: keyof Omit<ResponseMessage, 'internal_server_error' | 'insufficient_role' | 'cannot_give_privilege_to_user' | 'email_already_in_use'> =
					(error.response) ? error.response.message : '';

				this.$toaster.create({
					type : 'danger',
					message : this.lang[code],
				});

				this.setRequestResult({
					status : 'error',
					actor : 'topUp',
				});

				return of(null);
			})
		).subscribe();
	}

	public requestTopUp(topUp: IRequestTopUpDto): void {
		this.$balanceApiService.requestTopUp(topUp).pipe(
			tap((/* balance: ITopUpCustomerPayload */) => {
				this.setRequestResult({
					status : 'success',
					actor : 'requestTopUp',
				});

				this.$toaster.create({
					type : 'success',
					message : 'Sent request for top up',
				});
			}),
			catchError((error) => {
				const code: keyof Omit<ResponseMessage, 'internal_server_error' | 'insufficient_role' | 'cannot_give_privilege_to_user' | 'email_already_in_use'> =
					(error.response) ? error.response.message : '';

				this.$toaster.create({
					type : 'danger',
					message : this.lang[code],
				});

				this.setRequestResult({
					status : 'error',
					actor : 'requestTopUp',
				});

				return of(null);
			})
		).subscribe();
	}

	public withdrawCustomerBalance(withdraw: IWithdrawCustomerBalanceDto): void {
		this.$balanceApiService.withdrawCustomerBalance(withdraw).pipe(
			tap((/* balance: IWithdrawCustomerPayload */) => {
				this.setRequestResult({
					status : 'success',
					actor : 'withdraw',
				});

				this.$toaster.create({
					type : 'success',
					message : 'Balance is changed',
				});
			}),
			catchError((error) => {
				const code: keyof Omit<ResponseMessage, 'internal_server_error' | 'insufficient_role' | 'cannot_give_privilege_to_user' | 'email_already_in_use' | 'insufficient_privilege'> =
					(error.response) ? error.response.message : '';

				this.$toaster.create({
					type : 'danger',
					message : this.lang[code],
				});

				this.setRequestResult({
					status : 'error',
					actor : 'withdraw',
				});

				return of(null);
			})
		).subscribe();
	}

	public listOneUserPaymentHistory(listOneUserPaymentHistoryDto: IListUserPaymentHistoryDto): void {
		this.$balanceApiService.getOneUserPaymentHistoryOrOwn(listOneUserPaymentHistoryDto)
			.pipe(
				tap((paymentHistory: IListUserPaymentHistoryPayload) => {
					this.setPaymentHistory(paymentHistory);
				})
			).subscribe();
	}

	public listBalanceRequest(listBalanceRequest: IListBalanceRequestDto): void {
		this.$balanceRequestService.getListBalanceRequest(listBalanceRequest)
			.pipe(
				tap((balanceRequest: IListBalanceRequestPayload) => {
					this.setBalanceRequest(balanceRequest);
				})
			).subscribe();
	}

	public getPendingBalanceRequestCount(): void {
		this.$balanceRequestService.getListBalanceRequest(
			{
				page : 1,
				pageLength : 999,
				type : BalanceRequestTypes.PENDING,
			})
			.pipe(
				tap((balanceRequest: IListBalanceRequestPayload) => {
					this.setPendingBalanceRequestCounter(balanceRequest);
				})
			).subscribe();
	}

	public changeRequestStatusToGrant(changeStatus: IChangeStatusDto): void {
		this.$balanceRequestService.changeRequestStatusToGrant(changeStatus)
			.pipe(
				tap(() => {
					this.setRequestResult({
						status : 'success',
						actor : 'changeStatus',
					});

					this.$toaster.create({
						type : 'success',
						message : 'Order status changed to GRANTED',
					});
				}),
				catchError((error) => {
					const code: keyof Omit<ResponseMessage, 'internal_server_error' | 'insufficient_role' | 'cannot_give_privilege_to_user' | 'email_already_in_use'> =
						(error.response) ? error.response.message : '';

					this.$toaster.create({
						type : 'danger',
						message : this.lang[code],
					});

					this.setRequestResult({
						status : 'error',
						actor : 'changeStatus',
					});

					return of(null);
				})
			).subscribe();
	}

	public changeRequestStatusToReject(changeStatus: IChangeStatusDto): void {
		this.$balanceRequestService.changeRequestStatusToReject(changeStatus)
			.pipe(
				tap(() => {
					this.setRequestResult({
						status : 'success',
						actor : 'changeStatus',
					});

					this.$toaster.create({
						type : 'success',
						message : 'Order status changed to REJECTED',
					});
				}),
				catchError((error) => {
					const code: keyof Omit<ResponseMessage, 'internal_server_error' | 'insufficient_role' | 'cannot_give_privilege_to_user' | 'email_already_in_use'> =
						(error.response) ? error.response.message : '';

					this.$toaster.create({
						type : 'danger',
						message : this.lang[code],
					});

					this.setRequestResult({
						status : 'error',
						actor : 'changeStatus',
					});

					return of(null);
				})
			).subscribe();
	}

	public getOwnBalance(): void {
		this.$balanceApiService.getOwnBalance()
			.pipe(
				tap((balance: IBalancePayload) => {
					this.setBalance(balance);
				})
			).subscribe();
	}

	/**
	 * Helper
	 *
	 * Helper, filter, etc functions
	 */
}
