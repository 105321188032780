import Vue from 'vue';
import VueRouter, { RawLocation } from 'vue-router';
import { Route } from 'vue-router/types/router';
import WbApp from './views/wb-app/wb-app.vue';

/* SERVICES */
import { HttpService, IHttpService } from '@/services/common/http.service';
import { UiService, IUiService } from '@/services/common/ui.service';
import { PersistentStoreService, IPersistentStoreService } from '@/services/common/persistent-store.service';
import { FormValidateService, IValidateService } from './services/common/form-validate.service';
import { ToasterStoreService, IToasterStoreService } from '@/services/common/toaster-store.service';
import { RegionalFormatService, IRegionalFormatService } from '@/services/common/regional-format.service';
import { GoogleCaptchaService, IGoogleCaptchaService } from '@/services/common/google-captcha.service';

import { RouterService } from './services/router.service';

import { IMenuService, MenuService } from '@/services/menu-store.service';

import { AuthApiService } from '@/services/api/auth-api.service';
import { AuthStoreService, IAuthStoreService } from '@/services/auth-store.service';

/* Api.Services and Services */
import { UserApiService } from '@/services/api/user-api.service';
import { AppAndFirmwareApiService } from '@/services/api/app-and-firmware-api.service';
import { BalanceApiService } from '@/services/api/balance-api.service';
import { PriceGroupApiService } from '@/services/api/price-group-api.service';
import { LogApiService } from '@/services/api/log-api.service';

import { IUserStoreService, UserStoreService } from '@/services/user-store.service';
import { IAppAndFirmwareStoreService, AppAndFirmwareStoreService } from '@/services/app-and-firmware-store.service';
import { IBalanceStoreService, BalanceStoreService } from '@/services/balance-store.service';
import { IPricesStoreService, PricesStoreService } from '@/services/prices-store.service';
import { AndroidFirmwareApiService, IAndroidFirmwareApiService } from '@/services/api/android-firmware-api.service';
import { ILogStoreService, LogStoreService } from '@/services/log-store.service';
import { BalanceRequestApiService } from '@/services/api/balance-request-api.service';

/* globally reachable components */
import uiComponents from './components/ui-elements/ui-elements';

/* STYLES */
import './styles.scss';

// VUE ROUTE
const { isNavigationFailure, NavigationFailureType, } = VueRouter;

declare module 'vue/types/vue' {
    interface Vue {
		$http: IHttpService;
		$ui: IUiService;
		$store: IPersistentStoreService;
		$formValidation: IValidateService;
		$toaster: IToasterStoreService;
		$auth: IAuthStoreService;
		$menu: IMenuService;
		$region: IRegionalFormatService;
		$googleCaptcha: IGoogleCaptchaService;
		$services: {
			user: IUserStoreService;
			appAndFirmware: IAppAndFirmwareStoreService;
			androidFirmware: IAndroidFirmwareApiService;
			balance: IBalanceStoreService;
			prices: IPricesStoreService;
			logs: ILogStoreService;
        };
    }
}

Vue.config.productionTip = false;

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
const startVueApp = () => {
	const persistentStoreService = new PersistentStoreService();

	persistentStoreService.init().then(() => {
		// Common services
		const httpService = new HttpService();
		const uiService = new UiService();
		const regionalFormatService = new RegionalFormatService();
		const formValidateService = new FormValidateService();
		const toaster = new ToasterStoreService();
		const googleCaptcha = new GoogleCaptchaService();

		// Api services
		const authApiService = new AuthApiService(httpService);
		const userApiService = new UserApiService(httpService);
		const balanceApiService = new BalanceApiService(httpService);
		const balanceRequestApiService = new BalanceRequestApiService(httpService);
		const priceGroupApiService = new PriceGroupApiService(httpService);
		const appAndFirmwareApiService = new AppAndFirmwareApiService(httpService);
		const androidFirmwareApiService = new AndroidFirmwareApiService(httpService);
		const logApiService = new LogApiService(httpService);

		// Auth service
		const authStoreService = new AuthStoreService(persistentStoreService, httpService, authApiService);

		// Other Services
		const userStoreService = new UserStoreService(persistentStoreService, httpService, toaster, userApiService);
		const balanceStoreService = new BalanceStoreService(persistentStoreService, httpService, toaster, balanceApiService, balanceRequestApiService);
		const pricesStoreService = new PricesStoreService(persistentStoreService, httpService, toaster, priceGroupApiService);
		const appAndFirmwareStoreService = new AppAndFirmwareStoreService(persistentStoreService, httpService, toaster, appAndFirmwareApiService, androidFirmwareApiService);
		const logStoreService = new LogStoreService(persistentStoreService, httpService, toaster, logApiService);

		const menuService = new MenuService(authStoreService);
		const routerService = new RouterService(authStoreService, menuService);

		/* services */
		Vue.prototype.$store = persistentStoreService;
		Vue.prototype.$toaster = toaster;
		Vue.prototype.$http = httpService;
		Vue.prototype.$googleCaptcha = googleCaptcha;
		Vue.prototype.$ui = uiService;
		Vue.prototype.$region = regionalFormatService;
		Vue.prototype.$menu = menuService;
		Vue.prototype.$auth = authStoreService;
		Vue.prototype.$formValidation = formValidateService;
		Vue.prototype.$services = {
			user : userStoreService,
			balance : balanceStoreService,
			prices : pricesStoreService,
			appAndFirmware : appAndFirmwareStoreService,
			androidFirmware : androidFirmwareApiService,
			logs : logStoreService,
		};
		/* services */

		/* globally reachable vue components */
		Object.keys(uiComponents).forEach((tag: string) => {
			// eslint-disable-next-line
            // @ts-ignore
			Vue.component(tag, uiComponents[tag]);
		});
		/* globally reachable vue components */

		Vue.use(VueRouter);

		// Catch duplicated error
		const originalPush = VueRouter.prototype.push;
		VueRouter.prototype.push = function push(location: RawLocation) {
			const p = (originalPush.call<VueRouter, [RawLocation], Promise<Route>>(this, location));
			p.catch((error) => {
				if (!isNavigationFailure(error, NavigationFailureType.duplicated)) {
					// eslint-disable-next-line no-console
					console.error('ROUTER ERROR: ', error);
					throw Error(error);
				}
			});

			return p;
		};

		new Vue({
			router : routerService.getRouter(),
			render : (h) => h(WbApp),
		}).$mount('[vue-app]');
	}).catch((err) => {
		// eslint-disable-next-line no-console
		console.error(err);
	});
};

startVueApp();
