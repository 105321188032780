import { Observable } from 'rxjs';

import { HttpService } from '@/services/common/http.service';

import { IListDto, IRequestPayload } from '@/services/dtos/shared.dto';

import { IUser, UserLogTypes } from '@/services/payloads/user.payload';

// <editor-fold desc="DTOs">
export type IListOwnPaymentHistoryDto = IListDto;

export interface IListOneUserPaymentHistoryDto {
	id?: number;
}

export interface IListUserPaymentHistoryDto extends IListDto{
	id?: number;
}

export interface ITopUpCustomerBalanceDto {
	id: number;
	amount: number;
}

export interface IRequestTopUpDto {
	amount: number;
}

export interface IWithdrawCustomerBalanceDto {
	id: number;
	amount: number;
}
// </editor-fold desc="DTOs">

// <editor-fold desc="Payloads">
export interface IPaymentHistory {
	id: number;
	user: IUser;
	type: UserLogTypes;
	amount: number;
	details: {[key: string]: string | number};
	note: string;
	createdAt: Date;
}

export interface IListUserPaymentHistoryPayload {
	count: number;
	userLogs: IPaymentHistory[];
}

export interface IBalancePayload {
	balance: number;
}
// </editor-fold desc="Payloads">

export interface IBalanceApiService {
	requestTopUp(topUpDto: IRequestTopUpDto): Observable<IRequestPayload>;
	topUpCustomerBalance(addUserDto: ITopUpCustomerBalanceDto): Observable<IRequestPayload>;
	withdrawCustomerBalance(withdraw: ITopUpCustomerBalanceDto): Observable<IRequestPayload>;

	getOwnBalance(): Observable<IBalancePayload>;

	getOneUserPaymentHistoryOrOwn(listBalance: IListOneUserPaymentHistoryDto): Observable<IListUserPaymentHistoryPayload>;
}

export class BalanceApiService implements IBalanceApiService {
	private $http: HttpService;

	private serviceRootUrl = '/api/balance';

	constructor(_http: HttpService) {
		this.$http = _http;
	}

	public requestTopUp(topUpDto: IRequestTopUpDto): Observable<IRequestPayload> {
		return this.$http.post(
			'requestTopUp',
			`${this.serviceRootUrl}/request-top-up`,
			{
				body : topUpDto,
			}
		);
	}

	public topUpCustomerBalance(topUpDto: ITopUpCustomerBalanceDto): Observable<IRequestPayload> {
		return this.$http.post(
			'topUpCustomerBalance',
			`${this.serviceRootUrl}/top-up/${topUpDto.id}`,
			{
				body : topUpDto,
			}
		);
	}

	public withdrawCustomerBalance(withdraw: ITopUpCustomerBalanceDto): Observable<IRequestPayload> {
		return this.$http.post(
			'withdrawCustomerBalance',
			`${this.serviceRootUrl}/withdraw/${withdraw.id}`,
			{
				body : withdraw,
			}
		);
	}

	public getOwnBalance(): Observable<IBalancePayload> {
		return this.$http.get(
			'getOwnBalance',
			`${this.serviceRootUrl}`
		);
	}

	public getOneUserPaymentHistoryOrOwn(listBalance: IListUserPaymentHistoryDto): Observable<IListUserPaymentHistoryPayload> {
		return this.$http.get(
			'getOneUserPaymentHistoryOrOwn',
			`${this.serviceRootUrl}/history/${listBalance.id || ''}`,
			{
				params : listBalance as Record<keyof IListOneUserPaymentHistoryDto, number>,
			}
		);
	}
}
