












/**
 * Modal Header
 *
 * @copyright Wallboard Inc. 2019
 * @version V1.1.0
 * @lastModified Gabor Fabian 2021.may.13.
 *
 * @author  Gabor Fabian, David Fabian
 * @utf-8   ő
 */
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
	components : {},
})
export default class WbModalHeader extends Vue {
	@Prop({ default : '', })
	public status!: string;

	@Prop({ default : true, })
	public underline!: boolean;

	@Prop({ default : false, })
	public noX!: boolean;

	/**
	 * CREATED
	 */
	public created(): void {
		// eslint-disable-next-line no-console
		console.log('%c INIT uiElements.WbModalHeader ', 'background: blue; color: #FFF');

		window.addEventListener('keydown', this.handleKeyDown);
	}

	public destroyed(): void {
		// eslint-disable-next-line no-console
		console.info('%c DESTROY uiElements.WbModalHeader ', 'background: purple; color: #FFF');

		window.removeEventListener('keydown', this.handleKeyDown);
	}

	private handleKeyDown(e: KeyboardEvent) {
		if (e.key === 'Escape') {
			this.$emit('on-close');
		}
	}
}
