






















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
	name : 'wb-horizontal-menu-logout',
})
export default class WbHorizontalMenuLogout extends Vue {
	@Prop({ default : false, })
	public list!: boolean;

	@Prop({ default : '', })
	public imgSrc!: string;

	@Prop({ default : '', })
	public name!: string;

	@Prop({ default : '', })
	public version!: string;

	/**
	 * CREATED
	 */
	public created(): void {
		// eslint-disable-next-line no-console
		console.info('%c CREATE WbHorizontalMenuLogout ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbHorizontalMenuLogout ', 'background: purple; color: #FFF');
	}
}
