import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { HttpService } from '@/services/common/http.service';
import { IPersistentStoreService } from '@/services/common/persistent-store.service';
import { IToasterStoreService } from '@/services/common/toaster-store.service';

import { IUser, IListUsersPayload, IListOneUserPayload } from '@/services/payloads/user.payload';
import {
	IUserApiService,
	IListUsersDto,
	IListOneUserDto,
	IAddUserDto,
	IModifyUserDto,
	IDeleteUserDto,
	IDisableUserDto,
	IChangeOwnPasswordUserDto,
	IModifyProfileDto,
	IModifyUserPrivilegesDto
} from './api/user-api.service';
import { IRequestPayload } from '@/services/dtos/shared.dto';

import { ResponseMessage } from '@/services/interfaces/message.interface';

/* Payloads */

export interface IRegisterUserPayload {
	id: number;
}

export interface IListUsersBalancePayload {
	count: number;
	users: IUser[];
}

export interface IAddUserPayload {
	id: number;
}

export interface IModifyUserPayload {
	id: number;
}

export interface IDisableUserPayload {
	id: number;
}

export interface IDeleteUserPayload {
	id: number;
}

export interface IChangeOwnPasswordUserPayload {
	status: string;
}

export interface IResendConfirmationEmailUserPayload {
	status: string;
}

/* Payloads */

export interface IUserStoreService {
	users$: Observable<IListUsersPayload | null>;
	usersBalance$: Observable<IListUsersPayload | null>;
	selectedUser$: Observable<IListOneUserPayload | null>;
	profile$: Observable<IListOneUserPayload | null>;
	requestResult$: Observable<IRequestPayload | null>;

	listUsers(listUsers: IListUsersDto): void;
	listUsersBalance(listUsers: IListUsersDto): void;
	listOneUser(listOneUser: IListOneUserDto): void;
	addUser(user: IAddUserDto): void;
	allowUser(user: Pick<IDisableUserDto, 'id'>): void;
	blockUser(user: Pick<IDisableUserDto, 'id'>): void;
	modifyUser(user: Partial<IModifyUserDto>): void;
	modifyUserPrivileges(user: Partial<IModifyUserPrivilegesDto>): void;
	deleteUser(user: IDeleteUserDto): void;

	getProfile(): void;
	changeOwnPassword(user: IChangeOwnPasswordUserDto): void;
	modifyProfile(user: Partial<Pick<IModifyProfileDto, 'firstname' | 'lastname' | 'organization'>>): void;

	resendConfirmationEmail(user: IUser): void;

	resetSelectedUser(): void;
	resetRequestResult(): void;
}

export class UserStoreService implements IUserStoreService {
	private $store: IPersistentStoreService;
	private $http: HttpService;
	private $toaster: IToasterStoreService;
	private $userApiService: IUserApiService;

	private moduleName = 'UserService';

	private lang: Omit<ResponseMessage, 'invalid_flasher' | 'email_already_in_use'> = {
		already_verified : 'User email address has already verified.',
		invalid_old_password : 'You mistyped your current password.',
		password_too_short : 'Your new password must be at least 8 characters long.',
		password_weak : 'Your password is too weak: It should contains at least 2 uppercase letters, 2 digits and 2 special characters',
		insufficient_role : 'You don\'t have permission to do this.',
		internal_server_error : 'Something went wrong, please contact the administrator',
		cannot_give_privilege_to_user : 'This privilege can not be set to this user',
		insufficient_privilege : 'You do not have sufficient privileges to perform this action.',
	}

	/**
	 * State
	 *
	 * Can be behaviour subject or variable.
	 */

	private users = new BehaviorSubject<IListUsersPayload | null>(null);
	private usersBalance = new BehaviorSubject<IListUsersBalancePayload | null>(null);
	private selectedUser = new BehaviorSubject<IListOneUserPayload | null>(null);
	private profile = new BehaviorSubject<IListOneUserPayload | null>(null);
	private requestResult = new BehaviorSubject<IRequestPayload | null>(null);

	/**
	 * Selectors
	 *
	 * Always public.
	 * For the changes needs to be subscribed.
	 */

	public users$ = this.users.asObservable();
	public usersBalance$ = this.usersBalance.asObservable();
	public selectedUser$ = this.selectedUser.asObservable();
	public profile$ = this.profile.asObservable();
	public requestResult$ = this.requestResult.asObservable();

	/**
	 * CONSTRUCTOR
	 */
	constructor(
		_store: IPersistentStoreService,
		_http: HttpService,
		_toaster: IToasterStoreService,
		_userApiService: IUserApiService
	) {
		// eslint-disable-next-line no-console
		console.info('%c INIT STORE-SERVICE USER ', 'background: green; color: #FFF');

		// Get Params
		this.$store = _store;
		this.$http = _http;
		this.$userApiService = _userApiService;
		this.$toaster = _toaster;

		this.fixScope();
		this.initState();
	}

	private fixScope(): void {
		// I know it is empty
	}

	private initState(): void {
		// I know it is empty
	}

	/**
	 * Updaters
	 */

	private setUsers(users: IListUsersPayload) {
		this.users.next(users);
	}

	private setUsersBalance(users: IListUsersBalancePayload) {
		this.usersBalance.next(users);
	}

	public resetSelectedUser(): void {
		this.selectedUser.next(null);
	}

	private setSelectedUser(user: IListOneUserPayload) {
		this.selectedUser.next(user);
	}

	private setProfile(profile: IListOneUserPayload) {
		this.profile.next(profile);
	}

	public resetRequestResult(): void {
		this.requestResult.next(null);
	}

	private setRequestResult(requestPayload: IRequestPayload) {
		this.requestResult.next(requestPayload);
	}

	/**
	 * Getters
	 */
	public getUsers(): IListUsersPayload | null {
		return this.users.getValue();
	}

	public getUsersBalance(): IListUsersBalancePayload | null {
		return this.usersBalance.getValue();
	}

	public getSelectedUser(): IListOneUserPayload | null {
		return this.selectedUser.getValue();
	}

	/**
	 * Effects
	 */

	public listUsers(listUsersDto: Partial<IListUsersDto> & Pick<IListUsersDto, 'pageLength' | 'page'>): void {
		this.$userApiService.getUsers(listUsersDto)
			.pipe(
				tap((users: IListUsersPayload) => {
					this.setUsers(users);
				})
			).subscribe();
	}

	public listUsersBalance(listUsersDto: Partial<IListUsersDto> & Pick<IListUsersDto, 'pageLength' | 'page'>): void {
		this.$userApiService.getUsersBalance(listUsersDto)
			.pipe(
				tap((users: IListUsersBalancePayload) => {
					this.setUsersBalance(users);
				})
			).subscribe();
	}

	public listOneUser(listOneUser: IListOneUserDto): void {
		this.$userApiService.getOneUser(listOneUser)
			.pipe(
				tap((user) => {
					this.setSelectedUser(user);
				})
			).subscribe();
	}

	public getProfile(): void {
		this.$userApiService.getProfile()
			.pipe(
				tap((profile) => {
					this.setProfile(profile);
				})
			).subscribe();
	}

	public addUser(user: IAddUserDto): void {
		this.$userApiService.addUser(user).pipe(
			tap((/* user: IAddUserPayload */) => {
				this.setRequestResult({ status : 'success', actor : 'addUser', });

				this.$toaster.create({
					type : 'success',
					message : 'User is added',
				});
			}),
			catchError((error) => {
				const code: keyof Omit<ResponseMessage, 'invalid_flasher' | 'email_already_in_use'> = (error.response) ? error.response.message : '';

				this.$toaster.create({
					type : 'danger',
					message : this.lang[code],
				});

				this.setRequestResult({
					status : 'error',
					actor : 'addUser',
				});

				return of(null);
			})
		).subscribe();
	}

	public modifyUser(user: Partial<IModifyUserDto>): void {
		this.$userApiService.modifyUser(user).pipe(
			tap((/* user: IModifyUserPayload */) => {
				this.setRequestResult({ status : 'success', actor : 'modifyUser', });

				this.$toaster.create({
					type : 'success',
					message : 'User is updated',
				});
			}),
			catchError((error) => {
				const code: keyof Omit<ResponseMessage, 'invalid_flasher' | 'email_already_in_use'> = (error.response) ? error.response.message : '';

				this.$toaster.create({
					type : 'danger',
					message : this.lang[code],
				});

				this.setRequestResult({
					status : 'error',
					actor : 'modifyUser',
				});

				return of(null);
			})
		).subscribe();
	}

	public modifyUserPrivileges(user: Partial<IModifyUserPrivilegesDto>): void {
		this.$userApiService.modifyUserPrivileges(user).pipe(
			tap((/* user: IModifyUserPayload */) => {
				this.setRequestResult({
					status : 'success',
					actor : 'modifyUserPrivileges',
				});

				this.$toaster.create({
					type : 'success',
					message : 'User privileges is updated',
				});
			}),
			catchError((error) => {
				const code: keyof Omit<ResponseMessage, 'invalid_flasher' | 'email_already_in_use'> = (error.response) ? error.response.message : '';

				this.$toaster.create({
					type : 'danger',
					message : this.lang[code],
				});

				this.setRequestResult({
					status : 'error',
					actor : 'modifyUserPrivileges',
				});

				return of(null);
			})
		).subscribe();
	}

	public allowUser(user: Pick<IDisableUserDto, 'id'>): void {
		this.$userApiService.modifyUser({
			id : user.id, isEnabled : true,
		}).pipe(
			tap((/* user: IDisableUserPayload */) => {
				this.setRequestResult({ status : 'success', actor : 'allowUser', });
			}),
			catchError((error) => {
				const code: keyof Omit<ResponseMessage, 'invalid_flasher' | 'email_already_in_use'> = (error.response) ? error.response.message : '';

				this.$toaster.create({
					type : 'danger',
					message : this.lang[code],
				});

				this.setRequestResult({
					status : 'error',
					actor : 'addUser',
				});

				return of(null);
			})
		).subscribe();
	}

	public blockUser(user: Pick<IDisableUserDto, 'id'>): void {
		this.$userApiService.modifyUser({
			id : user.id, isEnabled : false,
		}).pipe(
			tap((/* user: IDisableUserPayload */) => {
				this.setRequestResult({ status : 'success', actor : 'blockUser', });
			}),
			catchError((error) => {
				const code: keyof Omit<ResponseMessage, 'invalid_flasher' | 'email_already_in_use'> = (error.response) ? error.response.message : '';

				this.$toaster.create({
					type : 'danger',
					message : this.lang[code],
				});

				this.setRequestResult({
					status : 'error',
					actor : 'addUser',
				});

				return of(null);
			})
		).subscribe();
	}

	public deleteUser(user: IDeleteUserDto): void {
		this.$userApiService.deleteUser(user).pipe(
			tap((/* user: IModifyUserPayload */) => {
				this.setRequestResult({ status : 'success', actor : 'deleteUser', });
			}),
			catchError((error) => {
				const code: keyof Omit<ResponseMessage, 'invalid_flasher' | 'email_already_in_use'> = (error.response) ? error.response.message : '';

				this.$toaster.create({
					type : 'danger',
					message : this.lang[code],
				});

				this.setRequestResult({
					status : 'error',
					actor : 'deleteUser',
				});

				return of(null);
			})
		).subscribe();
	}

	public resendConfirmationEmail(user: IUser): void {
		this.$userApiService.resendConfirmationEmail({ id : user.id, }).pipe(
			tap((/* confirmationStatus: IResendConfirmationEmailUserPayload */) => {
				this.$toaster.create({
					type : 'success',
					message : `Confirmation Email is sent to ${user.email}.`,
				});

				this.setRequestResult({
					status : 'success',
					actor : 'resendConfirmationEmail',
				});
			}),
			catchError((error) => {
				const code: keyof Omit<ResponseMessage, 'invalid_flasher' | 'email_already_in_use'> = (error.response) ? error.response.message : '';

				this.$toaster.create({
					type : 'danger',
					message : this.lang[code],
				});

				this.setRequestResult({
					status : 'error',
					actor : 'resendConfirmationEmail',
				});

				return of(null);
			})
		).subscribe();
	}

	public changeOwnPassword(user: IChangeOwnPasswordUserDto): void {
		this.$userApiService.changeOwnPassword(user).pipe(
			tap((/* confirmationStatus: IChangeOwnPasswordUserPayload */) => {
				this.$toaster.create({
					type : 'success',
					message : 'Password is changed successfully',
				});

				this.setRequestResult({
					status : 'success',
					actor : 'changeOwnPassword',
				});
			}),
			catchError((error) => {
				const code: keyof Omit<ResponseMessage, 'invalid_flasher' | 'email_already_in_use'> = (error.response) ? error.response.message : '';

				this.$toaster.create({
					type : 'danger',
					message : this.lang[code],
				});

				this.setRequestResult({
					status : 'error',
					actor : 'invalidOldPassword',
				});

				return of(null);
			})
		).subscribe();
	}

	public modifyProfile(user: Partial<Pick<IModifyProfileDto, 'firstname' | 'lastname' | 'organization'>>): void {
		this.$userApiService.modifyProfile(user).pipe(
			tap((/* user: IModifyUserPayload */) => {
				this.setRequestResult({ status : 'success', actor : 'modifyProfile', });

				this.$toaster.create({
					type : 'success',
					message : 'Profile is updated',
				});
			}),
			catchError((error) => {
				const code: keyof Omit<ResponseMessage, 'invalid_flasher' | 'email_already_in_use'> = (error.response) ? error.response.message : '';

				this.$toaster.create({
					type : 'danger',
					message : this.lang[code],
				});

				this.setRequestResult({
					status : 'error',
					actor : 'modifyProfile',
				});

				return of(null);
			})
		).subscribe();
	}

	/**
	 * Helper
	 *
	 * Helper, filter, etc functions
	 */
}
