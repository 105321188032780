

















/**
 * Select Simple
 *
 * @copyright Wallboard Inc. 2019
 * @version V1.0.0
 * @author  Gabor Fabian, David Fabian
 * @utf-8   ő
 */
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

import { IModelValue } from '@/services/common/form-validate.service';
import WbInputGrowl from '../wb-input-growl/wb-input-growl.vue';
import WbSelectSimpleList from './wb-select-simple-list/wb-select-simple-list.vue';

interface IContainerClass {
	top: boolean;
	bottom: boolean;
}
interface IClasses {
	container: IContainerClass;
}

@Component({
	name : 'wb-select-simple',
	components : {
		WbSelectSimpleList,
		WbInputGrowl,
	},
})
export default class WbSelectSimple extends Vue {
	@Prop()
	public model!: IModelValue;

	public isDropdownOpen = false;

	public classes: IClasses = {
		container : {
			top : false,
			bottom : false,
		},
	};

	/**
	 * CREATED
	 */
	public created(): void {
		// eslint-disable-next-line no-console
		console.info('%c CREATE WbSelectSimple ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbSelectSimple ', 'background: purple; color: #FFF');
	}

	public findLabel(): string {
		let label = '';
		if (this.model.options) {
			this.model.options.forEach((option) => {
				if (this.model.value === option.value) {
					label = option.label;
				}
			});
		}

		return label;
	}

	public onShowList(direction: string): void {
		Object.keys(this.classes.container).forEach((directionFromContainer: string) => {
			this.classes.container[directionFromContainer as keyof IContainerClass] = false;
		});
		this.classes.container[direction as keyof IContainerClass] = true;
	}

	// Outputs

	@Emit('on-select')
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private onSelect(option: any): void {
		return option;
	}
	// Outputs
}
