













import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

@Component({
	name : 'wb-growl',
})
export default class WbGrowl extends Vue {
	@Prop({ default : 'primary', })
	public status!: string;

	@Prop({ default : true, })
	public controller!: boolean;

	/**
	 * CREATED
	 */
	public created(): void {
		// eslint-disable-next-line no-console
		console.log('%c CREATE WbGrowl ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbGrowl ', 'background: purple; color: #FFF');
	}

	@Emit('on-remove')
	public onRemove(): boolean {
		return true;
	}
}
