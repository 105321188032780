import { Observable } from 'rxjs';

import { HttpService } from '@/services/common/http.service';

import { IListDto, IRequestPayload } from '@/services/dtos/shared.dto';

import { IUser } from '@/services/payloads/user.payload';

export enum BalanceRequestTypes {
	PENDING = 'PENDING',
	GRANTED = 'GRANTED',
	REJECTED = 'REJECTED'
}

// <editor-fold desc="DTOs">
export interface IListBalanceRequestDto extends IListDto{
	type?: BalanceRequestTypes;
}

export interface IChangeStatusDto {
	balanceRequestId: number;
}
// </editor-fold desc="DTOs">

export interface IBalanceRequest {
	id: number;
	user: IUser;
	amount: number;
	status: BalanceRequestTypes;
	createdAt: Date;
}

// <editor-fold desc="Payloads">
export interface IListBalanceRequestPayload{
	count: number;
	balanceRequests: IBalanceRequest[];
}
// </editor-fold desc="Payloads">

export interface IBalanceRequestApiService {
	changeRequestStatusToGrant(changeStatusDto: IChangeStatusDto): Observable<IRequestPayload>;
	changeRequestStatusToReject(changeStatusDto: IChangeStatusDto): Observable<IRequestPayload>;

	getListBalanceRequest(
		listBalanceRequest: Partial<IListBalanceRequestDto> & Pick<IListBalanceRequestDto, 'pageLength' | 'page'>
	): Observable<IListBalanceRequestPayload>;
}

export class BalanceRequestApiService implements IBalanceRequestApiService {
	private $http: HttpService;

	private serviceRootUrl = '/api/balance-request'

	constructor(_http: HttpService) {
		this.$http = _http;
	}

	changeRequestStatusToGrant(changeStatusDto: IChangeStatusDto): Observable<IRequestPayload> {
		return this.$http.put(
			'changeRequestStatusToGrant',
			`${this.serviceRootUrl}/grant/${changeStatusDto.balanceRequestId}`,
			{
				body : changeStatusDto,
			}
		);
	}

	changeRequestStatusToReject(changeStatusDto: IChangeStatusDto): Observable<IRequestPayload> {
		return this.$http.put(
			'changeRequestStatusToReject',
			`${this.serviceRootUrl}/reject/${changeStatusDto.balanceRequestId}`,
			{
				body : changeStatusDto,
			}
		);
	}

	getListBalanceRequest(
		listBalanceRequest: Partial<IListBalanceRequestDto> & Pick<IListBalanceRequestDto, 'pageLength' | 'page'>
	): Observable<IListBalanceRequestPayload> {
		return this.$http.get(
			'getListBalanceRequest',
			`${this.serviceRootUrl}`,
			{
				params : listBalanceRequest,
			}
		);
	}
}
