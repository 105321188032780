






import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
	name : 'wb-position-element',
})
export default class WbPositionElement extends Vue {
	@Prop({ default : 'row', })
	public direction!: string;

	@Prop({ default : 'start', })
	public horizontal!: string;

	@Prop({ default : 'start', })
	public vertical!: string;

	@Prop({ default : '0', })
	public gap!: string;

	@Prop({ default : false, })
	public wrap!: boolean;

	/**
	 * CREATED
	 */
	public created(): void {
		// eslint-disable-next-line no-console
		console.info('%c INIT uiElements.WbPositionElement ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		// eslint-disable-next-line no-console
		console.info('%c DESTROY uiElements.WbPositionElement ', 'background: purple; color: #FFF');
	}
}
