import { BehaviorSubject, Observable } from 'rxjs';

import { AuthStoreService } from './auth-store.service';
import { UserRoles } from '@/services/payloads/user.payload';

export interface IMenu {
    routeName: string;
    action: string;
    label: string;
    tooltip: string;
    active: boolean;
    liClass: string;
    class: string;
    img: string;
    imgSet: string;
    icon: string;
    separator: boolean;
    isSubMenuOpen: boolean;
    children: IMenu[];
    requiredRoles: UserRoles[];
}

export interface IMenuService {
	menus$: Observable<IMenu[]>;

	reload(): void;
	closeAllSubMenus: (menuItems?: IMenu[]) => void;
}

export class MenuService implements IMenuService {
	private $auth: AuthStoreService;

	/**
	 * State
	 */
	private allMenus: IMenu[] = [];
	private menus = new BehaviorSubject<IMenu[]>([]);

	/**
	 * Selectors
	 */

	public menus$ = this.menus.asObservable();

	/**
	 * CONSTRUCTOR
	 */
	public constructor(
		_authService: AuthStoreService
	) {
		// eslint-disable-next-line no-console
		console.info('%c INIT SERVICE MENU ', 'background: green; color: #FFF');

		this.$auth = _authService;

		this.fixScope();
		this.initState();
	}

	private fixScope(): void {
		// I know it is empty
	}

	private initState(): void {
		this.setAllMenus([
			// Admin menus
			{
				routeName : 'adminHome',
				action : '',
				label : 'Home',
				tooltip : 'Home',
				active : true,
				liClass : '',
				class : 'text',
				img : '',
				imgSet : '',
				icon : '',
				separator : true,
				isSubMenuOpen : false,
				children : [],
				requiredRoles : [UserRoles.SUPERADMIN, UserRoles.ADMIN],
			},
			{
				routeName : 'adminUsers',
				action : '',
				label : 'Users',
				tooltip : 'Users',
				active : false,
				liClass : '',
				class : 'text',
				img : '',
				imgSet : '',
				icon : '',
				separator : true,
				isSubMenuOpen : false,
				children : [],
				requiredRoles : [UserRoles.SUPERADMIN, UserRoles.ADMIN],
			},
			{
				routeName : 'adminBalance',
				action : '',
				label : 'Payment History',
				tooltip : 'Payment History',
				active : false,
				liClass : '',
				class : 'text',
				img : '',
				imgSet : '',
				icon : '',
				separator : true,
				isSubMenuOpen : false,
				children : [],
				requiredRoles : [UserRoles.SUPERADMIN, UserRoles.ADMIN],
			},
			{
				routeName : 'adminAppsAndFirmwares',
				action : '',
				label : 'Apps and Firmwares',
				tooltip : 'Apps and Firmwares',
				active : false,
				liClass : '',
				class : 'text',
				img : '',
				imgSet : '',
				icon : '',
				separator : true,
				isSubMenuOpen : false,
				children : [],
				requiredRoles : [UserRoles.SUPERADMIN, UserRoles.ADMIN],
			},
			{
				routeName : 'adminLog',
				action : '',
				label : 'Log',
				tooltip : 'Log',
				active : false,
				liClass : '',
				class : 'text',
				img : '',
				imgSet : '',
				icon : '',
				separator : true,
				isSubMenuOpen : false,
				children : [],
				requiredRoles : [UserRoles.SUPERADMIN, UserRoles.ADMIN],
			},
			{
				routeName : 'adminProfile',
				action : '',
				label : 'Profile',
				tooltip : 'Profile',
				active : false,
				liClass : '',
				class : 'text',
				img : '',
				imgSet : '',
				icon : '',
				separator : true,
				isSubMenuOpen : false,
				children : [],
				requiredRoles : [UserRoles.ADMIN, UserRoles.SUPERADMIN],
			},
			// User menus
			{
				routeName : 'userHome',
				action : '',
				label : 'Home',
				tooltip : 'Home',
				active : true,
				liClass : '',
				class : 'text',
				img : '',
				imgSet : '',
				icon : '',
				separator : true,
				isSubMenuOpen : false,
				children : [],
				requiredRoles : [UserRoles.USER],
			},
			{
				routeName : 'userProfile',
				action : '',
				label : 'Profile',
				tooltip : 'Profile',
				active : false,
				liClass : '',
				class : 'text',
				img : '',
				imgSet : '',
				icon : '',
				separator : true,
				isSubMenuOpen : false,
				children : [],
				requiredRoles : [UserRoles.USER],
			}
			// All role menus
			/* {
				routeName : 'wbLogout',
				action : '',
				label : 'Logout',
				tooltip : 'Logout',
				active : false,
				liClass : '',
				class : 'text',
				img : '',
				imgSet : '',
				icon : '',
				separator : true,
				isSubMenuOpen : false,
				children : [],
				requiredRoles : [],
			} */
		]);
	}

	/**
	 * Updaters
	 */
	private setAllMenus(allMenus: IMenu[]) {
		this.allMenus = allMenus;
	}

	private setMenus(menus: IMenu[]) {
		this.menus.next(menus);
	}

	/**
	 * Getters
	 */
	public getAllMenus(): IMenu[] {
		return this.allMenus;
	}

	public getMenus(): IMenu[] {
		return this.menus.getValue();
	}

	/**
	 * Effects
	 */

	public reload(): void {
		const filteredByRoleMenus: IMenu[] = [];
		this.getAllMenus().forEach((menuItem: IMenu) => {
			if (menuItem.requiredRoles.length) {
				if (this.checkMenuItemForRequiredRoles(menuItem)) {
					filteredByRoleMenus.push(menuItem);
				}
			} else {
				filteredByRoleMenus.push(menuItem);
			}

			this.setMenus(filteredByRoleMenus);
		});
	}

	public closeAllSubMenus(/* menuItems?: IMenu[] */): void {
		// TODO: refactor
		/* menuItems = menuItems || this.menus;
		menuItems.forEach((menuItem: IMenu) => {
			if (menuItem.children.length) {
				this.closeAllSubMenus(menuItem.children);
			}
			menuItem.isSubMenuOpen = false;
		}); */
	}

	/**
	 * Helper
	 */

	private checkMenuItemForRequiredRoles(menuItem: IMenu): boolean {
		let validForRequiredRoles = false;
		const userRole: UserRoles = this.$auth.getUserRole();

		menuItem.requiredRoles.forEach((role) => {
			if (userRole === role) {
				validForRequiredRoles = true;
			}
		});

		return validForRequiredRoles;
	}
}
