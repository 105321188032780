import { Vue } from 'vue-property-decorator';

/* <editor-fold desc="Enums: Modal"> --------------------------------------- */

export enum ModalStatusTypes {
    primary = 'primary',
    secondary = 'secondary',
    info = 'info',
    success = 'success',
    warning = 'warning',
    danger = 'danger',
    transparent = 'transparent',
}

export enum ModalFooterHorizontalPosition {
    start = 'start',
    center = 'center',
    end = 'end',
}

/* </editor-fold desc="Enums: Modal"> -------------------------------------- */

/* <editor-fold desc="Enums: Button"> -------------------------------------- */

export enum ButtonStatusTypes {
    primary = 'primary',
    secondary = 'secondary',
    info = 'info',
    success = 'success',
    warning = 'warning',
    danger = 'danger',
    disabled = 'disabled',
}

export enum ButtonSizeTypes {
    small = 'small',
    normal = 'normal',
    large = 'large',
    large2 = 'large-2',
}

/* </editor-fold desc="Enums: Button"> ------------------------------------- */

/* <editor-fold desc="Enums: Panels"> -------------------------------------- */

export enum PanelStatusTypes {
    primary = 'primary',
    secondary = 'secondary',
    info = 'info',
    success = 'success',
    warning = 'warning',
    danger = 'danger',
    transparent = 'transparent',
}

export enum PanelHeaderStatusTypes {
    primary = 'primary',
    secondary = 'secondary',
    info = 'info',
    success = 'success',
    warning = 'warning',
    danger = 'danger',
    transparent = 'transparent',
}

export enum PanelFooterHorizontalPosition {
    start = 'start',
    center = 'center',
    end = 'end',
}

/* </editor-fold desc="Enums: Panels"> ------------------------------------- */

/* <editor-fold desc="Enums: Cards"> --------------------------------------- */

export enum CardStatusTypes {
    primary = 'primary',
    secondary = 'secondary',
    info = 'info',
    success = 'success',
    warning = 'warning',
    danger = 'danger',
    transparent = 'transparent',
}

export enum CardFooterHorizontalPosition {
    start = 'start',
    center = 'center',
    end = 'end',
}

/* </editor-fold desc="Enums: Cards"> -------------------------------------- */

/* <editor-fold desc="Enums: Form elements"> ------------------------------- */

/* <editor-fold desc="Enums: Form group"> ---------------------------------- */

export enum FormGroupOrientationTypes {
    horizontal = 'horizontal',
    vertical = 'vertical',
}

/* </editor-fold desc="Enums: Form group"> --------------------------------- */

/* <editor-fold desc="Enums: Form item"> ----------------------------------- */

export enum FormItemOrientationTypes {
    horizontal = 'horizontal',
    vertical = 'vertical',
}

/* </editor-fold desc="Enums: Form item"> ---------------------------------- */

/* <editor-fold desc="Enums: Input group"> --------------------------------- */

export enum InputGroupGap {
    gapNo = 'gap-no',
    gapNormal = 'gap-normal',
    gapLarge = 'gap-large',
    gapLarge2 = 'gap-large-2',
}

export enum InputGroupAlignItems {
    stretch = 'stretch',
    start = 'start',
    center = 'center',
    end = 'end',

}

/* </editor-fold desc="Enums: Input group"> -------------------------------- */

/* </editor-fold desc="Enums: Form elements"> ------------------------------ */

// <editor-fold desc="Enums: StatusTypes">

export enum StatusTypes {
    default = 'default',
    primary = 'primary',
    info = 'info',
    success = 'success',
    warning = 'warning',
    danger = 'danger',
    secondary = 'secondary',
    disabled = 'disabled',
    white = 'white',
}

export enum SizeTypes {
    small2 = 'small-2',
    small = 'small',
    normal = 'normal',
    large = 'large',
    large2 = 'large-2',
}

export enum BusyIndicatorSizeTypes {
    small = 'small',
    normal = 'normal',
    large = 'large',
    large2 = 'large-2',
}

export enum BusyIndicatorModes {
    inner = 'inner',
    outer = 'outer',
    fullWindow = 'full-window',
}

export enum BusyIndicatorTypes {
    icon = 'icon',
    img = 'img',
}

export enum RowXAlignTypes {
    left = 'left',
    center = 'center',
    right = 'right',
    apart = 'apart',
    evenly = 'evenly',
}

export enum RowYAlignTypes {
    up = 'up',
    center = 'center',
    down = 'down',
    fill = 'fill',
}

export enum ColumnXAlignTypes {
    left = 'left',
    center = 'center',
    right = 'right',
    fill = 'fill',
}

export enum ColumnYAlignTypes {
    up = 'up',
    center = 'center',
    down = 'down',
    apart = 'apart',
    evenly = 'evenly',
}

// </editor-fold desc="Enums: StatusTypes">

export interface IUiService {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setMovableBeingMoved(movableBeingMoved: any): void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	getMovableBeingMoved(): any;
	seeIfAncestorsHaveOutline<V extends Vue & {outline: boolean}>(component: V): void;
}

export class UiService {
	/**
	 * CONSTRUCTOR
	 */
	public constructor() {
		// eslint-disable-next-line no-console
		console.info('%c INIT SERVICE UI ', 'background: green; color: #FFF');
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private movableBeingMoved: any;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
	public setMovableBeingMoved(movableBeingMoved: any): void {
		this.movableBeingMoved = movableBeingMoved;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public getMovableBeingMoved(): any {
		return this.movableBeingMoved;
	}

	public seeIfAncestorsHaveOutline<V extends Vue & {outline: boolean}>(component: V): void {
		/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
		let parent: V = component.$parent as V;

		let outlineFound = false;

		const containerElement: HTMLElement = component.$el as HTMLElement;

		while (parent) {
			if (parent.outline) {
				outlineFound = true;
			}
			parent = parent.$parent as V;
		}

		if (outlineFound) {
			containerElement.style.border = '1px dashed grey';
		}
	}
}
