






import { Component, Vue } from 'vue-property-decorator';

@Component({
	name : 'wb-horizontal-menu-controller',
})
export default class WbHorizontalMenuController extends Vue {
	/**
	 * CREATED
	 */
	public created(): void {
		// eslint-disable-next-line no-console
		console.info('%c CREATE WbHorizontalMenuController ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbHorizontalMenuController ', 'background: purple; color: #FFF');
	}
}
