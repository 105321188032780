/**
 * Form-validation for Vue.js
 * @copyright Wallboard Inc. 2019
 * @version V1.1.0
 * @lastModified Gabor Fabian 2021.apr.15.
 *
 * @author Gabor Fabian, Gergo Fucskar
 */

/* eslint-disable */
// <editor-fold desc="Enums: StatusTypes">

export enum StatusTypes {
    success = 'success',
    warning = 'warning',
    danger = 'danger',
}

export enum InputTypeTypes {
    text = 'text',
    email  = 'email',
    phoneNumber = 'phoneNumber',
    IP = 'ip',
    password = 'password',
    int = 'int',
    uInt = 'uInt',
    float = 'float',
    uFloat = 'uFloat',
}

// </editor-fold desc="Enums: StatusTypes">


// <editor-fold desc="Interface: IModel, IModelFields, IModelValue, IError, IOption, IStatus, IModelProperties, IResponseError">

export interface IError {
    required?: string;
    email?: string;
    phoneNumber?: string;
    sameAs?: string;
    custom?: string;
}

export interface IOption {
    label: string;
    value: string | number | boolean;
}

export interface IRange {
    min: number;
    max: number;
    step: number;
}

export interface IStatus {
    attr?: string;
    class?: string;
    txt?: string;
}

export interface ICustomValidationResult {
    txt: string;
}

export interface IModelValue {
    type?: InputTypeTypes;
    placeholder?: string;
    oldValue: string | number | boolean;
    value: string | number | boolean;
    componentValue?: string | number;
    required: boolean;
    customValidation?: (value: string | number | boolean, model: IModel) => ICustomValidationResult | boolean;
    sameAs?: string;
    status: IStatus;
    focus: boolean;
    disabled?: boolean;
    readonly?: boolean;
    error: IError;
    options?: IOption[];
    range?: IRange;
}

interface IModelProperties {
    dirtyFields: string[];
    visible?: boolean;
}

export interface IModelFields {
    [key: string]: IModelValue;
}

export interface IResponseError {
    status: StatusTypes;
    message: string;
}

export type IFieldCb = (modelValue: IModelValue) => void;

export interface IModel {
    id?: string | number;
    fields: IModelFields;
    properties: IModelProperties;
    responseError?: IResponseError | null;
}

// </editor-fold desc="Interface: IModel, IModelFields, IModelValue, IError, IOption, IStatus, IModelProperties, IResponseError">

// <editor-fold desc="IValidation, Validation">

export interface IValidation {
    result: any;
    modifiedCurrResult: any;
    modifiedOldResult: any;
    ok: () => boolean;
    modified: () => boolean;
}

export class Validation implements IValidation {
    public constructor(
        public result: any,
        public modifiedCurrResult: any,
        public modifiedOldResult: any,
        private isOk: boolean,
        private isModified: boolean,
    ) {}

    public ok() {
        return this.isOk;
    }

    public modified() {
        return this.isModified;
    }
}

// </editor-fold desc="IValidation, Validation">

export interface IValidateService {
    validate: (model: IModel) => Validation;
    onInput: (model: IModel, key: string, value: string | number | boolean, cb?: IFieldCb | null) => void;
    updateInputValidationState: (model: IModel, key: string, state: boolean, txt?: string) => void;
}

// There is two class in this file and I want in this way!
// tslint:disable-next-line
export class FormValidateService {
    /**
     * CONSTRUCTOR
     */
    constructor() {
		// eslint-disable-next-line no-console
        console.info( '%c INIT SERVICE FORM-VALIDATE ', 'background: green; color: #FFF');
    }

    public hasUpdated(model: IModel) {
        const modelKeys = Object.keys(model.fields);

        let counter = 0;
        let counterOldValue = 0;

        let response = '';

        modelKeys.forEach((key) => {
            const currentField = model.fields[key];
            if (currentField.hasOwnProperty('oldValue')) {
                counterOldValue++;
            }

            counter++;
        });

        if (counterOldValue === 0) {
            response = 'no';
        }

        if (counterOldValue > 0 && counterOldValue !== counter) {
            response = 'error';
        }

        if (counterOldValue > 0 && counterOldValue === counter) {
            response = 'yes';
        }

		// eslint-disable-next-line no-console
        console.log(
            'formValidateService->hasUpdated()',
            'counterOldValue: ' + counterOldValue,
            'counter: ' + counter,
        );

        return response;
    }

    public validate(model: IModel): Validation {
        const obj: any = {};
        const objModifiedCurr: any = {};
        const objModifiedOld: any = {};
        let ok = true;
        let modified = false;

        const modelKeys = Object.keys(model.fields);

        modelKeys.forEach((key) => {
            const currentField = model.fields[key];

            currentField.status = {class: 'success', txt: ''};

            // console.log(key,':', typeof currentField.value, '=' ,currentField.value);

            if (typeof currentField.value === 'string') {
                currentField.value = currentField.value.toString().trim();
            }

            if (
                currentField.required === true &&
                (currentField.value === '' || currentField.value === undefined || currentField.value === null)
            ) {
                currentField.status = {class: 'error', attr: 'required', txt: currentField.error.required};
                ok = false;
            }

            if (
                ((currentField.required === true && currentField.value !== '') || currentField.required === false)
                && currentField.type === 'email'
            ) {
                 const reEmail = /^[a-z][a-z0-9\-\.]*\@(?:[a-z0-9\-]+\.){1,2}[a-z]+$/;

                if (!reEmail.test(currentField.value.toString())) {
                    currentField.status = {class: 'error', attr: 'email', txt: currentField.error.email};
                    ok = false;
                }
            }

            if (
                ((currentField.required === true && currentField.value !== '') || currentField.required === false)
                && currentField.type === 'phoneNumber'
            ) {
                const rePhoneNumber = /(\+)([0-9]{10,12}){1}$/;

                if (!rePhoneNumber.test(currentField.value.toString())) {
                    currentField.status = {class: 'error', attr: 'phoneNumber', txt: currentField.error.phoneNumber};
                    ok = false;
                }
            }

            if (
                ((currentField.required === true && currentField.value !== '') || currentField.required === false)
                && currentField.sameAs !== undefined && currentField.sameAs !== ''
            ) {
                const fieldHasToBeChecked = model.fields[currentField.sameAs];

                if (fieldHasToBeChecked.value !== currentField.value) {
                      currentField.status = {class: 'error', attr: 'sameAs', txt: currentField.error.sameAs};
                      ok = false;
                }
            }

            if (currentField.customValidation && typeof currentField.customValidation === 'function') {
                const customValidationResult: ICustomValidationResult | boolean = currentField.customValidation(currentField.value, model);

                if (typeof customValidationResult !== 'boolean') {
                    currentField.status = {class: 'error', attr: 'custom', txt: customValidationResult.txt};
                    ok = false;
                }
            }

            obj[key] = currentField.value;
        });

        // If it has changed
        if (ok && this.hasUpdated(model) === 'yes') {
          modelKeys.forEach((key) => {
            const currentField = model.fields[key];

            if (
              currentField.oldValue !== currentField.value
            ) {
              objModifiedCurr[key] = currentField.value;
              objModifiedOld[key] = currentField.oldValue;
              modified = true;
            }
          });
        }

        return new Validation(obj, objModifiedCurr, objModifiedOld, ok, modified);
      }

    public onInput(model: IModel, key: string, value: string | number | boolean, cb: IFieldCb | null = null) {
        const currentField = model.fields[key];

        if (
            currentField.type === 'int' &&
            /^(0|-{0,1}[1-9][0-9]*)$/.test(value.toString())
        ) {
            currentField.value = +value;

        } else if (
            currentField.type === 'uInt' &&
            /^(0|[1-9][0-9]*)$/.test(value.toString())
        ) {
            currentField.value = +value;

        } else if (
            currentField.type === 'float' &&
            /^(0|-{0,1}0\.0*[1-9]+[0-9]*|-{0,1}[1-9][0-9]*|-{0,1}[1-9][0-9]*\.0*[1-9]+[0-9]*)$/.test(value.toString())
        ) {
            currentField.value = +value;

        } else if (
            currentField.type === 'uFloat' &&
            /^(0|0\.0*[1-9]+[0-9]*|[1-9][0-9]*|[1-9][0-9]*\.0*[1-9]+[0-9]*)$/.test(value.toString())
        ) {
            currentField.value = +value;

        } else {
            currentField.value = value;
        }

        if (currentField.status.attr !== '') {
            currentField.status.attr = '';
        }
        if (currentField.status.class !== '') {
            currentField.status.class = '';
        }
        if (model.responseError !== null) {
            model.responseError = null;
        }

        // validate min/max
        if (
            (
                currentField.type === 'int' ||
                currentField.type === 'uInt' ||
                currentField.type === 'float' ||
                currentField.type === 'uFloat'
            ) &&
            (
                currentField.range &&
                currentField.range.min !== undefined &&
                currentField.value < currentField.range.min
            )
        ) {
            currentField.value = currentField.range.min;
        }

        if (
            (
                currentField.type === 'int' ||
                currentField.type === 'uInt' ||
                currentField.type === 'float' ||
                currentField.type === 'uFloat'
            ) &&
            (
                currentField.range &&
                currentField.range.max !== undefined &&
                currentField.value > currentField.range.max
            )
        ) {
            currentField.value = currentField.range.max;
        }

        this.isDirty(model);

        if (cb !== null) {
            cb(currentField);
        }
    }

    public updateInputValidationState(model: IModel, key: string, state: boolean, txt = '') {
    	// console.log(model, key, state, txt);
        const currentField = model.fields[key];

		// eslint-disable-next-line no-console
        console.log('err', txt);
        if (state) {
            currentField.status = { class: 'error', attr: 'custom', txt, };
        } else {
            currentField.status = {};
        }
    }

    public commit(model: IModel) {
        const modelKeys = Object.keys(model.fields);

        modelKeys.forEach((key) => {
          const currentField = model.fields[key];

          currentField.status = {};
          currentField.oldValue = currentField.value;
        });
      }

    private isDirty(model: IModel) {
        const modelKeys = Object.keys(model.fields);
        const dirty = false;
        model.properties.dirtyFields = [];

        modelKeys.forEach((key) => {
            const currentField = model.fields[key];

            if (currentField.oldValue !== currentField.value) {
                model.properties.dirtyFields.push(key);
            }
        });
    }
}
