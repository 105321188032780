var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
        'wb-input' : true,
        'success' : _vm.model.status.class===_vm.statusTypes.success,
        'danger' : _vm.model.status.class===_vm.statusTypes.danger,
        'modified' : _vm.model.value!==_vm.model.oldValue,
        'disabled' : _vm.model.disabled,
        'readonly' : _vm.model.readonly,
    }},[_c('input',{ref:"input",class:{
            'left' : (_vm.iconLeft !== '' || _vm.textLeft !== ''),
            'right' : (_vm.iconRight !== '' || _vm.textRight !== '')
        },attrs:{"placeholder":_vm.model.placeholder,"type":_vm.inputType,"disabled":_vm.model.disabled,"readonly":_vm.model.readonly,"wb-ref-input":_vm.reference},domProps:{"value":_vm.model.value},on:{"input":function($event){return _vm.handleInput($event)},"keydown":function($event){return _vm.handleKeyDown($event)}}}),(_vm.iconLeft !== '')?_c('span',{staticClass:"icon left",class:_vm.iconLeft}):_vm._e(),(_vm.iconLeft === '' && _vm.textLeft !== '')?_c('span',{staticClass:"text left"},[_vm._v(_vm._s(_vm.textLeft))]):_vm._e(),(_vm.iconRight !== '')?_c('span',{staticClass:"icon right",class:_vm.iconRight}):_vm._e(),(_vm.iconRight === '' && _vm.textRight !== '')?_c('span',{staticClass:"text right"},[_vm._v(_vm._s(_vm.textRight))]):_vm._e(),(
            _vm.model.status.attr === 'required' ||
            _vm.model.status.attr === 'email' ||
            _vm.model.status.attr === 'sameAs' ||
            _vm.model.status.attr === 'custom')?_c('wb-input-growl',{class:{
                'success' : _vm.model.status.class === _vm.statusTypes.success,
                'danger' : _vm.model.status.class === _vm.statusTypes.danger
            }},[_vm._v(" "+_vm._s(_vm.model.status.txt)+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }