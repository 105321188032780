import { catchError, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { ResponseMessage } from '@/services/interfaces/message.interface';
import { IRequestPayload } from '@/services/dtos/shared.dto';

import { HttpService } from '@/services/common/http.service';
import { IToasterStoreService } from '@/services/common/toaster-store.service';
import { IPersistentStoreService } from '@/services/common/persistent-store.service';

// API Services
/* import { IStatusOkPayload } from '@/services/payloads/status.payload'; */
import {
	ReleaseType,
	IAppAndFirmwareApiService,
	IListAppsDto,
	IListOneAppDto,
	IListFirmwaresDto,
	IListOneFirmwareDto,
	IModifyAppDto,
	IModifyFirmwareDto,
	IListAppsPayload,
	IListOneAppPayload,
	IListFirmwaresPayload,
	IListOneFirmwarePayload
} from '@/services/api/app-and-firmware-api.service';
import {
	IAndroidFirmwareApiService,
	IListAndroidFirmwaresDto,
	IListOneAndroidFirmwareDto,
	IModifyAndroidFirmwareDto,
	IListAndroidFirmwaresPayload,
	IListOneAndroidFirmwarePayload, IToggleAndroidFirmwareDto, IDeleteAndroidFirmwareDto
} from '@/services/api/android-firmware-api.service';

export enum AndroidFirmwareType {
	DN_73 = 'dn_73',
	DN_73_STOCK = 'dn_73_stock',
	DN_74 = 'dn_74',
	DN_74_STOCK = 'dn_74_stock',
	DN_75 = 'dn_75',
	DN_75_STOCK = 'dn_75_stock',
	DN_76 = 'dn_76',
	AI = 'ai'
}

export interface IApp {
	id: number;
	time: Date;
	name: string;
	version: string;
	releaseType: ReleaseType;
	url: string;
	nexusAssetId: string;
	isEnabled: boolean;
	busy: boolean;
	changeLog: string;
	description: string;
}

export interface IFirmware {
	id: number;
	nexusItemId: string;
	nexusAssetId: string;
	releaseType: 'RC' | 'Production';
	name: string;
	env: string;
	version: string;
	tags: string;
	flasherRequirement: string;
	changeLog: string;
	description: string;
	time: number;
	isEnabled: boolean;
}

export interface IAndroidFirmware {
	id: number;
	name: string;
	type: AndroidFirmwareType;
	version: string;
	originalFilename: string;
	description: string;
	size: number;
	isLatest: boolean;
	isEnabled: boolean;
	createdAt: Date;
}

export interface IAppAndFirmwareStoreService {
	apps$: Observable<IListAppsPayload | null>;
	selectedApp$: Observable<IListOneAppPayload | null>;
	firmwares$: Observable<IListFirmwaresPayload | null>;
	selectedFirmware$: Observable<IListOneFirmwarePayload | null>;
	androidFirmwares$: Observable<IListAndroidFirmwaresPayload | null>;
	selectedAndroidFirmware$: Observable<IListOneAndroidFirmwarePayload | null>;
	requestResult$: Observable<IRequestPayload | null>;

	syncAppsAndFirmwares(): void;

	enableApp(appId: number): void;

	disableApp(appId: number): void;

	listApps(listAppsDto: IListAppsDto): void;

	listOneApp(listOneApp: IListOneAppDto): void;

	modifyApp(app: Partial<IModifyAppDto>): void;

	enableFirmware(firmwareId: number): void;

	disableFirmware(firmwareId: number): void;

	listFirmwares(listFirmwaresDto: IListFirmwaresDto): void;

	listOneFirmware(listOneFirmwareDto: IListOneFirmwareDto): void;

	modifyFirmware(firmware: Partial<IModifyFirmwareDto>): void;

	enableAndroidFirmware(toggleAndroidFirmwareDto: IToggleAndroidFirmwareDto): void;

	disableAndroidFirmware(toggleAndroidFirmwareDto: IToggleAndroidFirmwareDto): void;

	listAndroidFirmwares(listAndroidFirmwaresDto: IListAndroidFirmwaresDto): void;

	listOneAndroidFirmware(listOneAndroidFirmwareDto: IListOneAndroidFirmwareDto): void;

	modifyAndroidFirmware(androidFirmware: Partial<IModifyAndroidFirmwareDto>): void;

	deleteAndroidFirmware(deleteAndroidFirmwareDto: IDeleteAndroidFirmwareDto): void;

	resetRequestResult(): void;
}

export class AppAndFirmwareStoreService implements IAppAndFirmwareStoreService {
	private moduleName = 'AppAndFirmwareService';

	private lang: Omit<ResponseMessage, 'cannot_give_privilege_to_user' | 'insufficient_role' | 'email_already_in_use'> = {
		already_verified : 'User email address has already verified.',
		invalid_old_password : 'You mistyped your current password.',
		password_too_short : 'Your new password must be at least 8 characters long.',
		password_weak : 'Your password is too weak: It should contains at least 2 uppercase letters, 2 digits and 2 special characters.',
		invalid_flasher : 'The chosen app was not found.',
		internal_server_error : 'Something went wrong, please contact the administrator',
		insufficient_privilege : 'You do not have sufficient privileges to perform this action.',
	}

	/**
	 * State
	 */

	private apps = new BehaviorSubject<IListAppsPayload | null>(null);
	private selectedApp = new BehaviorSubject<IApp | null>(null);
	private firmwares = new BehaviorSubject<IListFirmwaresPayload | null>(null);
	private selectedFirmware = new BehaviorSubject<IFirmware | null>(null);
	private androidFirmwares = new BehaviorSubject<IListAndroidFirmwaresPayload | null>(null);
	private selectedAndroidFirmware = new BehaviorSubject<IAndroidFirmware | null>(null);
	private requestResult = new BehaviorSubject<IRequestPayload | null>(null);

	/**
	 * Selectors
	 */
	public apps$ = this.apps.asObservable();
	public selectedApp$ = this.selectedApp.asObservable();
	public firmwares$ = this.firmwares.asObservable();
	public selectedFirmware$ = this.selectedFirmware.asObservable();
	public androidFirmwares$ = this.androidFirmwares.asObservable();
	public selectedAndroidFirmware$ = this.selectedAndroidFirmware.asObservable();
	public requestResult$ = this.requestResult.asObservable();

	/**
	 * CONSTRUCTOR
	 */
	constructor(
		private $store: IPersistentStoreService,
		private $http: HttpService,
		private $toaster: IToasterStoreService,
		private $appAndFirmwareApiService: IAppAndFirmwareApiService,
		private $androidFirmwareApiService: IAndroidFirmwareApiService
	) {
		// eslint-disable-next-line no-console
		console.info('%c INIT STORE-SERVICE USER ', 'background: green; color: #FFF');

		this.fixScope();
		this.initState();
	}

	private fixScope(): void {
		// I know it is empty
	}

	private initState(): void {
		// I know it is empty
	}

	/**
	 * Updaters
	 */

	private setApps(apps: IListAppsPayload) {
		this.apps.next(apps);
	}

	private setSelectedApp(app: IListOneAppPayload) {
		this.selectedApp.next(app);
	}

	private setFirmwares(firmwares: IListFirmwaresPayload) {
		this.firmwares.next(firmwares);
	}

	private setSelectedFirmware(firmware: IListOneFirmwarePayload) {
		this.selectedFirmware.next(firmware);
	}

	private setAndroidFirmwares(androidFirmwares: IListAndroidFirmwaresPayload) {
		this.androidFirmwares.next(androidFirmwares);
	}

	private setSelectedAndroidFirmware(androidFirmware: IListOneAndroidFirmwarePayload) {
		this.selectedAndroidFirmware.next(androidFirmware);
	}

	private setRequestResult(requestPayload: IRequestPayload) {
		this.requestResult.next(requestPayload);
	}

	public resetRequestResult(): void {
		this.requestResult.next(null);
	}

	/**
	 * Getters
	 */
	public getApps(): IListAppsPayload | null {
		return this.apps.getValue();
	}

	public getFirmwares(): IListFirmwaresPayload | null {
		return this.firmwares.getValue();
	}

	public getAndroidFirmwares(): IListAndroidFirmwaresPayload | null {
		return this.androidFirmwares.getValue();
	}

	/**
	 * Effects
	 */
	public syncAppsAndFirmwares(): void {
		this.$appAndFirmwareApiService.syncAppAndFirmwares()
			.pipe(
				tap((/* syncPayload: ISyncPayload */) => {
					this.setRequestResult({
						status : 'success',
						actor : 'syncAppsAndFirmwares',
					});

					this.$toaster.create({
						type : 'success',
						message : 'Sync is started',
					});
				})
			).subscribe();
	}

	public enableApp(appId: number): void {
		this.$appAndFirmwareApiService.enableApp(appId)
			.pipe(
				tap(() => {
					this.setRequestResult({
						status : 'success',
						actor : 'enableApp',
					});

					this.$toaster.create({
						type : 'success',
						message : 'App is enabled',
					});
				}),
				catchError((error) => {
					const code: keyof Omit<ResponseMessage, 'cannot_give_privilege_to_user' | 'insufficient_role' | 'email_already_in_use'> =
						(error.response) ? error.response.message : '';

					this.$toaster.create({
						type : 'danger',
						message : this.lang[code],
					});

					this.setRequestResult({
						status : 'error',
						actor : 'enableApp',
					});

					return of(null);
				})
			).subscribe();
	}

	public disableApp(appId: number): void {
		this.$appAndFirmwareApiService.disableApp(appId)
			.pipe(
				tap(() => {
					this.setRequestResult({
						status : 'success',
						actor : 'disableApp',
					});

					this.$toaster.create({
						type : 'success',
						message : 'App is disabled',
					});
				}),
				catchError((error) => {
					const code: keyof Omit<ResponseMessage, 'cannot_give_privilege_to_user' | 'insufficient_role' | 'email_already_in_use'> =
						(error.response) ? error.response.message : '';

					this.$toaster.create({
						type : 'danger',
						message : this.lang[code],
					});

					this.setRequestResult({
						status : 'error',
						actor : 'disableApp',
					});

					return of(null);
				})
			).subscribe();
	}

	public listOneApp(listOneApp: IListOneAppDto): void {
		this.$appAndFirmwareApiService.getOneApp(listOneApp)
			.pipe(
				tap((app: IListOneAppPayload) => {
					this.setSelectedApp(app);
				})
			).subscribe();
	}

	public listApps(listAppsDto: IListAppsDto): void {
		this.$appAndFirmwareApiService.getApps(listAppsDto)
			.pipe(
				tap((appsPayload: IListAppsPayload) => {
					this.setApps(appsPayload);
				})
			).subscribe();
	}

	public modifyApp(app: Partial<IModifyAppDto>): void {
		this.$appAndFirmwareApiService.modifyApp(app).pipe(
			tap((/* app: IModifyAppPayload */) => {
				this.setRequestResult({ status : 'success', actor : 'modifyApp', });

				this.$toaster.create({
					type : 'success',
					message : 'Application is updated',
				});
			}),
			catchError((error) => {
				const code: keyof Omit<ResponseMessage, 'cannot_give_privilege_to_user' | 'insufficient_role' | 'email_already_in_use'> =
					(error.response) ? error.response.message : '';

				this.$toaster.create({
					type : 'danger',
					message : this.lang[code],
				});

				this.setRequestResult({
					status : 'error',
					actor : 'modifyApp',
				});

				return of(null);
			})
		).subscribe();
	}

	public modifyFirmware(firmware: Partial<IModifyFirmwareDto>): void {
		this.$appAndFirmwareApiService.modifyFirmware(firmware).pipe(
			tap((/* modifyFirmwarePayload: IModifyFirmwarePayload */) => {
				this.setRequestResult({ status : 'success', actor : 'modifyFirmware', });

				this.$toaster.create({
					type : 'success',
					message : 'Firmware is updated',
				});
			}),
			catchError((error) => {
				const code: keyof Omit<ResponseMessage, 'cannot_give_privilege_to_user' | 'insufficient_role' | 'email_already_in_use'> =
					(error.response) ? error.response.message : '';

				this.$toaster.create({
					type : 'danger',
					message : this.lang[code],
				});

				this.setRequestResult({
					status : 'error',
					actor : 'modifyFirmware',
				});

				return of(null);
			})
		).subscribe();
	}

	public enableFirmware(firmwareId: number): void {
		this.$appAndFirmwareApiService.enableFirmware(firmwareId)
			.pipe(
				tap(() => {
					this.setRequestResult({
						status : 'success',
						actor : 'enableFirmware',
					});

					this.$toaster.create({
						type : 'success',
						message : 'Firmware is enabled',
					});
				}),
				catchError((error) => {
					const code: keyof Omit<ResponseMessage, 'cannot_give_privilege_to_user' | 'insufficient_role' | 'email_already_in_use'> =
						(error.response) ? error.response.message : '';

					this.$toaster.create({
						type : 'danger',
						message : this.lang[code],
					});

					this.setRequestResult({
						status : 'error',
						actor : 'enableFirmware',
					});

					return of(null);
				})
			).subscribe();
	}

	public disableFirmware(firmwareId: number): void {
		this.$appAndFirmwareApiService.disableFirmware(firmwareId)
			.pipe(
				tap(() => {
					this.setRequestResult({
						status : 'success',
						actor : 'disableFirmware',
					});

					this.$toaster.create({
						type : 'success',
						message : 'Firmware is disabled',
					});
				}),
				catchError((error) => {
					const code: keyof Omit<ResponseMessage, 'cannot_give_privilege_to_user' | 'insufficient_role' | 'email_already_in_use'> =
						(error.response) ? error.response.message : '';

					this.$toaster.create({
						type : 'danger',
						message : this.lang[code],
					});

					this.setRequestResult({
						status : 'error',
						actor : 'disableFirmware',
					});

					return of(null);
				})
			).subscribe();
	}

	public listOneFirmware(listOneFirmware: IListOneFirmwareDto): void {
		this.$appAndFirmwareApiService.getOneFirmware(listOneFirmware)
			.pipe(
				tap((firmware: IListOneFirmwarePayload) => {
					this.setSelectedFirmware(firmware);
				})
			).subscribe();
	}

	public listFirmwares(listFirmwaresDto: IListFirmwaresDto): void {
		this.$appAndFirmwareApiService.getFirmwares(listFirmwaresDto)
			.pipe(
				tap((firmwaresPayload) => {
					this.setFirmwares(firmwaresPayload);
				})
			).subscribe();
	}

	public listOneAndroidFirmware(listOneAndroidFirmware: IListOneAndroidFirmwareDto): void {
		this.$androidFirmwareApiService.getOneAndroidFirmware(listOneAndroidFirmware)
			.pipe(
				tap((androidFirmware: IListOneAndroidFirmwarePayload) => {
					this.setSelectedAndroidFirmware(androidFirmware);
				})
			).subscribe();
	}

	public listAndroidFirmwares(listAndroidFirmwaresDto: IListAndroidFirmwaresDto): void {
		this.$androidFirmwareApiService.getAndroidFirmwares(listAndroidFirmwaresDto)
			.pipe(
				tap((androidFirmwaresPayload) => {
					this.setAndroidFirmwares(androidFirmwaresPayload);
				})
			).subscribe();
	}

	public modifyAndroidFirmware(androidFirmware: Partial<IModifyAndroidFirmwareDto>): void {
		this.$androidFirmwareApiService.modifyAndroidFirmware(androidFirmware).pipe(
			tap((/* modifyAndroidFirmwarePayload: IModifyAndroidFirmwarePayload */) => {
				this.setRequestResult({ status : 'success', actor : 'modifyAndroidFirmware', });

				this.$toaster.create({
					type : 'success',
					message : 'Android Firmware is updated',
				});
			}),
			catchError((error) => {
				const code: keyof Omit<ResponseMessage, 'cannot_give_privilege_to_user' | 'insufficient_role' | 'email_already_in_use'> =
					(error.response) ? error.response.message : '';

				this.$toaster.create({
					type : 'danger',
					message : this.lang[code],
				});

				this.setRequestResult({
					status : 'error',
					actor : 'modifyAndroidFirmware',
				});

				return of(null);
			})
		).subscribe();
	}

	public enableAndroidFirmware(toggleAndroidFirmwareDto: IToggleAndroidFirmwareDto): void {
		this.$androidFirmwareApiService.enableAndroidFirmware(toggleAndroidFirmwareDto)
			.pipe(
				tap((/* statusOkPayload: IStatusOkPayload */) => {
					this.setRequestResult({
						status : 'success',
						actor : 'enableAndroidFirmware',
					});

					this.$toaster.create({
						type : 'success',
						message : 'Android Firmware is enabled',
					});
				}),
				catchError((error) => {
					const code: keyof Omit<ResponseMessage, 'cannot_give_privilege_to_user' | 'insufficient_role' | 'email_already_in_use'> =
						(error.response) ? error.response.message : '';

					this.$toaster.create({
						type : 'danger',
						message : this.lang[code],
					});

					this.setRequestResult({
						status : 'error',
						actor : 'enableAndroidFirmware',
					});

					return of(null);
				})
			).subscribe();
	}

	public disableAndroidFirmware(toggleAndroidFirmwareDto: IToggleAndroidFirmwareDto): void {
		this.$androidFirmwareApiService.disableAndroidFirmware(toggleAndroidFirmwareDto)
			.pipe(
				tap((/* statusOkPayload: IStatusOkPayload */) => {
					this.setRequestResult({
						status : 'success',
						actor : 'disableAndroidFirmware',
					});

					this.$toaster.create({
						type : 'success',
						message : 'Android Firmware is disabled',
					});
				}),
				catchError((error) => {
					const code: keyof Omit<ResponseMessage, 'cannot_give_privilege_to_user' | 'insufficient_role' | 'email_already_in_use'> =
						(error.response) ? error.response.message : '';

					this.$toaster.create({
						type : 'danger',
						message : this.lang[code],
					});

					this.setRequestResult({
						status : 'error',
						actor : 'disableAndroidFirmware',
					});

					return of(null);
				})
			).subscribe();
	}

	public deleteAndroidFirmware(androidFirmwarePayload: IListOneAndroidFirmwarePayload): void {
		this.$androidFirmwareApiService.deleteAndroidFirmware(androidFirmwarePayload)
			.pipe(
				tap((/* statusOkPayload: IStatusOkPayload */) => {
					this.setRequestResult({
						status : 'success',
						actor : 'deleteAndroidFirmware',
					});

					this.$toaster.create({
						type : 'success',
						message : 'Android Firmware is deleted',
					});
				}),
				catchError((error) => {
					const code: keyof Omit<ResponseMessage, 'cannot_give_privilege_to_user' | 'insufficient_role' | 'email_already_in_use'> =
						(error.response) ? error.response.message : '';

					this.$toaster.create({
						type : 'danger',
						message : this.lang[code],
					});

					this.setRequestResult({
						status : 'error',
						actor : 'deleteAndroidFirmware',
					});

					return of(null);
				})
			).subscribe();
	}

	/**
	 * Helper
	 */
}
