

















/* eslint-disable */
    import {Component, Vue, Prop, Emit} from 'vue-property-decorator';

    @Component({
        name : 'wb-transformable',
    })
    export default class WbTransformable extends Vue {
        @Prop({default : 'yes'})                    // alt or nothing if not rotatable and not scalable and not resizable
        public movable!: string;

        @Prop({default : 'no'})                     // ctrl
        public rotatable!: string;

        @Prop({default : 'no'})                     // shift
        public scalable!: string;

        @Prop({default : 'no'})                     // alt + shift
        public resizable!: string;

        @Prop({default: ''})                        // css selector
        public handler!: string;

        @Prop({default : 'html'})                   // css selector
        public boundingArea!: string;

        @Prop({default : '0.5'})                    // 0..1
        public leavingToleranceTop!: string;

        @Prop({default : '0.5'})                    // 0..1
        public leavingToleranceRight!: string;

        @Prop({default : '0.5'})                    // 0..1
        public leavingToleranceBottom!: string;

        @Prop({default : '0.5'})                    // 0..1
        public leavingToleranceLeft!: string;

        @Prop({default: '200'})                     // in ms
        public outOfBoundsResetSpeed!: string;

        @Prop({default : '0.5'})                    // 0..1
        public rotationSpeed!: string;

        @Prop({default : '0.5'})                    // 0..1
        public scalingSpeed!: string;

        @Prop({default : () => ({})})               // potential useful data
        public data!: object;

        public styles = {
            container : {
                transform : 'translate3d(0, 0, 0) rotate(0deg) scale(1)',
                transition : '',
                opacity : '1',
                width : '',
                height : '',
            },
        };

        public isResizingHelperFrameVisible: boolean = false;

        private pointer = {
            translate : {
                x : 0,
                y : 0,
            },
            lastInBoundsPosition : {
                x : 0,
                y : 0,
            },
            rotate : 0,
            scale : 1,
            down : false,
            downLocation : '',
        };

        private boundingAreaBox: DOMRect = new DOMRect();

        private componentBox: DOMRect = new DOMRect();

        private isComponentOutOfBounds: boolean = false;

        /**
         * CREATED
         */
        public created(): void {
            this.addNativeEventListeners();

            // eslint-disable-next-line no-console
            console.info('%c INIT WbTransformable ', 'background: blue; color: #FFF');
        }

        public mounted(): void {
            this.initFirstElementChild();
        }

        public destroyed(): void {
            this.removeNativeEventListeners();

            // eslint-disable-next-line no-console
            console.info('%c DESTROY WbTransformable ', 'background: purple; color: #FFF');
        }

        @Emit('on-pointer-down')
        public emitPointerDownEvent(e: PointerEvent) {
            const objectToEmit = {pointerEvent : e, component : this /*!!! 'this' CONTAINS A LOT OF USEFUL INFORMATION !!!*/};
            this.$ui.setMovableBeingMoved(objectToEmit);
            return objectToEmit;
        }

        @Emit('on-pointer-move')
        public emitPointerMoveEvent(e: PointerEvent) {
            const objectToEmit = {pointerEvent : e, component : this /*!!! 'this' CONTAINS A LOT OF USEFUL INFORMATION !!!*/};
			this.$ui.setMovableBeingMoved(objectToEmit);
            return objectToEmit;
        }

        @Emit('on-pointer-up')
        public emitPointerUpEvent(e: PointerEvent) {
            const objectToEmit = {pointerEvent : e, component : this /*!!! 'this' CONTAINS A LOT OF USEFUL INFORMATION !!!*/};
			this.$ui.setMovableBeingMoved(objectToEmit);
            return objectToEmit;
        }

        public onPointerOver() {
            if (this.resizable === 'yes') {
                this.isResizingHelperFrameVisible = true;
            }
        }

        public onPointerLeave() {
            if (!this.pointer.down) {
                this.isResizingHelperFrameVisible = false;
            }
        }

        private initFirstElementChild() {
            const firstElementChild = this.$el.firstElementChild  as HTMLElement;
            firstElementChild.style.width = '100%';
            firstElementChild.style.height = '100%';
        }

        private emitEvent(e: PointerEvent) {
            if (e.type === 'pointerdown') {
                this.emitPointerDownEvent(e);
            } else if (e.type === 'pointermove') {
                this.emitPointerMoveEvent(e);
            } else if (e.type === 'pointerup') {
                this.emitPointerUpEvent(e);
            }
        }

        private translateComponent() {
            this.styles.container.transform = `translate3d(${this.pointer.translate.x}px, ${this.pointer.translate.y}px, 0) rotate(${this.pointer.rotate}deg) scale(${this.pointer.scale})`;
        }

        private testIfComponentIsOutOfBounds() {
            const boundingAreaBox = this.$el.closest(this.boundingArea)!.getBoundingClientRect();
            const componentBox = this.$el.getBoundingClientRect();
            const leavingTolerance = {
                top : (1 - +this.leavingToleranceTop) * componentBox.height,
                right : (1 - +this.leavingToleranceRight) * componentBox.width,
                bottom : (1 - +this.leavingToleranceBottom) * componentBox.height,
                left : (1 - +this.leavingToleranceLeft) * componentBox.width,
            };
            const isComponentOutOfBounds = (
                componentBox.left > boundingAreaBox.right - leavingTolerance.right
                || componentBox.right < boundingAreaBox.left + leavingTolerance.left
                || componentBox.top > boundingAreaBox.bottom - leavingTolerance.bottom
                || componentBox.bottom < boundingAreaBox.top + leavingTolerance.top
            );

            this.boundingAreaBox = boundingAreaBox as DOMRect;
            this.componentBox = componentBox as DOMRect;
            this.isComponentOutOfBounds = isComponentOutOfBounds;

            return isComponentOutOfBounds;
        }

        private setPointerDownLocation(e: PointerEvent) {
            const eventTarget = e.target as Node;
            const topLeft = this.$el.querySelector('.resizer-point.top-left');
            const topCenter = this.$el.querySelector('.resizer-point.top-center');
            const topRight = this.$el.querySelector('.resizer-point.top-right');
            const centerLeft = this.$el.querySelector('.resizer-point.center-left');
            const centerCenter = this.$el.querySelector('.resizer-point.center-center');
            const centerRight = this.$el.querySelector('.resizer-point.center-right');
            const bottomLeft = this.$el.querySelector('.resizer-point.bottom-left');
            const bottomCenter = this.$el.querySelector('.resizer-point.bottom-center');
            const bottomRight = this.$el.querySelector('.resizer-point.bottom-right');

            if (eventTarget.isSameNode(topLeft)) {
                this.pointer.downLocation = 'topLeft';
            } else if (eventTarget.isSameNode(topCenter)) {
                this.pointer.downLocation = 'topCenter';
            } else if (eventTarget.isSameNode(topRight)) {
                this.pointer.downLocation = 'topRight';
            } else if (eventTarget.isSameNode(centerLeft)) {
                this.pointer.downLocation = 'centerLeft';
            } else if (eventTarget.isSameNode(centerCenter)) {
                this.pointer.downLocation = 'centerCenter';
            } else if (eventTarget.isSameNode(centerRight)) {
                this.pointer.downLocation = 'centerRight';
            } else if (eventTarget.isSameNode(bottomLeft)) {
                this.pointer.downLocation = 'bottomLeft';
            } else if (eventTarget.isSameNode(bottomCenter)) {
                this.pointer.downLocation = 'bottomCenter';
            } else if (eventTarget.isSameNode(bottomRight)) {
                this.pointer.downLocation = 'bottomRight';
            }
        }

        private onPointerDown(e: PointerEvent) {
            if (!this.pointer.down) {
                const target = e.target as Node;
                // @ts-ignore
                if ((!this.handler && this.$el.contains(target) || (this.handler && this.$el.contains(target) && e.target.closest(this.handler))) ) {
                    e.preventDefault();
                    this.pointer.down = true;
                    this.setPointerDownLocation(e);
                    this.testIfComponentIsOutOfBounds();
                    this.emitEvent(e);
                }
            }
        }

        private onPointerMove(e: PointerEvent) {
            if (this.pointer.down) {
                if ((this.movable === 'yes' && this.rotatable === 'no' && this.scalable === 'no' && this.resizable === 'no') || (this.movable === 'yes' && e.altKey && !e.ctrlKey && !e.shiftKey)) {
                    e.preventDefault();
                    this.pointer.translate.x += e.movementX;
                    this.pointer.translate.y += e.movementY;
                    this.translateComponent();
                    if (this.testIfComponentIsOutOfBounds()) {
                        this.styles.container.opacity = '0.5';
                    } else {
                        this.styles.container.opacity = '1';
                    }
                } else if (this.rotatable === 'yes' && !e.altKey && e.ctrlKey && !e.shiftKey) {
                    e.preventDefault();
                    const componentBox = this.$el.getBoundingClientRect();
                    const speed = +this.rotationSpeed;
                    if (e.clientY < (componentBox.top + componentBox.height / 2)) {
                        if (e.clientX > (componentBox.left + componentBox.width / 2)) {
                            this.pointer.rotate = this.pointer.rotate + speed * e.movementX + speed * e.movementY;
                        } else {
                            this.pointer.rotate = this.pointer.rotate + speed * e.movementX - speed * e.movementY;
                        }
                    } else {
                        if (e.clientX > (componentBox.left + componentBox.width / 2)) {
                            this.pointer.rotate = this.pointer.rotate - speed * e.movementX + speed * e.movementY;
                        } else {
                            this.pointer.rotate = this.pointer.rotate - speed * e.movementX - speed * e.movementY;
                        }
                    }
                    this.translateComponent();
                } else if (this.scalable === 'yes' && !e.altKey && !e.ctrlKey && e.shiftKey) {
                    e.preventDefault();
                    const componentBox = this.$el.getBoundingClientRect();
                    const speed = +this.scalingSpeed / 100;
                    if (e.clientY < (componentBox.top + componentBox.height / 2)) {
                        if (e.clientX > (componentBox.left + componentBox.width / 2)) {
                            this.pointer.scale = this.pointer.scale + speed * e.movementX - speed * e.movementY;
                        } else {
                            this.pointer.scale = this.pointer.scale - speed * e.movementX - speed * e.movementY;
                        }
                    } else {
                        if (e.clientX > (componentBox.left + componentBox.width / 2)) {
                            this.pointer.scale = this.pointer.scale + speed * e.movementX + speed * e.movementY;
                        } else {
                            this.pointer.scale = this.pointer.scale - speed * e.movementX + speed * e.movementY;
                        }
                    }
                    this.translateComponent();
                } else if (this.resizable === 'yes' && e.altKey && !e.ctrlKey && e.shiftKey) {
                    e.preventDefault();
                    if (this.pointer.downLocation === 'topLeft') {
                        // @ts-ignore
                        this.styles.container.width = parseInt(getComputedStyle(this.$el).width, 10) - e.movementX + 'px';
                        this.pointer.translate.x += e.movementX;
                        // @ts-ignore
                        this.styles.container.height = parseInt(getComputedStyle(this.$el).height, 10) - e.movementY + 'px';
                        this.pointer.translate.y += e.movementY;
                    } else if (this.pointer.downLocation === 'topCenter') {
                        // @ts-ignore
                        this.styles.container.height = parseInt(getComputedStyle(this.$el).height, 10) - e.movementY + 'px';
                        this.pointer.translate.y += e.movementY;
                    } else if (this.pointer.downLocation === 'topRight') {
                        // @ts-ignore
                        this.styles.container.width = parseInt(getComputedStyle(this.$el).width, 10) + e.movementX + 'px';
                        // @ts-ignore
                        this.styles.container.height = parseInt(getComputedStyle(this.$el).height, 10) - e.movementY + 'px';
                        this.pointer.translate.y += e.movementY;
                    } else if (this.pointer.downLocation === 'centerLeft') {
                        // @ts-ignore
                        this.styles.container.width = parseInt(getComputedStyle(this.$el).width, 10) - e.movementX + 'px';
                        this.pointer.translate.x += e.movementX;
                    } else if (this.pointer.downLocation === 'centerCenter') {
                        // nothing at the moment
                    } else if (this.pointer.downLocation === 'centerRight') {
                        // @ts-ignore
                        this.styles.container.width = parseInt(getComputedStyle(this.$el).width, 10) + e.movementX + 'px';
                    } else if (this.pointer.downLocation === 'bottomLeft') {
                        // @ts-ignore
                        this.styles.container.width = parseInt(getComputedStyle(this.$el).width, 10) - e.movementX + 'px';
                        this.pointer.translate.x += e.movementX;
                        // @ts-ignore
                        this.styles.container.height = parseInt(getComputedStyle(this.$el).height, 10) + e.movementY + 'px';
                    } else if (this.pointer.downLocation === 'bottomCenter') {
                        // @ts-ignore
                        this.styles.container.height = parseInt(getComputedStyle(this.$el).height, 10) + e.movementY + 'px';
                    } else if (this.pointer.downLocation === 'bottomRight') {
                        // @ts-ignore
                        this.styles.container.width = parseInt(getComputedStyle(this.$el).width, 10) + e.movementX + 'px';
                        // @ts-ignore
                        this.styles.container.height = parseInt(getComputedStyle(this.$el).height, 10) + e.movementY + 'px';
                    }
                    this.translateComponent();
                    if (this.testIfComponentIsOutOfBounds()) {
                        this.styles.container.opacity = '0.5';
                    } else {
                        this.styles.container.opacity = '1';
                    }
                }
                this.emitEvent(e);
            }
        }

        private onPointerUp(e: PointerEvent) {
            if (this.pointer.down) {
                e.preventDefault();
                this.pointer.down = false;
                this.pointer.downLocation = '';

                if (this.testIfComponentIsOutOfBounds()) {
                    this.styles.container.transition = `transform ${this.outOfBoundsResetSpeed}ms`;
                    this.pointer.translate.x = this.pointer.lastInBoundsPosition.x;
                    this.pointer.translate.y = this.pointer.lastInBoundsPosition.y;
                    this.translateComponent();
                    setTimeout(() => {
                        this.styles.container.transition = '';
                        this.styles.container.opacity = '1';
                    }, +this.outOfBoundsResetSpeed);
                } else {
                    this.pointer.lastInBoundsPosition.x = this.pointer.translate.x;
                    this.pointer.lastInBoundsPosition.y = this.pointer.translate.y;
                }
                this.emitEvent(e);
            }
        }

        private addNativeEventListeners() {
            document.addEventListener('pointerdown', this.onPointerDown);
            document.addEventListener('pointermove', this.onPointerMove);
            document.addEventListener('pointerup', this.onPointerUp);
        }

        private removeNativeEventListeners() {
            document.removeEventListener('pointerdown', this.onPointerDown);
            document.removeEventListener('pointermove', this.onPointerMove);
            document.removeEventListener('pointerup', this.onPointerUp);
        }
    }
