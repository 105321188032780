export enum UserRoles {
	SUPERADMIN = '0',
	ADMIN = '1',
	USER = '2',
	NONE = '3',
}

export enum UserLogTypes {
	activeTimeSpent = 'activeTimeSpent',
	balanceChanged = 'balanceChanged',
	balanceRequestGranted = 'balanceRequestGranted',
	balanceTopUpRequested = 'balanceTopUpRequested',
	balanceRequestRejected = 'balanceRequestRejected',
	configSaved = 'configSaved',
	configLoaded = 'configLoaded',
	configUploaded = 'configUploaded',
	configDownloaded = 'configDownloaded',
	configUploadedToDevice = 'configUploadedToDevice',
	deviceConnected = 'deviceConnected',
	deviceRegistered = 'deviceRegistered',
	firmwareBurnt = 'firmwareBurnt',
	flasherDownloaded = 'flasherDownloaded',
	loggedIn = 'loggedIn',
	loggedOut = 'loggedOut',
	userModified = 'userModified',
	userPasswordChanged = 'userPasswordChanged',
}

export interface IUserActivity {
	id: number;
	balance: number;
	numberOfFirmwaresBurnt: number;
	numberOfConfigSaves: number;
	numberOfConfigLoads: number;
	numberOfConfigDownloads: number;
	numberOfConfigUploads: number;
	numberOfConfigUploadsToDevice: number;
	lastUsedFlasherVersion: string;
	lastLoginDate: Date;
	lastActiveDate: Date;
	lastTopUpRequest: Date;
	numberOfLogins: number;
	allActiveTimeInMinutes: number;
}

export interface IUserPrivilege {
	id: number;
	developer: boolean;
	isaac: boolean;
	canModifyBalance: boolean;
	topUpRequestRecipient: boolean; // can get email
	userRegisteredRecipient: boolean;
	god: boolean;
}

export interface IUser {
	id: number;
	role: UserRoles;
	organization: string;
	email: string;
	firstname: string;
	lastname: string;
	enabled: boolean;
	isVerified: boolean;
	createdAt: Date;
	updatedAt: Date;
	activity: IUserActivity;
	privilege: IUserPrivilege;
}

export interface IListOneUserPayload {
	id: number;
	role: UserRoles;
	organization: string;
	email: string;
	firstname: string;
	lastname: string;
	enabled: boolean;
	isVerified: boolean;
	createdAt: Date;
	updatedAt: Date;
	activity: IUserActivity;
	privilege: IUserPrivilege;
}

export interface IListUsersPayload {
	count: number;
	users: IUser[];
}
