






import { Component, Vue, Prop } from 'vue-property-decorator';
import { CardFooterHorizontalPosition } from '@/services/common/ui.service';

@Component({
	name : 'wb-card-footer',
})
export default class WbCardFooter extends Vue {
	@Prop({ default : CardFooterHorizontalPosition.end, })
	public horizontalPosition!: string;

	/**
	 * CREATED
	 */
	public created(): void {
		// eslint-disable-next-line no-console
		console.log('%c INIT uiElements.card.WbCardFooter ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		// eslint-disable-next-line no-console
		console.info('%c DESTROY uiElements.card.WbCardFooter ', 'background: purple; color: #FFF');
	}
}
