






























































import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Component, Vue /*, Watch */ } from 'vue-property-decorator';

import { IMenu } from '@/services/menu-store.service';

import { IBalancePayload } from '@/services/api/balance-api.service';
import { IListOneUserPayload, UserRoles } from '@/services/payloads/user.payload';

import WbHorizontalMenu from '@/components/ui-elements/wb-horizontal-menu/wb-horizontal-menu.vue';
import WbHorizontalMenuLogo from '@/components/ui-elements/wb-horizontal-menu/wb-horizontal-menu-logo/wb-horizontal-menu-logo.vue';
import WbHorizontalMenuList from '@/components/ui-elements/wb-horizontal-menu/wb-horizontal-menu-list/wb-horizontal-menu-list.vue';
import WbHorizontalMenuListItem from '@/components/ui-elements/wb-horizontal-menu/wb-horizontal-menu-list-item/wb-horizontal-menu-list-item.vue';
import WbHorizontalMenuController from '@/components/ui-elements/wb-horizontal-menu/wb-horizontal-menu-controller/wb-horizontal-menu-controller.vue';
import WbHorizontalMenuControllerItem from '@/components/ui-elements/wb-horizontal-menu/wb-horizontal-menu-controller-item/wb-horizontal-menu-controller-item.vue';
import WbHorizontalMenuLogout from '@/components/ui-elements/wb-horizontal-menu/wb-horizontal-menu-logout/wb-horizontal-menu-logout.vue';
import WbLogoutModal from '@/views/wb-app/wb-site/wb-horizontal-menu-container/wb-logout-modal/wb-logout-modal.vue';

interface ISubscriptions {
	listMenus: Subscription | null;
	ownBalance: Subscription | null;
	ownProfile: Subscription | null;
}

@Component({
	name : 'wb-horizontal-menu-container',
	components : {
		WbHorizontalMenuControllerItem,
		WbHorizontalMenuController,
		WbHorizontalMenuListItem,
		WbHorizontalMenuList,
		WbHorizontalMenuLogo,
		WbHorizontalMenu,
		WbHorizontalMenuLogout,
		WbLogoutModal,
	},
})
export default class WbHorizontalMenuContainer extends Vue {
	private subscriptions: ISubscriptions = {
		listMenus : null,
		ownBalance : null,
		ownProfile : null,
	}

	public balance = 0;
	public profile: IListOneUserPayload | null = null;

	public toggleLogout = false;

	public modal = {
		logout : false,
	};

	public model: IMenu[] = [];

	public onMenuItemClick(menu: IMenu /* , parentMenu: IMenu */): void {
		if (!menu.children.length) {
			if (menu.routeName) {
				if (menu.routeName !== this.$router.currentRoute.name) {
					/* if (menu.routeName === 'wbLogout') {
						this.$emit('logout');
					} else { */
					this.$router.push({ name : menu.routeName, })
						.then(() => {
							this.model.forEach((menu, index) => {
								this.model[index].active = false;
							});
							menu.active = true;
						})
						.catch((err) => {
							// eslint-disable-next-line no-console
							console.info('ROUTER: ', menu.routeName, err);
						});
					/* } */
				} else {
					// TODO: do not allow reloading the page. We need added a custom event based refreshing...
					this.$router.go(0);
				}
			}
			this.$menu.closeAllSubMenus();
		} else {
			menu.isSubMenuOpen = !menu.isSubMenuOpen;
		}
	}

	public created(): void {
		this.subscribeToStore();

		this.$services.balance.getOwnBalance();
		this.$services.user.getProfile();

		// eslint-disable-next-line no-console
		console.info('%c CREATE WbHorizontalMenuContainer ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbHorizontalMenuContainer ', 'background: purple; color: #FFF');

		this.unsubscribeFromStore();
	}

	public goToProfile(): void {
		if (
			this.$auth.getUserRole() === UserRoles.ADMIN ||
			this.$auth.getUserRole() === UserRoles.SUPERADMIN
		) {
			this.$router.push({ name : 'adminProfile', });
		} else {
			this.$router.push({ name : 'userProfile', });
		}
	}

	/* @Watch('$route')
	onRouteChanged(to: string, from: string) {
		console.log(to);
	} */

	private subscribeToStore(): void {
		this.subscriptions.listMenus = this.$menu.menus$
			.pipe(
				tap((menus: IMenu[]) => {
					this.model = menus;

					this.model.forEach((menu, index) => {
						this.model[index].active = false;

						if (this.model[index].routeName === this.$router.currentRoute.name) {
							this.model[index].active = true;
						}
					});
				})
			).subscribe();

		this.subscriptions.ownBalance = this.$services.balance.balance$
			.pipe(
				tap((data: IBalancePayload | null) => {
					if (data) {
						this.balance = data.balance;
					}
				})
			).subscribe();

		this.subscriptions.ownProfile = this.$services.user.profile$
			.pipe(
				tap((profile: IListOneUserPayload | null) => {
					if (profile) {
						this.profile = profile;
					}
				})
			).subscribe();
	}

	private unsubscribeFromStore(): void {
		Object.keys(this.subscriptions).map(key => this.subscriptions[key as keyof ISubscriptions])
			.forEach((subscription: Subscription | null) => {
				if (subscription) {
					subscription.unsubscribe();
				}
			});
	}
}
