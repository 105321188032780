







































































/**
 * Pagination
 *
 * @copyright Wallboard Inc. 2019
 * @version V1.0.0
 * @author  Gabor Fabian, David Fabian
 * @utf-8   ő
 */
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { IModel, IOption } from '@/services/common/form-validate.service';

export enum PAGE_NAVIGATION_TYPES {
    PREVIOUS = 'previous',
    NEXT = 'next',
    FIRST = 'first',
    LAST = 'last'
}

@Component({
	name : 'wb-pagination',
})

export default class WbPagination extends Vue {
	public pageNavigationTypes = PAGE_NAVIGATION_TYPES;

	@Prop({ required : true, })
	public min!: number;

	@Prop({ required : true, })
	public current!: number;

	@Prop({ required : true, })
	public max!: number;

	public model: IModel = {
		fields : {
			pageNumber : {
				oldValue : '',
				value : this.current,
				required : true,
				options : [],
				status : {},
				focus : false,
				disabled : false,
				error : {},
			},
		},
		properties : {
			dirtyFields : [],
		},
	}

	public get isBackArrowUsable(): boolean {
		return !(this.min === this.max || this.min === this.current || this.max === 0);
	}

	public get isNextArrowUsable(): boolean {
		return !(this.min === this.max || this.max === this.current || this.max === 0);
	}

	public get isMinUsable(): boolean {
		return this.min !== this.current;
	}

	public get isMaxUsable(): boolean {
		return this.max !== this.current;
	}

	public get isNumberDotVisible(): boolean {
		return this.max !== undefined && this.max > 1;
	}

	@Watch('max', { immediate : true, })
	public onMaxChange(): void {
		this.model.fields.pageNumber.options = this.generateOptions();
	}

	/**
	 * CREATED
	 */
	public created(): void {
		// eslint-disable-next-line no-console
		console.log('%c INIT uiElements.WbPagination ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		// eslint-disable-next-line no-console
		console.info('%c DESTROY uiElements.WbPagination ', 'background: purple; color: #FFF');
	}

	public onClickGuard(navigationType: PAGE_NAVIGATION_TYPES): void {
		switch (navigationType) {
			case PAGE_NAVIGATION_TYPES.PREVIOUS:
				if (this.isBackArrowUsable) { this.updateSelectedPage(navigationType); }
				break;
			case PAGE_NAVIGATION_TYPES.NEXT:
				if (this.isNextArrowUsable) { this.updateSelectedPage(navigationType); }
				break;
			case PAGE_NAVIGATION_TYPES.FIRST:
				if (this.isMinUsable) { this.updateSelectedPage(navigationType); }
				break;
			case PAGE_NAVIGATION_TYPES.LAST:
				if (this.isMaxUsable) { this.updateSelectedPage(navigationType); }
				break;
		}
	}

	public updateSelectedPage(pageNavigationValue: PAGE_NAVIGATION_TYPES | number): void {
		if (this.min !== undefined && this.current !== undefined && this.max !== undefined) {
			let newCurrentPageValue: number;

			switch (pageNavigationValue) {
				case PAGE_NAVIGATION_TYPES.PREVIOUS:
					newCurrentPageValue = this.current - 1 < this.min ? this.min : this.current - 1;
					break;
				case PAGE_NAVIGATION_TYPES.NEXT:
					newCurrentPageValue = this.current + 1 > this.max ? this.max : this.current + 1;
					break;
				case PAGE_NAVIGATION_TYPES.FIRST:
					newCurrentPageValue = this.min;
					break;
				case PAGE_NAVIGATION_TYPES.LAST:
					newCurrentPageValue = this.max;
					break;
				default:
					newCurrentPageValue = pageNavigationValue;
					break;
			}

			this.model.fields.pageNumber.value = newCurrentPageValue;
			this.$emit('page-navigation', newCurrentPageValue);
		}
	}

	private generateOptions(): IOption[] {
		const options: IOption[] = [];
		Array.from({ length : this.max === undefined ? 1 : this.max, }, (item, index) => index + 1).map((item) => {
			options.push({ label : String(item), value : item, });
		});

		return options.length ? options : [{ label : '1', value : 1, }];
	}
}
