import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { HttpService } from '@/services/common/http.service';
import { IToasterStoreService } from '@/services/common/toaster-store.service';
import { IPersistentStoreService } from '@/services/common/persistent-store.service';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {
	ILogApiService,
	IListUserLogDto,
	IListSystemLogDto,
	IListUserLogPayload,
	IListSystemLogPayload
} from '@/services/api/log-api.service';
import { IRequestPayload } from '@/services/dtos/shared.dto';

export interface ILogStoreService {
	systemLogs$: Observable<IListSystemLogPayload | null>;
	userLogs$: Observable<IListUserLogPayload | null>;
	requestResult$: Observable<IRequestPayload | null>;

	listSystemLogs(listSystemLogDto: Partial<IListSystemLogDto> & Pick<IListSystemLogDto, 'page' | 'pageLength'>): void | null;
	listUserLogs(listUserLogDto: Partial<IListUserLogDto> & Pick<IListUserLogDto, 'page' | 'pageLength'>): void | null;
}

export class LogStoreService implements ILogStoreService {
	/**
	 * State
	 */
	private systemLogs = new BehaviorSubject<IListSystemLogPayload | null>(null);
	private userLogs = new BehaviorSubject<IListUserLogPayload | null>(null);
	private requestResult = new BehaviorSubject<IRequestPayload | null>(null);

	/**
	 * Selectors
	 */

	public requestResult$ = this.requestResult.asObservable();
	public systemLogs$ = this.systemLogs.asObservable();
	public userLogs$ = this.userLogs.asObservable();

	/**
	 * CONSTRUCTOR
	 */
	constructor(
		private $store: IPersistentStoreService,
		private $http: HttpService,
		private $toaster: IToasterStoreService,
		private $logsApiService: ILogApiService
	) {
		// eslint-disable-next-line no-console
		console.info('%c INIT STORE-SERVICE LOG ', 'background: green; color: #FFF');

		this.fixScope();
		this.initState();
	}

	private fixScope(): void {
		// I know it is empty
	}

	private initState(): void {
		// I know it is empty
	}

	/**
	 * Updaters
	 */

	private setRequestResult(requestPayload: IRequestPayload) {
		this.requestResult.next(requestPayload);
	}

	private setSystemLog(systemLog: IListSystemLogPayload) {
		this.systemLogs.next(systemLog);
	}

	private setUserLog(userLog: IListUserLogPayload) {
		this.userLogs.next(userLog);
	}

	/**
	 * Getters
	 */
	public getUserLog(): BehaviorSubject<IListUserLogPayload | null> {
		return this.userLogs;
	}

	public getSystemLog(): BehaviorSubject<IListSystemLogPayload | null> {
		return this.systemLogs;
	}

	/**
	 * Effects
	 */

	public listSystemLogs(IListSystemLogDto: Partial<IListSystemLogDto> & Pick<IListSystemLogDto, 'page' | 'pageLength'>): void {
		this.$logsApiService.getSystemLog(IListSystemLogDto)
			.pipe(
				tap((logsPayload: IListSystemLogPayload) => {
					this.setSystemLog(logsPayload);
				})
			).subscribe();
		/**
		 * Helper
		 */
	}

	public listUserLogs(listUserLogDto: Partial<IListUserLogDto> & Pick<IListUserLogDto, 'page' | 'pageLength'>): void {
		this.$logsApiService.getUserLog(listUserLogDto)
			.pipe(
				tap((logsPayload: IListUserLogPayload) => {
					this.setUserLog(logsPayload);
				})
			).subscribe();
	}
}
