/**
 * EventBus service for Vue.js
 * @copyright Wallboard Inc. 2019
 * @version V1.0.0
 * @author Gabor Fabian
 */

// <editor-fold desc="Enums: ">

// </editor-fold desc="Enums: ">

// <editor-fold desc="Interface: IGoogleCaptchaService">

import { Observable } from 'rxjs';

declare global {
	interface Window { // Must be the name Window
		grecaptcha?: {
			ready(cb: () => void): void;
			execute(secret: string, options: { action: string }): Promise<string>;
		};
		// eslint-disable-next-line camelcase
		__google_recaptcha_client?: boolean;
		// eslint-disable-next-line camelcase
		__recaptcha_api?: string;
		// eslint-disable-next-line camelcase
		___grecaptcha_cfg?: Record<string, unknown>;
	}
}

export interface IGoogleCaptchaService {
	init(secret: string): void;
	remove(): void;
	setSecret(secret: string): void;
	getSecret(): string;
    getToken: () => Observable<string>;
}

// </editor-fold desc="Interface: IGoogleCaptchaService">

export class GoogleCaptchaService implements IGoogleCaptchaService {
	private secret = '';
	private serviceApiUrl = 'https://www.google.com/recaptcha/api.js';

	/**
	 * CONSTRUCTOR
	 */
	public constructor() {
		// eslint-disable-next-line no-console
		console.log('%c INIT SERVICE GOOGLE CAPTCHA ', 'background: green; color: #FFF');
	}

	public setSecret(secret: string): void {
		this.secret = secret;
	}

	public getSecret(): string {
		return this.secret;
	}

	public init(secret: string): void {
		const googleCaptchaScript = document.querySelector('[data-script="google-captcha"]');
		if (!googleCaptchaScript) {
			this.setSecret(secret);

			const script = document.createElement('script');
			script.onload = function() {
				// eslint-disable-next-line no-console
				console.info('Google captcha is loaded.');
			};
			script.setAttribute('data-script', 'google-captcha');
			script.src = `${this.serviceApiUrl}?render=${secret}`;
			document.getElementsByTagName('head')[0].appendChild(script);
		} else {
			// eslint-disable-next-line no-console
			console.warn('Google captcha already has been loaded');
		}
	}

	public remove(): void {
		const googleCaptchaScript = document.querySelector('[data-script="google-captcha"]');
		const googleCaptchaUI = document.querySelector('.grecaptcha-badge');
		if (googleCaptchaScript) {
			googleCaptchaScript.remove();
		}

		if (googleCaptchaUI && googleCaptchaUI.parentElement) {
			googleCaptchaUI.parentElement.remove();
		}

		document.querySelectorAll('script').forEach((script) => {
			if (
				script.src.indexOf('https://www.gstatic.com/recaptcha') > -1
			) {
				script.remove();
			}
		});

		if (window.grecaptcha) {
			delete (window.grecaptcha);
		}

		if (window.__google_recaptcha_client) {
			delete (window.__google_recaptcha_client);
		}

		if (window.___grecaptcha_cfg) {
			delete (window.___grecaptcha_cfg);
		}

		if (window.__recaptcha_api) {
			delete (window.__recaptcha_api);
		}
	}

	public getToken(): Observable<string> {
		return new Observable(subscriber => {
			if (window.grecaptcha) {
				window.grecaptcha.ready(() => {
					if (window.grecaptcha) {
						window.grecaptcha.execute(this.getSecret(), { action : 'submit', })
							.then((token) => {
								subscriber.next(token);
							}).catch((err) => {
								subscriber.error(err);
							});
					}
				});
			}
			return () => null;
		});
	}
}
