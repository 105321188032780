






import { Component, Vue, Prop } from 'vue-property-decorator';
import { CardStatusTypes } from '../../../services/common/ui.service';

@Component({
	name : 'wb-card',
})
export default class WbCard extends Vue {
	@Prop({ default : CardStatusTypes.transparent, })
	public status!: string;

	/**
	 * CREATED
	 */
	public created(): void {
		// eslint-disable-next-line no-console
		console.log('%c INIT uiElements.WbCard ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		// eslint-disable-next-line no-console
		console.info('%c DESTROY uiElements.WbCard ', 'background: purple; color: #FFF');
	}
}
