








































/**
 * Select
 *
 * @copyright Wallboard Inc. 2019
 * @version V1.0.0
 * @author  Gabor Fabian, David Fabian
 * @utf-8   ő
 */
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

import { IModelValue } from '@/services/common/form-validate.service';
import WbInputGrowl from '../wb-input-growl/wb-input-growl.vue';

enum StatusTypes {
	success = 'success',
	danger = 'error',
}

@Component({
	name : 'wb-select',
	components : {
		WbInputGrowl,
	},
})
export default class WbSelect extends Vue {
	public statusTypes = StatusTypes;

	@Prop()
	public model!: IModelValue;

	@Prop({ default : false, })
	public center!: boolean;

	// Output onSelect: EventEmitter

	@Emit('on-select')
	public onSelect(event: Event): string | number | boolean {
		return (event.target as HTMLInputElement).value;
	}

	/**
	 * CREATED
	 */
	public created(): void {
		// eslint-disable-next-line no-console
		console.info('%c CREATE uiElements.input.WbSelect ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		// eslint-disable-next-line no-console
		console.info('%c DESTROY uiElements.input.WbSelect ', 'background: purple; color: #FFF');
	}
}
