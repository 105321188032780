






/**
 * Switch
 *
 * @copyright Wallboard Inc. 2019
 * @version V1.0.0
 * @author  Gabor Fabian, David Fabian
 * @utf-8   ő
 */
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
	name : 'wb-switch',
})
export default class WbSwitch extends Vue {
	@Prop({ default : 'middle', })
	public state!: string;

	/**
	 * CREATED
	 */
	public created(): void {
		// eslint-disable-next-line no-console
		console.info('%c INIT uiElements.inputs.WbSwitch ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		// eslint-disable-next-line no-console
		console.info('%c DESTROY uiElements.inputs.WbSwitch ', 'background: purple; color: #FFF');
	}
}
