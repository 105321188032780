






/**
 * Modal Footer
 *
 * @copyright Wallboard Inc. 2019
 * @version V1.0.0
 * @author  Gabor Fabian, David Fabian
 * @utf-8   ő
 */
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ModalFooterHorizontalPosition } from '@/services/common/ui.service';

@Component({
	name : 'wb-modal-footer',
	components : {},
})
export default class WbModalFooter extends Vue {
	@Prop({ default : ModalFooterHorizontalPosition.end, })
	public position!: string;

	@Prop({ default : true, })
	public underline!: boolean;

	@Prop({ default : 'gap-1', })
	public gap!: string;

	/**
	 * CREATED
	 */
	public created(): void {
		// eslint-disable-next-line no-console
		console.log('%c INIT uiElements.WbModalFooter ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		// eslint-disable-next-line no-console
		console.info('%c DESTROY uiElements.WbModalFooter ', 'background: purple; color: #FFF');
	}
}
