























/**
 * Confirm
 *
 * @copyright Wallboard Inc. 2019
 * @version V1.1.0
 * @lastModified Gabor Fabian 2021.may.13.
 *
 * @author  Gabor Fabian, David Fabian
 * @utf-8   ő
 */
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { StatusTypes } from '@/services/common/ui.service';

@Component({
	name : 'wb-confirm',
})
export default class WbConfirm extends Vue {
	@Prop({ default : '', })
	public title!: string;

	@Prop({ default : '', })
	public message!: string;

	@Prop({ default : {}, })
	public data!: Record<string, unknown>;

	public statusTypes = StatusTypes;

	public created(): void {
		// eslint-disable-next-line no-console
		console.info('%c CREATE modals.WbConfirm ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		// eslint-disable-next-line no-console
		console.info('%c DESTROY modals.WbConfirm ', 'background: purple; color: #FFF');
	}

	@Emit('on-confirm')
	public onConfirm(): Record<string, unknown> {
		return this.data;
	}

	@Emit('on-cancel')
	public onCancel(): boolean {
		return true;
	}
}
