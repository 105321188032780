import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { HttpService } from '@/services/common/http.service';
import { IPersistentStoreService } from '@/services/common/persistent-store.service';
import { IToasterStoreService } from '@/services/common/toaster-store.service';

import { ResponseMessage } from '@/services/interfaces/message.interface';

import { IRequestPayload, IListDto } from '@/services/dtos/shared.dto';
import {
	IListOnePriceGroupDto,
	IPriceGroupApiService,
	IModifyPriceGroupDto,
	IListPricesPayload,
	IListOnePriceGroupPayload
} from '@/services/api/price-group-api.service';

export interface IPricesStoreService {
	prices$: Observable<IListPricesPayload | null>;
	selectedPrice$: Observable<IListOnePriceGroupPayload | null>;
	requestResult$: Observable<IRequestPayload | null>;

	resetRequestResult(): void;

	listPrices(listPricesDto: IListDto): void;
	listOnePrice(listOnePriceGroupDto: IListOnePriceGroupDto): void;
	modifyPrice(modifyPriceDto: IModifyPriceGroupDto): void;
}

export class PricesStoreService implements IPricesStoreService {
	private $store: IPersistentStoreService;
	private $http: HttpService;
	private $toaster: IToasterStoreService;
	private $priceGroupApiService: IPriceGroupApiService;

	private moduleName = 'UserService';

	private lang: Pick<ResponseMessage, 'internal_server_error'> = {
		internal_server_error : 'Internal Server Error.',
	}

	/**
	 * State
	 */

	private prices = new BehaviorSubject<IListPricesPayload | null>(null);
	private selectedPrice = new BehaviorSubject<IListOnePriceGroupPayload | null>(null);
	private requestResult = new BehaviorSubject<IRequestPayload | null>(null);

	/**
	 * Selectors
	 */

	public prices$ = this.prices.asObservable();
	public selectedPrice$ = this.selectedPrice.asObservable();
	public requestResult$ = this.requestResult.asObservable();

	/**
	 * CONSTRUCTOR
	 */
	constructor(
		_store: IPersistentStoreService,
		_http: HttpService,
		_toaster: IToasterStoreService,
		_priceGroupApiService: IPriceGroupApiService
	) {
		// eslint-disable-next-line no-console
		console.info('%c INIT STORE-SERVICE PRICES ', 'background: green; color: #FFF');

		// Get Params
		this.$store = _store;
		this.$http = _http;
		this.$priceGroupApiService = _priceGroupApiService;
		this.$toaster = _toaster;

		this.fixScope();
		this.initState();
	}

	private fixScope(): void {
		// I know it is empty
	}

	private initState(): void {
		// I know it is empty
	}

	/**
	 * Updaters
	 */

	private setPrices(prices: IListPricesPayload): void {
		this.prices.next(prices);
	}

	private setSelectedPrice(price: IListOnePriceGroupPayload): void {
		this.selectedPrice.next(price);
	}

	public resetRequestResult(): void {
		this.requestResult.next(null);
	}

	private setRequestResult(requestPayload: IRequestPayload): void {
		this.requestResult.next(requestPayload);
	}

	/**
	 * Getters
	 */
	public getPrices(): IListPricesPayload | null {
		return this.prices.getValue();
	}

	public getSelectedPrice(): IListOnePriceGroupPayload | null {
		return this.selectedPrice.getValue();
	}

	/**
	 * Effects
	 */

	public listPrices(listPricesDto: IListDto): void {
		this.$priceGroupApiService.getPriceGroup(listPricesDto)
			.pipe(
				tap((pricesPayload: IListPricesPayload) => {
					this.setPrices(pricesPayload);
				})
			).subscribe();
	}

	public listOnePrice(listOnePriceGroupDto: IListOnePriceGroupDto): void {
		this.$priceGroupApiService.getOnePriceGroup(listOnePriceGroupDto)
			.pipe(
				tap((onePricePayload: IListOnePriceGroupPayload) => {
					this.setSelectedPrice(onePricePayload);
				})
			).subscribe();
	}

	public modifyPrice(modifyPriceDto: IModifyPriceGroupDto): void {
		this.$priceGroupApiService.modifyPrice(modifyPriceDto).pipe(
			tap((/* user: IModifyUserPayload */) => {
				this.setRequestResult({ status : 'success', actor : 'modifyPrice', });

				this.$toaster.create({
					type : 'success',
					message : 'Price is updated',
				});
			}),
			catchError((error) => {
				const code: keyof Pick<ResponseMessage, 'internal_server_error'> = (error.response) ? error.response.message : '';

				this.$toaster.create({
					type : 'danger',
					message : this.lang[code],
				});

				this.setRequestResult({
					status : 'error',
					actor : 'modifyPrice',
				});

				return of(null);
			})
		).subscribe();
	}

	/**
	 * Helper
	 */
}
