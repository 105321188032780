var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{attrs:{"selector":"#wb-modal"}},[_c('div',{staticClass:"wb-modal",on:{"contextmenu":function($event){if($event.target !== $event.currentTarget){ return null; }$event.preventDefault();return _vm.close()}}},[_c('wb-transformable',{attrs:{"handler":".wb-modal-header","bounding-area":".wb-modal","leaving-tolerance-top":"0"}},[_c('div',{staticClass:"modal",class:[_vm.modalClassName, _vm.status],style:({
						minWidth: _vm.minWidth,
						minHeight: _vm.minHeight,
						width: _vm.width,
						height: _vm.height,
						maxWidth: _vm.maxWidth,
						maxHeight: _vm.maxHeight,
					})},[_vm._t("default")],2)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }