















import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { Portal } from '@linusborg/vue-simple-portal';

@Component({
	name : 'wb-select-simple-list',
	components : {
		Portal,
	},
})
export default class WbSelectSimpleList extends Vue {
/**
 * Select Simple List
 *
 * @copyright Wallboard Inc. 2019
 * @version V1.0.0
 * @author  Gabor Fabian, David Fabian
 * @utf-8   ő
 */
	@Prop({ default : [], })
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public options!: any;

	@Prop({ default : 250, })
	public transitionInTime!: string;

	@Prop({ default : 250, })
	public transitionOutTime!: string;

	public styles = {
		selectSimpleList : {
			transition : `opacity ${this.transitionInTime}ms`,
			opacity : '0',
			left : '',
			right : '',
			top : '',
			bottom : '',
			minWidth : '',
			width : '',
			maxWidth : '',
			maxHeight : '',
		},
	};

	/**
	 * CREATED
	 */
	public created(): void {
		// eslint-disable-next-line no-console
		console.info('%c CREATE WbSelectSimpleList ', 'background: blue; color: #FFF');
	}

	public mounted(): void {
		this.positionComponent();
	}

	public destroyed(): void {
		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbSelectSimpleList ', 'background: purple; color: #FFF');

		this.removeNativeEventListeners();
	}

	// Outputs
	@Emit('on-close')
	private onClose() {
		return true;
	}

	@Emit('on-select')
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private onSelect(option: any) {
		this.makeComponentInvisible();
		return option;
	}

	@Emit('on-show-list')
	private onShowList(direction: string) {
		return direction;
	}
	// Outputs

	private onDocumentKeyDown(e: KeyboardEvent): void {
		if (e.which === 27) {
			this.makeComponentInvisible();
		}
	}

	private onDocumentClick(e: MouseEvent): void {
		// eslint-disable-next-line
		const containerBox = (this.$refs['select-simple-list'] as Element).getBoundingClientRect();

		if (e.clientX < containerBox.left || e.clientX > containerBox.right || e.clientY < containerBox.top || e.clientY > containerBox.bottom) {
			this.makeComponentInvisible();
		}
	}

	private onWindowScroll(e: Event): void {
		const eventTarget = e.target as Element;
		if (!eventTarget.closest('#wb-select-simple-list')) {
			this.makeComponentInvisible();
		}
	}

	private addNativeEventListeners(): void {
		document.addEventListener('keydown', this.onDocumentKeyDown);
		document.addEventListener('click', this.onDocumentClick);
		window.addEventListener('scroll', this.onWindowScroll, { capture : true, });
	}

	private removeNativeEventListeners(): void {
		document.removeEventListener('keydown', this.onDocumentKeyDown);
		document.removeEventListener('click', this.onDocumentClick);
		window.removeEventListener('scroll', this.onWindowScroll, { capture : true, });
	}

	private makeComponentVisible(): void {
		this.styles.selectSimpleList.opacity = '1';
	}

	private makeComponentInvisible(): void {
		this.styles.selectSimpleList.transition = `opacity ${this.transitionOutTime}ms`;
		this.styles.selectSimpleList.opacity = '0';
		setTimeout(() => {
			this.onClose();
		}, +this.transitionOutTime);
	}

	private positionComponent(): void {
		this.$nextTick(() => {
			const parentElement = this.$el.parentElement;

			if (parentElement) {
				const parentElementBox = parentElement.getBoundingClientRect();

				const availableSpace = {
					onTheLeft : parentElementBox.left,
					onTheRight : window.innerWidth - parentElementBox.right,
					onTheTop : parentElementBox.top,
					onTheBottom : window.innerHeight - parentElementBox.bottom,
				};

				if (availableSpace.onTheBottom >= availableSpace.onTheTop) {
					this.styles.selectSimpleList.left = parentElementBox.left + 'px';
					this.styles.selectSimpleList.top = parentElementBox.bottom + 'px';
					this.styles.selectSimpleList.maxHeight = window.innerHeight - parentElementBox.bottom - 15 + 'px';
					this.onShowList('bottom');
				} else {
					this.styles.selectSimpleList.left = parentElementBox.left + 'px';
					this.styles.selectSimpleList.bottom = (window.innerHeight - parentElementBox.top) + 'px';
					this.styles.selectSimpleList.maxHeight = parentElementBox.top - 15 + 'px';
					this.onShowList('top');
				}

				this.styles.selectSimpleList.minWidth = parentElementBox.width + 'px';
				this.styles.selectSimpleList.maxWidth = parentElementBox.width * 3 + 'px';

				setTimeout(() => {
					this.makeComponentVisible();
					this.addNativeEventListeners();
				}, 0);
			}
		});
	}
}
