






































/**
 * Input
 *
 * @copyright Wallboard Inc. 2019
 * @version V1.0.0
 * @author  Gergely Fucskar, Fabian, David Fabian
 * @utf-8   ő
 */
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

import { IModelValue } from '@/services/common/form-validate.service';
import wbInputGrowl from '../wb-input-growl/wb-input-growl.vue';

enum StatusTypes {
    success = 'success',
    danger = 'error',
}

@Component({
	name : 'wb-date-picker',
	components : { DatePicker, wbInputGrowl, },
})

export default class WbDatePicker extends Vue {
	public statusTypes = StatusTypes;

	@Prop()
	public model!: IModelValue;

	@Prop({ required : false, default : 'datetime', })
	public type!: string;

	@Prop({ required : false, default : 'date', })
	public valueType!: string;

	@Prop({ required : false, default : false, })
	showSecond!: boolean;

	@Prop({ required : false, default : true, })
	public confirm!: boolean;

	@Prop({ required : false, })
	public disabledDate!: (date: Date) => boolean;

	@Prop({ required : false, })
	public disabledTime!: (date: Date) => boolean;

	public get activeFromDate(): Date {
		const date = new Date(String(this.model.value));

		if (this.showSecond) {
			return date;
		} else {
			return new Date(date.setSeconds(0));
		}
	}

	public set activeFromDate(date: Date) {
		this.model.value = date.toISOString();
		this.$emit('on-select', date);
	}

	public created(): void {
		// eslint-disable-next-line no-console
		console.log('%c INIT uiElements.Inputs.WbDatePicker ', 'background: blue; color: #FFF');
	}

	@Watch('model', { immediate : true, deep : true, })
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	private onModelChanged(val: IModelValue, oldVal: IModelValue) {
		if (val.focus !== undefined && val.focus === true) {
			(this.$refs.datePicker as HTMLInputElement).focus();
		}
	}
}
