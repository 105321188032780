import { Observable } from 'rxjs';
import { HttpService } from '@/services/common/http.service';

import { IListDto } from '@/services/dtos/shared.dto';
import { IListOneUserPayload, IListUsersPayload } from '@/services/payloads/user.payload';
import {
	IAddUserPayload, IChangeOwnPasswordUserPayload,
	IListUsersBalancePayload,
	IModifyUserPayload,
	IResendConfirmationEmailUserPayload
} from '@/services/user-store.service';

// <editor-fold desc="DTOs">
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export type IListUsersDto = IListDto;

export interface IListOneUserDto {
	id: number;
}

export interface IAddUserDto {
	organization: string;
	firstname: string;
	lastname: string;
	email: string;
	role?: string;
	isDev?: boolean;
}

export interface IModifyUserDto {
	id: number;
	organization: string;
	firstname: string;
	lastname: string;
	email?: string;
	role?: string;
	isDev?: boolean;
	isEnabled: boolean;
}

export interface IModifyUserPrivilegesDto {
	id: number;
	isaac: boolean;
	developer: boolean;
	topUpRequestRecipient: boolean;
	userRegisteredRecipient: boolean;
	canModifyBalance: boolean;
}

export interface IModifyProfileDto {
	organization: string;
	firstname: string;
	lastname: string;
}

export interface IDisableUserDto {
	id: number;
	isEnabled: boolean;
}

export interface IDeleteUserDto {
	id: number;
	password: string;
}

export interface IResendConfirmationEmailUserDto {
	id: number;
}

export interface IChangeOwnPasswordUserDto {
	oldPassword: string;
	newPassword: string;
}
// </editor-fold desc="DTOs">

export interface IUserApiService {
	getUsers(listUsersDto: Partial<IListUsersDto> & Pick<IListUsersDto, 'pageLength' | 'page'>): Observable<IListUsersPayload>;
	getUsersBalance(listUsersDto: Partial<IListUsersDto> & Pick<IListUsersDto, 'pageLength' | 'page'>): Observable<IListUsersBalancePayload>;
	getOneUser(listUserDto: IListOneUserDto): Observable<IListOneUserPayload>;
	addUser(addUserDto: IAddUserDto): Observable<IAddUserPayload>;
	modifyUser(modifyUserDto: Partial<IModifyUserDto>): Observable<IModifyUserPayload>;
	modifyUserPrivileges(modifyUserPrivilegesDto: Partial<IModifyUserPrivilegesDto>): Observable<IModifyUserPayload>;
	// TODO: why IModifyUserPayload and not deleteUserPayload
	deleteUser(deleteUserDto: IDeleteUserDto): Observable<IModifyUserPayload>;

	getProfile(): Observable<IListOneUserPayload>;
	modifyProfile(user: Partial<Pick<IModifyProfileDto, 'firstname' | 'lastname' | 'organization'>>): Observable<IModifyUserPayload>;
	deleteProfile(): Observable<unknown>;
	changeOwnPassword(user: IChangeOwnPasswordUserDto): Observable<IChangeOwnPasswordUserPayload>;

	resendConfirmationEmail(user: IResendConfirmationEmailUserDto): Observable<IResendConfirmationEmailUserPayload>;
}

export class UserApiService implements IUserApiService {
	private $http: HttpService;

	private serviceRootUrl = '/api/user';

	constructor(_http: HttpService) {
		this.$http = _http;
	}

	public getUsers(
		listUsersDto: Partial<IListUsersDto> & Pick<IListUsersDto, 'pageLength' | 'page'>
	): Observable<IListUsersPayload> {
		return this.$http.get(
			'getUsers',
			this.serviceRootUrl,
			{
				params : listUsersDto,
			}
		);
	}

	public getUsersBalance(
		listUsersDto: Partial<IListUsersDto> & Pick<IListUsersDto, 'pageLength' | 'page'>
	): Observable<IListUsersBalancePayload> {
		return this.$http.get(
			'getUsers',
			`${this.serviceRootUrl}/balance`,
			{
				params : listUsersDto,
			}
		);
	}

	public getOneUser(listOneUserDto: IListOneUserDto): Observable<IListOneUserPayload> {
		return this.$http.get(
			'login',
			`${this.serviceRootUrl}/${listOneUserDto.id}`
		);
	}

	public addUser(addUserDto: IAddUserDto): Observable<IAddUserPayload> {
		return this.$http.post(
			'addUser',
			this.serviceRootUrl,
			{
				body : addUserDto,
			}
		);
	}

	public modifyUser(modifyUserDto: Partial<IModifyUserDto>): Observable<IModifyUserPayload> {
		return this.$http.put(
			'modifyUser',
			`${this.serviceRootUrl}/${modifyUserDto.id}`,
			{
				body : modifyUserDto,
			}
		);
	}

	public modifyUserPrivileges(
		modifyUserPrivilegesDto: Partial<IModifyUserPrivilegesDto>
	): Observable<IModifyUserPayload> {
		return this.$http.put(
			'modifyUserPrivileges',
			`${this.serviceRootUrl}/privileges/${modifyUserPrivilegesDto.id}`,
			{
				body : modifyUserPrivilegesDto,
			}
		);
	}

	public deleteUser(deleteUserDto: IDeleteUserDto): Observable<IModifyUserPayload> {
		return this.$http.put(
			'deleteUser',
			`${this.serviceRootUrl}/delete/${deleteUserDto.id}`,
			{
				body : deleteUserDto,
			}
		);
	}

	public resendConfirmationEmail(
		user: IResendConfirmationEmailUserDto
	): Observable<IResendConfirmationEmailUserPayload> {
		return this.$http.post(
			'resendConfirmationEmail',
			`${this.serviceRootUrl}/resend-verification-email/${user.id}`
		);
	}

	public getProfile(): Observable<IListOneUserPayload> {
		return this.$http.get(
			'getProfile',
			`${this.serviceRootUrl}/own`
		);
	}

	public modifyProfile(
		user: Partial<Pick<IModifyProfileDto, 'firstname' | 'lastname' | 'organization'>>
	): Observable<IModifyUserPayload> {
		return this.$http.put(
			'modifyProfile',
			`${this.serviceRootUrl}/own`,
			{
				body : user,
			}
		);
	}

	public deleteProfile(): Observable<unknown> {
		return this.$http.delete(
			'deleteProfile',
			`${this.serviceRootUrl}/own`
		);
	}

	public changeOwnPassword(password: IChangeOwnPasswordUserDto): Observable<IChangeOwnPasswordUserPayload> {
		return this.$http.post(
			'changePassword',
			`${this.serviceRootUrl}/own/change-password`,
			{
				body : password,
			}
		);
	}
}
