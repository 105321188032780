






import { Component, Vue } from 'vue-property-decorator';

@Component({
	name : 'wb-horizontal-menu',
})
export default class WbHorizontalMenu extends Vue {
	/**
	 * CREATED
	 */
	public created(): void {
		// eslint-disable-next-line no-console
		console.info('%c CREATE WbHorizontalMenu ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbHorizontalMenu ', 'background: purple; color: #FFF');
	}
}
