import { Observable } from 'rxjs';

import { HttpService, RequestQueryParams } from '@/services/common/http.service';
import { IStatusOkPayload } from '@/services/payloads/status.payload';
import {
	IApp,
	IFirmware
} from '@/services/app-and-firmware-store.service';
import { IListDto } from '@/services/dtos/shared.dto';

export enum ReleaseType {
	RC_ = 'RC',
	RELEASE_ = 'RELEASE',
}

// <editor-fold desc="DTOs">
export interface IListAppsDto extends IListDto {
	id?: number;
	showDisabled?: boolean;
	showOnlyRc?: boolean;
	showOnlyRelease?: boolean;
}

export interface IListOneAppDto {
	id: number;
}

export interface IListFirmwaresDto extends IListDto {
	showDisabled?: boolean;
	showOnlyRc?: boolean;
	showOnlyRelease?: boolean;
}

export interface IListOneFirmwareDto {
	id: number;
}

export interface IModifyAppDto{
	id: number;
	changeLog: string;
	description: string;
}

export interface IModifyFirmwareDto{
	id: number;
	description: string;
}
// </editor-fold desc="DTOs">

// <editor-fold desc="Payloads">
export interface ISyncPayload {
	status: 'Started' | 'Pending';
}

export interface IListFirmwaresPayload {
	count: number;
	firmwares: IFirmware[];
}

export interface IListOneFirmwarePayload {
	id: number;
	nexusItemId: string;
	nexusAssetId: string;
	releaseType: 'RC' | 'Production';
	name: string;
	env: string;
	version: string;
	tags: string;
	flasherRequirement: string;
	changeLog: string;
	description: string;
	time: number;
	isEnabled: boolean;
}

export interface IListAppsPayload {
	count: number;
	flashers: IApp[];
}

export interface IListOneAppPayload {
	id: number;
	time: Date;
	name: string;
	version: string;
	releaseType: ReleaseType;
	url: string;
	nexusAssetId: string;
	isEnabled: boolean;
	busy: boolean;
	changeLog: string;
	description: string;
}

export interface IModifyAppPayload {
	id: number;
}

export interface IModifyFirmwarePayload {
	id: number;
}
// </editor-fold desc="Payloads">

export interface IAppAndFirmwareApiService {
	syncAppAndFirmwares(): Observable<ISyncPayload>;

	getApps(
		listAppsDto: Partial<IListAppsDto> & Pick<IListAppsDto, 'pageLength' | 'pageLength'>
	): Observable<IListAppsPayload>;
	getOneApp(listOneAppDto: IListOneAppDto): Observable<IListOneAppPayload>;
	enableApp(appId: number): Observable<IStatusOkPayload>;
	disableApp(appId: number): Observable<IStatusOkPayload>;
	modifyApp(modifyAppDto: Partial<IModifyAppDto>): Observable<IModifyAppPayload>;

	getFirmwares(
		listFirmwaresDto: Partial<IListFirmwaresDto> & Pick<IListFirmwaresDto, 'page' | 'pageLength'>
	): Observable<IListFirmwaresPayload>;
	getOneFirmware(listOneFirmwareDto: IListOneFirmwareDto): Observable<IListOneFirmwarePayload>;
	enableFirmware(firmwareId: number): Observable<IStatusOkPayload>;
	disableFirmware(firmwareId: number): Observable<IStatusOkPayload>;
	modifyFirmware(modifyFirmwareDto: Partial<IModifyFirmwareDto>): Observable<IModifyFirmwarePayload>;
}

export class AppAndFirmwareApiService implements IAppAndFirmwareApiService {
	private serviceRootUrl = '/api/app-and-firmware';

	constructor(
		private $http: HttpService
	) {
		// eslint-disable-next-line no-console
		console.info('%c INIT APP AND FIRMWARE API SERVICE', 'background: green; color: #FFF');

		this.fixScope();
		this.initState();
	}

	private fixScope(): void {
		// I know it is empty
	}

	private initState(): void {
		// I know it is empty
	}

	public syncAppAndFirmwares(): Observable<ISyncPayload> {
		return this.$http.post(
			'syncAppAndFirmwares',
			`${this.serviceRootUrl}/sync`
		);
	}

	// Apps
	public getApps(
		listAppsDto: Partial<IListAppsDto> & Pick<IListAppsDto, 'pageLength' | 'pageLength'>
	): Observable<IListAppsPayload> {
		return this.$http.get(
			'getApps',
			`${this.serviceRootUrl}/flashers`,
			{
				params : listAppsDto as RequestQueryParams,
			}
		);
	}

	public getOneApp(listOneAppDto: IListOneAppDto): Observable<IListOneAppPayload> {
		return this.$http.get(
			'getApp',
			`${this.serviceRootUrl}/flasher/${listOneAppDto.id}`
		);
	}

	public enableApp(appId: number): Observable<IStatusOkPayload> {
		return this.$http.put(
			'enableApp',
			`${this.serviceRootUrl}/enable/flasher/${appId}`
		);
	}

	public disableApp(appId: number): Observable<IStatusOkPayload> {
		return this.$http.put(
			'disableApp',
			`${this.serviceRootUrl}/disable/flasher/${appId}`
		);
	}

	public modifyApp(modifyAppDto: Partial<IModifyAppDto>): Observable<IModifyAppPayload> {
		return this.$http.put(
			'modifyApp',
			`${this.serviceRootUrl}/flasher/${modifyAppDto.id}`,
			{
				body : modifyAppDto,
			}
		);
	}

	// Firmwares
	public getFirmwares(
		listFirmwaresDto: Partial<IListFirmwaresDto> & Pick<IListFirmwaresDto, 'page' | 'pageLength'>
	): Observable<IListFirmwaresPayload> {
		return this.$http.get(
			'getFirmwares',
			`${this.serviceRootUrl}/firmwares`,
			{
				params : listFirmwaresDto as RequestQueryParams,
			}
		);
	}

	public getOneFirmware(listOneFirmwareDto: IListOneFirmwareDto): Observable<IListOneFirmwarePayload> {
		return this.$http.get(
			'getFirmware',
			`${this.serviceRootUrl}/firmware/${listOneFirmwareDto.id}`
		);
	}

	public enableFirmware(firmwareId: number): Observable<IStatusOkPayload> {
		return this.$http.put(
			'enableFirmware',
			`${this.serviceRootUrl}/enable/firmware/${firmwareId}`
		);
	}

	public disableFirmware(firmwareId: number): Observable<IStatusOkPayload> {
		return this.$http.put(
			'disableFirmware',
			`${this.serviceRootUrl}/disable/firmware/${firmwareId}`
		);
	}

	public modifyFirmware(modifyFirmwareDto: Partial<IModifyFirmwareDto>): Observable<IModifyFirmwarePayload> {
		return this.$http.put(
			'modifyFirmware',
			`${this.serviceRootUrl}/firmware/${modifyFirmwareDto.id}`,
			{
				body : modifyFirmwareDto,
			}
		);
	}
}
