







/**
 * Form Label
 *
 * @copyright Wallboard Inc. 2019
 * @version V1.0.0
 * @author  Gabor Fabian, David Fabian
 * @utf-8   ő
 */
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
	name : 'wb-form-label',
})
export default class WbFormLabel extends Vue {
	@Prop({ default : true, })
	public wrap!: boolean;

	@Prop({ default : 'normal', })
	public size!: boolean;

	@Prop({ default : false, })
	public required!: boolean;

	/**
	 * CREATED
	 */
	public created(): void {
		// eslint-disable-next-line no-console
		console.log('%c INIT uiElements.forms.WbFormLabel ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		// eslint-disable-next-line no-console
		console.info('%c DESTROY uiElements.forms.WbFormLabel ', 'background: purple; color: #FFF');
	}
}
