import { Observable } from 'rxjs';
import { HttpService } from '@/services/common/http.service';
import { IListDto } from '@/services/dtos/shared.dto';

// <editor-fold desc="DTOs">
export interface IListOnePriceGroupDto {
	id: number;
}

export interface IModifyPriceGroupDto {
	id: number;
	price: number;
}
// </editor-fold desc="DTOs">

// <editor-fold desc="Payloads">
export interface IPrice {
	id: number;
	name: string;
	price: number;
}

export interface IListPricesPayload {
	count: number;
	priceGroups: IPrice[];
}

export interface IListOnePriceGroupPayload {
	id: number;
	price: number;
}

export interface IModifyPriceGroupPayload {
	status: string;
}
// </editor-fold desc="Payloads">

export interface IPriceGroupApiService {
	getPriceGroup(listPriceGroup: Partial<IListDto> & Pick<IListDto, 'page' | 'pageLength'>): Observable<IListPricesPayload>;
	getOnePriceGroup(listOnePriceGroupDto: IListOnePriceGroupDto): Observable<IListOnePriceGroupPayload>;
	modifyPrice(modifyPriceGroupDto: IModifyPriceGroupDto): Observable<IModifyPriceGroupPayload>;
}

export class PriceGroupApiService implements IPriceGroupApiService {
	private $http: HttpService;

	private serviceRootUrl = '/api/price-group';

	constructor(_http: HttpService) {
		this.$http = _http;
	}

	public getPriceGroup(listPriceGroup: Partial<IListDto> & Pick<IListDto, 'page' | 'pageLength'>): Observable<IListPricesPayload> {
		return this.$http.get(
			'getPriceGroup',
			`${this.serviceRootUrl}`,
			{
				params : listPriceGroup,
			}
		);
	}

	public getOnePriceGroup(listOnePriceGroupDto: IListOnePriceGroupDto): Observable<IListOnePriceGroupPayload> {
		return this.$http.get(
			'getPriceGroup',
			`${this.serviceRootUrl}/${listOnePriceGroupDto.id}`
		);
	}

	public modifyPrice(modifyPriceGroupDto: IModifyPriceGroupDto): Observable<IModifyPriceGroupPayload> {
		return this.$http.put(
			'modifyPrice',
			`${this.serviceRootUrl}/${modifyPriceGroupDto.id}`,
			{
				body : { price : +modifyPriceGroupDto.price, },
			});
	}
}
