var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wb-select",class:{
			success : _vm.model.status.class === _vm.statusTypes.success,
			danger : _vm.model.status.class === _vm.statusTypes.danger,
			disabled : _vm.model.disabled,
			modified : _vm.model.oldValue !== this.model.value,
			center : _vm.center
		}},[_c('select',{attrs:{"disabled":_vm.model.disabled},on:{"change":function (event) { return _vm.onSelect(event); }}},_vm._l((_vm.model.options),function(option){return _c('option',{key:option.value,domProps:{"selected":option.value === _vm.model.value,"value":option.value}},[_vm._v(_vm._s(option.label))])}),0),_c('span',{staticClass:"icon wbn wb-icon-caret-down"}),(
				_vm.model.status.attr === 'required' ||
				_vm.model.status.attr === 'email' ||
				_vm.model.status.attr === 'sameAs' ||
				_vm.model.status.attr === 'custom'
			)?_c('wb-input-growl',{class:{
				success : this.model.status.class === _vm.statusTypes.success,
				danger : this.model.status.class === _vm.statusTypes.danger,
			}},[_vm._v(" "+_vm._s(_vm.model.status.txt)+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }