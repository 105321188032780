











/**
 * Input Group
 *
 * @copyright Wallboard Inc. 2019
 * @version V1.0.0
 * @author  Gabor Fabian, David Fabian
 * @utf-8   ő
 */
import { Component, Vue, Prop } from 'vue-property-decorator';
import { InputGroupGap } from '@/services/common/ui.service';

@Component({
	name : 'wb-input-group',
})
export default class WbInputGroup extends Vue {
	public inputGroupGap = InputGroupGap;

	@Prop({ default : InputGroupGap.gapNormal, })
	public gap!: InputGroupGap;

	@Prop({ default : 'stretch', })
	public verticalAlign!: string;

	/**
	 * CREATED
	 */
	public created(): void {
		// eslint-disable-next-line no-console
		console.log('%c INIT uiElements.forms.WbInputGroup ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		// eslint-disable-next-line no-console
		console.info('%c DESTROY uiElements.forms.WbInputGroup ', 'background: purple; color: #FFF');
	}
}
