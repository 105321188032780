import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap, catchError, finalize } from 'rxjs/operators';

import { HttpService, InterceptorData } from '@/services/common/http.service';
import { IPersistentStoreService } from '@/services/common/persistent-store.service';

import {
	StatusOfConfirmEmail,
	StatusOfChangePassword,
	IAuthApiService,
	ILoginDto,
	IRegisterDto,
	IForgottenPasswordDto,
	IChangePasswordDto,
	IJwtUserPayload,
	IConfirmEmailAddressPayload,
	IAccessTokenPayload
} from './api/auth-api.service';
import { UserRoles } from '@/services/payloads/user.payload';
import { ResponseMessage } from '@/services/interfaces/message.interface';

export interface IStateOfConfirmEmail {
	// eslint-disable-next-line camelcase
	refresh_token: string;
	// eslint-disable-next-line camelcase
	access_token: string;
	status: StatusOfConfirmEmail;
}

export interface IStateOfChangePassword {
	status: StatusOfChangePassword;
}

export interface IAuthStoreService {
	isLoggedInSuccess$: Observable<boolean | null>;
	registrationState$: Observable<{action: 'password_too_short' | 'password_weak' | 'email_already_in_use' | 'success'} | null>;
	isRequestEmailForForgottenPasswordSuccess$: Observable<boolean | null>;
	stateOfConfirmEmailAddress$: Observable<IStateOfConfirmEmail | null>;
	stateOfChangePassword$: Observable<IStateOfChangePassword | null>;

	lngUserRoles: {[key in UserRoles]: string};
	lang: Pick<ResponseMessage, 'password_too_short' | 'password_weak'>;

	resetIsLoggedInSuccess(): void;
	getIsLoggedIn(): Promise<boolean>;
	getUserRole(): UserRoles;
	register(user: IRegisterDto): void;
	confirmEmailAddress(token: string): void;
	login(loginDto: ILoginDto): void;
	logout(): void;
	resetRequestEmailForForgottenPasswordSuccess(): void;
	resetRegistrationState(): void;
	requestEmailToResetPassword(forgottenPassword: IForgottenPasswordDto): void;
	changePassword(changePassword: IChangePasswordDto, token: string): void;
}

export class AuthStoreService implements IAuthStoreService {
	private $store: IPersistentStoreService;
	private $http: HttpService;
	private $authApiService: IAuthApiService;

	private moduleName = 'AuthService';

	public lang: Pick<ResponseMessage, 'password_too_short' | 'password_weak'> = {
		password_too_short : 'Your new password must be at least 8 characters long.',
		password_weak : 'Your password is too weak: It should contains at least 2 uppercase letters, 2 digits and 2 special characters',
	}

	/**
	 * State
	 *
	 * Can be behaviour subject or variable.
	 */

	private accessToken = '';
	private refreshToken = '';
	private jwtUser: IJwtUserPayload = {
		id : -1,
		email : '',
		role : UserRoles.NONE,
	};

	private registrationState = new BehaviorSubject<{action: 'password_too_short' | 'password_weak' | 'email_already_in_use' | 'success'} | null>(null);
	private stateOfConfirmEmailAddress = new BehaviorSubject<IStateOfConfirmEmail | null>(null);
	private isLoggedInSuccess = new BehaviorSubject<boolean | null>(null);
	private isRequestEmailForForgottenPasswordSuccess = new BehaviorSubject<boolean | null>(null);
	private stateOfChangePassword = new BehaviorSubject<IStateOfChangePassword | null>(null);

	/**
	 * Selectors
	 *
	 * Always public.
	 * For the changes needs to be subscribed.
	 */

	public registrationState$ = this.registrationState.asObservable();
	public stateOfConfirmEmailAddress$ = this.stateOfConfirmEmailAddress.asObservable();
	public isLoggedInSuccess$ = this.isLoggedInSuccess.asObservable();
	public isRequestEmailForForgottenPasswordSuccess$ = this.isRequestEmailForForgottenPasswordSuccess.asObservable();
	public stateOfChangePassword$ = this.stateOfChangePassword.asObservable();

	/**
	 * CONSTRUCTOR
	 */
	constructor(
		_store: IPersistentStoreService,
		_http: HttpService,
		_authApiService: IAuthApiService
	) {
		// eslint-disable-next-line no-console
		console.info('%c INIT STORE-SERVICE AUTH ', 'background: green; color: #FFF');

		// Get Params
		this.$store = _store;
		this.$http = _http;
		this.$authApiService = _authApiService;

		this.fixScope();
		this.initState();

		this.setDefaultHttpHeaders(this.accessToken);
		this.handleUnauthorizedHttpError();
	}

	private fixScope(): void {
		this.getAccessToken = this.getAccessToken.bind(this);
		this.getRefreshToken = this.getRefreshToken.bind(this);
	}

	private initState(): void {
		this.accessToken = this.$store.getState(this.moduleName, 'accessToken');
		this.refreshToken = this.$store.getState(this.moduleName, 'refreshToken');
	}

	/**
	 * Updaters
	 *
	 * Can be public or private.
	 * If it is public bind needed in the constructor.
	 */

	/* public setUser(user: User | null): void {
		this.user.next(user);
	}; */

	private setAccessToken(token: string) {
		this.accessToken = token;
		this.$store.setState(this.moduleName, 'accessToken', token);
	}

	private setRefreshToken(token: string) {
		this.refreshToken = token;
		this.$store.setState(this.moduleName, 'refreshToken', token);
	}

	private setJwtUser(jwtToken: IJwtUserPayload) {
		this.jwtUser = jwtToken;
	}

	private setIsLoggedInSuccess(status: boolean) {
		this.isLoggedInSuccess.next(status);
	}

	public resetIsLoggedInSuccess(): void {
		this.isLoggedInSuccess.next(null);
	}

	private setRegistrationState(state: { action: 'password_too_short' | 'password_weak' | 'email_already_in_use' | 'success' }) {
		this.registrationState.next(state);
	}

	public resetRegistrationState(): void {
		this.registrationState.next(null);
	}

	private setIsRequestEmailForForgottenPasswordSuccess(status: boolean) {
		this.isRequestEmailForForgottenPasswordSuccess.next(status);
	}

	public resetRequestEmailForForgottenPasswordSuccess(): void {
		this.isRequestEmailForForgottenPasswordSuccess.next(null);
	}

	private setStateOfConfirmEmailAddress(state: IStateOfConfirmEmail) {
		this.stateOfConfirmEmailAddress.next(state);
	}

	private setStateOfChangePassword(state: IStateOfChangePassword) {
		this.stateOfChangePassword.next(state);
	}

	/**
	 * Getters
	 *
	 * Can be public or private.
	 */

	public getAccessToken(): string {
		return this.accessToken;
	}

	public getRefreshToken(): string {
		return this.refreshToken;
	}

	public getUserRole(): UserRoles {
		const jwtUser: IJwtUserPayload = this.getJwtUser();
		return ('' + jwtUser.role as UserRoles);
	}

	private getJwtUser() {
		return this.jwtUser;
	}

	/**
	 * Effects
	 *
	 * Mostly public, sometimes private.
	 * It contains every async operation and business logic.
	 * If it is public bind needed in the constructor.
	 */

	public async getIsLoggedIn(): Promise<boolean> {
		const jwtUser: IJwtUserPayload | null = await this.requestJwtUser()
			.catch(() => {
				return null;
			});

		if (jwtUser) {
			this.setJwtUser(jwtUser);
			return true;
		} else {
			return false;
		}
	}

	private requestJwtUser(): Promise<IJwtUserPayload> {
		return new Promise<IJwtUserPayload>((resolve, reject) => {
			const response = this.$authApiService.isLoggedIn();

			return response.subscribe({
				next : resolve,
				error : reject,
			});
		});
	}

	public register(user: IRegisterDto): void {
		this.$authApiService.register(user)
			.pipe(
				tap(() => {
					this.setRegistrationState({ action : 'success', });
				}),
				catchError((err: {response: {message: 'password_too_short' | 'password_weak' | 'email_already_in_use'} }) => {
					this.setRegistrationState({ action : err.response.message, });

					return of(null);
				})
			).subscribe();
	}

	public confirmEmailAddress(token: string): void {
		this.$authApiService.confirmEmailAddress(token)
			.pipe(
				tap((response: IConfirmEmailAddressPayload) => {
					this.setStateOfConfirmEmailAddress({
						refresh_token : response.refresh_token,
						access_token : response.access_token,
						status : StatusOfConfirmEmail.success,
					});
				}),
				catchError((err) => {
					const status: StatusOfConfirmEmail = (err.response) ? err.response.message : StatusOfConfirmEmail.none;
					this.setStateOfConfirmEmailAddress({
						refresh_token : '',
						access_token : '',
						status : status,
					});

					return of(null);
				})
			).subscribe();
	}

	public requestEmailToResetPassword(forgottenPassword: IForgottenPasswordDto): void {
		this.$authApiService.requestEmailToResetPassword(forgottenPassword)
			.pipe(
				tap(() => {
					this.setIsRequestEmailForForgottenPasswordSuccess(true);
				})
			).subscribe();
	}

	public changePassword(changePassword: IChangePasswordDto, token: string): void {
		this.$authApiService.changePassword(changePassword, token)
			.pipe(
				tap(() => {
					this.setStateOfChangePassword({ status : StatusOfChangePassword.success, });
				}),
				catchError((err) => {
					const status = (err.response) ? err.response.message : StatusOfChangePassword.none;
					this.setStateOfChangePassword({ status, });

					return of(null);
				})
			).subscribe();
	}

	public login(loginDto: ILoginDto): void {
		this.$authApiService.login(loginDto)
			.pipe(
				tap((accessTokenPayload: IAccessTokenPayload) => {
					this.setAccessToken(accessTokenPayload.access_token);
					this.setRefreshToken(accessTokenPayload.refresh_token);
					this.setDefaultHttpHeaders(accessTokenPayload.access_token);

					this.setIsLoggedInSuccess(true);
				}),
				catchError(() => {
					this.setIsLoggedInSuccess(false);

					return of(null);
				})
			).subscribe();
	}

	public logout(cb: (()=>void) | null = null): void {
		this.$authApiService.logout()
			.pipe(
				catchError((error) => {
					// eslint-disable-next-line no-console
					console.error(error);
					return of(null);
				}),
				finalize(() => {
					this.setAccessToken('');
					this.setRefreshToken('');
					this.setJwtUser({
						id : -1,
						email : '',
						role : UserRoles.NONE,
					});

					if (cb) {
						cb();
					} else {
						location.reload();
					}
				})
			).subscribe();
	}

	/**
	 * Helper
	 *
	 * Helper, filter, etc functions
	 */

	private setDefaultHttpHeaders(accessToken: string) {
		this.$http.setHeaderSchema('default', 'Content-Type', 'application/json; charset=UTF-8');
		this.$http.setBearerAuthorizationHeader('default', accessToken);
	}

	public lngUserRoles = {
		[UserRoles.SUPERADMIN] : 'Super Admin',
		[UserRoles.ADMIN] : 'Admin',
		[UserRoles.USER] : 'User',
		[UserRoles.NONE] : 'None',
	};

	/**
	 * INTERCEPTOR
	 */

	private handleUnauthorizedHttpError() {
		this.$http.interceptor$.subscribe((data: InterceptorData | null) => {
			if (data && data.statusCode === 401 && !(
				location.pathname === '/auth/login' ||
				location.pathname === '/auth/forgotten-password' ||
				location.pathname === '/auth/registration' ||
				location.pathname.includes('/auth/new-password')
			)) {
				this.logout(() => {
					location.href = '/auth/login';
				});
			} else if (
				data && data.statusCode < 400 &&
				location.pathname === '/auth/new-password'
			) {
				this.logout();
			}
		});
	}
}
