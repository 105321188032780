/**
 * HttpService using Rxjs/ajax
 *
 * @copyright Wallboard Inc. since 2019
 * @version v2.0.3
 * @author Gabor Fabian, Gergo Fucskar
 * @lastModified Gabor Fabian 2022.Mar.22.
 */

import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { ajax, AjaxResponse, AjaxError } from 'rxjs/ajax';
import { filter, map, tap, timeout, catchError } from 'rxjs/operators';

// <editor-fold desc="File Class: Uploader, Downloader">

export interface FetchResponse {
	error?: {
		status: string;
		code: number;
		message: string;
	};
	// eslint-disable-next-line @typescript-eslint/ban-types
	body?: object;
}

class FileDownloader {
	public progress$: Observable<number>;
	private progress = new BehaviorSubject<number>(0);

	constructor(
		private url: string,
		private fileName: string,
		private requestHeaders: {[key: string]: string}
	) {
		this.progress$ = this.progress.asObservable();

		this.start();

		// eslint-disable-next-line no-console
		console.log('%c INIT SERVICE FILE DOWNLOADER ', 'background: green; color: #FFF');
	}

	private setProgress(progress: number) {
		if (progress === -1) {
			this.progress.error('noContentLength');
			throw new Error('Error');
		}

		this.progress.next(progress);

		if (progress === 100) {
			this.progress.complete();
		}
	}

	private async fetchFile(url: string, requestHeaders: {[key: string]: string}): Promise<Blob> {
		// Start fetch and obtain a reader
		const response: Response = await fetch(url, {
			method : 'GET',
			headers : requestHeaders,
		});

		if (response) {
			const reader = (response.body as ReadableStream<Uint8Array>).getReader();

			// Get total length
			const contentLength = response.headers.get('Content-Length') || 0;

			// Read the data
			let receivedLength = 0;
			const chunks: Uint8Array[] = [];
			let tempProgress = 0;

			while (true) {
				const { done, value, } = await reader.read();

				if (done) {
					break;
				}

				if (value) {
					chunks.push(value);
					receivedLength += value.length;
				}

				if (contentLength > 0) {
					const currProgress = Math.round((receivedLength / +contentLength) * 100);

					if (currProgress > tempProgress) {
						this.setProgress(Math.round((receivedLength / +contentLength) * 100));
						tempProgress = currProgress;
					}
				} else {
					this.setProgress(-1);
					break;
				}

				// eslint-disable-next-line no-console
				// console.info(`Received ${receivedLength} of ${contentLength === 0 ? 'Unknown' : contentLength}`);
			}

			// Concatenate chunks into single Uint8Array
			const chunksAll = new Uint8Array(receivedLength);
			let position = 0;
			for (const chunk of chunks) {
				chunksAll.set(chunk, position);
				position += chunk.length;
			}

			// Put chunks into a blob
			return new Blob(chunks);
		}

		throw new Error('Error');
	}

	private start() {
		this.fetchFile(this.url, this.requestHeaders)
			.then((blob: Blob) => {
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.href = url;
				a.download = this.fileName;
				document.body.appendChild(a);
				a.click();
				a.remove();
			})
			.catch(() => {
				// NOOP
			});
	}
}

class FileUploader {
	public response$: Observable<FetchResponse | null>;
	private response = new BehaviorSubject<FetchResponse | null>(null);

	constructor(
		private url: string,
		private body: FormData,
		private headers: {[key: string]: string}
	) {
		this.response$ = this.response.asObservable();

		this.uploadFile();

		// eslint-disable-next-line no-console
		console.log('%c INIT SERVICE FILE UPLOADER ', 'background: green; color: #FFF');
	}

	private async uploadFile() {
		try {
			const response = await fetch(this.url, {
				method : 'POST',
				body : this.body,
				headers : this.headers,
				credentials : 'same-origin',
			});

			const data = await response.json();

			if (data.statusCode > 299) {
				this.response.error({ status : data.error, code : data.statusCode, message : data.message, });
			} else {
				this.response.next(data);
			}
			this.response.complete();
		} catch (error: any) { // eslint-disable-line @typescript-eslint/no-explicit-any
			this.response.error({ status : error.response, code : error.statusCode, message : error.message, });
		}
	}
}

// </editor-fold desc="File Class: Uploader, Downloader">

// <editor-fold desc="interfaces">

export interface InterceptorData {
	url: string;
	method: string;
	statusCode: number;
	message: string;
	response: unknown;
}

export enum HeaderType {
    'json',
    'urlencoded',
    'plain',
    'file',
}

export interface HttpError {
    note: string;
    statusCode: number;
    message: string;
    response?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export interface RequestHeaders {
	[key: string]: string;
}

export interface HeaderSchemas {
	[key: string]: string;
}

export interface RequestHeaderSchemas {
	[key: string]: RequestHeaders;
}

export type QueryParamsObject = Record<string, string | number | boolean | string[] | number[] | boolean[]>;

export type RequestQueryParams =
	string |
	URLSearchParams |
	QueryParamsObject |
	[string, string | number | boolean | string[] | number[] | boolean[]][];

export interface RequestOptions {
	body?: any; // eslint-disable-line  @typescript-eslint/no-explicit-any
	params?: RequestQueryParams;
	headers?: RequestHeaders;
	headerSchemas?: HeaderSchemas;
	ignoreDefaultHeaderSchema?: boolean;
	requestTimeout?: number;
	responseType?: XMLHttpRequestResponseType;
	rawResponse?: boolean;
}

// </editor-fold desc="interfaces">

export interface IHttpService {
    interceptor$: Observable<InterceptorData | null>;

	head(key: string, url: string, requestOptions?: Omit<RequestOptions, 'responseType' | 'params'>): Observable<unknown | undefined>;
	get<P>(key: string, url: string, requestOptions?: Omit<RequestOptions, 'body'>): Observable<P>;
	post<P>(key: string, url: string, requestOptions?: RequestOptions): Observable<P>;
	put<P>(key: string, url: string, requestOptions?: RequestOptions): Observable<P>;
	patch<P>(key: string, url: string, requestOptions?: RequestOptions): Observable<P>;
	delete<P>(key: string, url: string, requestOptions?: RequestOptions): Observable<P>;
	download(key: string, url: string, fileName: string, requestOptions?: Omit<RequestOptions, 'body'>): FileDownloader;
	upload(key: string, url: string, file: File, requestOptions: Omit<RequestOptions, 'body'>): FileUploader;

	setHeaderSchema(schema: string | 'default', key: string, value: string): this;
	setBearerAuthorizationHeader(schema: string | 'default', token: string): void;
	getHeaderSchemas(): RequestHeaderSchemas;
}

export class HttpService implements IHttpService {
	public static isHttpError(error: any): error is HttpError { // eslint-disable-line  @typescript-eslint/no-explicit-any
		return typeof error.statusCode === 'number';
	}

	public interceptor$: Observable<InterceptorData | null>;
	private interceptor = new BehaviorSubject<InterceptorData | null>(null);

	private readonly defaultTimeout = 3 * 1000;

	private headerSchemas: RequestHeaderSchemas = {
		default : {},
	};

	/**
	 * CONSTRUCTOR
	 */
	constructor() {
		this.interceptor$ = this.interceptor.asObservable();

		// eslint-disable-next-line no-console
		console.info('%c CREATED HttpService ', 'background: orange; color: #FFF');
	}

	// <editor-fold desc="headers">

	public setHeaderSchema(schema: string | 'default', key: string, value: string): this {
		this.headerSchemas[schema][key] = value;
		return this;
	}

	public setBearerAuthorizationHeader(schema: string | 'default', token: string): void {
		this.headerSchemas[schema].Authorization = `Bearer ${token}`;
	}

	public getHeaderSchemas(): RequestHeaderSchemas {
		return JSON.parse(JSON.stringify(this.headerSchemas));
	}

	// </editor-fold desc="headers">

	// <editor-fold desc="REST">

	/**
	 * HTTP HEAD
	 *
	 * @param {string} key
	 * @param {string} url
	 * @param {RequestOptions} requestOptions
	 * @returns {Observable<P>}
	 */
	public head(key: string, url: string, requestOptions: Omit<RequestOptions, 'responseType'>): Observable<unknown> {
		const validatedHeaders = this.validateHeaders(
			requestOptions.ignoreDefaultHeaderSchema,
			requestOptions.headers,
			requestOptions.headerSchemas
		);

		return ajax({
			method : 'HEAD',
			url : url,
			headers : validatedHeaders,
			queryParams : requestOptions.params || {},
		}).pipe(
			timeout({ each : requestOptions.requestTimeout || this.defaultTimeout, }),
			tap((response) => this.publishResponse(response)),
			map((response) => this.validateForSuccessfulResponse(requestOptions.rawResponse || false, response)),
			catchError<unknown, Observable<unknown>>(this.handleError<unknown>(key)),
			filter<unknown>((response): boolean => response !== undefined) // Stops empty responses when error is created from success stream
		);
	}

	/**
	 * HTTP GET
	 *
	 * @param {string} key
	 * @param {string} url
	 * @param {RequestOptions} requestOptions
	 * @returns {Observable<P>}
	 */
	public get<P>(key: string, url: string, requestOptions: Omit<RequestOptions, 'body'> = {}): Observable<P> {
		const validatedHeaders = this.validateHeaders(
			requestOptions.ignoreDefaultHeaderSchema,
			requestOptions.headers,
			requestOptions.headerSchemas
		);

		return ajax<P>({
			method : 'GET',
			url : url,
			headers : validatedHeaders,
			responseType : requestOptions.responseType || 'json',
			queryParams : requestOptions.params || {},
		}).pipe(
			timeout({ each : requestOptions.requestTimeout || this.defaultTimeout, }),
			tap((response) => this.publishResponse(response)),
			map((response) => this.validateForSuccessfulResponse(requestOptions.rawResponse || false, response)),
			catchError<P, Observable<P>>(this.handleError<P>(key)),
			filter<P>((response): boolean => response !== undefined) // Stops empty responses when error is created from success stream
		);
	}

	/**
	 * HTTP POST
	 *
	 * @param {string} key
	 * @param {string} url
	 * @param {RequestOptions} requestOptions
	 * @returns {Observable<P>}
	 */
	public post<P>(key: string, url: string, requestOptions: RequestOptions = {}): Observable<P> {
		const validatedHeaders = this.validateHeaders(
			requestOptions.ignoreDefaultHeaderSchema,
			requestOptions.headers,
			requestOptions.headerSchemas
		);

		return ajax<P>({
			method : 'POST',
			url : url,
			headers : validatedHeaders,
			responseType : requestOptions.responseType || 'json',
			queryParams : requestOptions.params || {},
			body : requestOptions.body || {},
		}).pipe(
			timeout({ each : requestOptions.requestTimeout || this.defaultTimeout, }),
			tap((response) => this.publishResponse(response)),
			map((response) => this.validateForSuccessfulResponse(requestOptions.rawResponse || false, response)),
			catchError<P, Observable<P>>(this.handleError<P>(key)),
			filter<P>((response): boolean => response !== undefined) // Stops empty responses when error is created from success stream
		);
	}

	/**
	 * HTTP PUT
	 *
	 * @param {string} key
	 * @param {string} url
	 * @param {RequestOptions} requestOptions
	 * @returns {Observable<P>}
	 */
	public put<P>(key: string, url: string, requestOptions: RequestOptions = {}): Observable<P> {
		const validatedHeaders = this.validateHeaders(
			requestOptions.ignoreDefaultHeaderSchema,
			requestOptions.headers,
			requestOptions.headerSchemas
		);

		return ajax<P>({
			method : 'PUT',
			url : url,
			headers : validatedHeaders,
			responseType : requestOptions.responseType || 'json',
			queryParams : requestOptions.params || {},
			body : requestOptions.body || {},
		}).pipe(
			timeout({ each : requestOptions.requestTimeout || this.defaultTimeout, }),
			tap((response) => this.publishResponse(response)),
			map((response) => this.validateForSuccessfulResponse(requestOptions.rawResponse || false, response)),
			catchError<P, Observable<P>>(this.handleError<P>(key)),
			filter<P>((response): boolean => response !== undefined) // Stops empty responses when error is created from success stream
		);
	}

	/**
	 * HTTP PATCH
	 *
	 * @param {string} key
	 * @param {string} url
	 * @param {RequestOptions} requestOptions
	 * @returns {Observable<P>}
	 */
	public patch<P>(key: string, url: string, requestOptions: RequestOptions = {}): Observable<P> {
		const validatedHeaders = this.validateHeaders(
			requestOptions.ignoreDefaultHeaderSchema,
			requestOptions.headers,
			requestOptions.headerSchemas
		);

		return ajax<P>({
			method : 'PATCH',
			url : url,
			headers : validatedHeaders,
			responseType : requestOptions.responseType || 'json',
			queryParams : requestOptions.params || {},
			body : requestOptions.body || {},
		}).pipe(
			timeout({ each : requestOptions.requestTimeout || this.defaultTimeout, }),
			tap((response) => this.publishResponse(response)),
			map((response) => this.validateForSuccessfulResponse(requestOptions.rawResponse || false, response)),
			catchError<P, Observable<P>>(this.handleError<P>(key)),
			filter<P>((response): boolean => response !== undefined) // Stops empty responses when error is created from success stream
		);
	}

	/**
	 * HTTP DELETE
	 *
	 * @param {string} key
	 * @param {string} url
	 * @param {RequestOptions} requestOptions
	 * @returns {Observable<P>}
	 */
	public delete<P>(key: string, url: string, requestOptions: RequestOptions = {}): Observable<P> {
		const validatedHeaders = this.validateHeaders(
			requestOptions.ignoreDefaultHeaderSchema,
			requestOptions.headers,
			requestOptions.headerSchemas
		);

		return ajax<P>({
			method : 'DELETE',
			url : url,
			headers : validatedHeaders,
			responseType : requestOptions.responseType || 'json',
			queryParams : requestOptions.params || {},
		}).pipe(
			timeout({ each : requestOptions.requestTimeout || this.defaultTimeout, }),
			tap((response) => this.publishResponse(response)),
			map((response) => this.validateForSuccessfulResponse(requestOptions.rawResponse || false, response)),
			catchError<P, Observable<P>>(this.handleError<P>(key)),
			filter<P>((response): boolean => response !== undefined) // Stops empty responses when error is created from success stream
		);
	}

	// </editor-fold desc="REST">

	// <editor-fold desc="file">

	public download(key: string, url: string, fileName: string, requestOptions: Omit<RequestOptions, 'body'> = {}): FileDownloader {
		const validatedHeaders = this.validateHeaders(
			requestOptions.ignoreDefaultHeaderSchema,
			requestOptions.headers,
			requestOptions.headerSchemas
		);

		return new FileDownloader(url, fileName, validatedHeaders);
	}

	public upload(key: string, url: string, file: File, requestOptions: Omit<RequestOptions, 'body'> = {}): FileUploader {
		const validatedHeaders = this.validateHeaders(
			requestOptions.ignoreDefaultHeaderSchema,
			requestOptions.headers,
			requestOptions.headerSchemas
		);

		// Removes 'content-type'
		Object.keys(validatedHeaders).forEach((headerKey) => {
			if (headerKey.toLowerCase() === 'content-type') {
				delete validatedHeaders[headerKey];
			}
		});

		const body = new FormData();
		body.append('file', file);

		return new FileUploader(url, body, validatedHeaders);
	}

	// </editor-fold desc="file">

	// <editor-fold desc="validation">

	private validateHeaders(
		ignoreDefaultHeaderSchema?: boolean,
		headers?: RequestHeaders,
		schemas?: HeaderSchemas
	): RequestHeaders {
		const validatedHeaders: RequestHeaders = (ignoreDefaultHeaderSchema === true) ? {} : this.headerSchemas.default;

		if (schemas) {
			Object.keys(schemas).forEach((schema) => {
				Object.keys(this.headerSchemas[schema]).forEach((headerKey: string) => {
					validatedHeaders[headerKey] = this.headerSchemas[schema][headerKey];
				});
			});
		}

		if (headers) {
			Object.keys(headers).forEach((headerKey: string) => {
				validatedHeaders[headerKey] = headers[headerKey];
			});
		}

		return validatedHeaders;
	}

	private validateForSuccessfulResponse<P>(rawResponse: boolean, response: AjaxResponse<P>): P {
		if (response.status >= 100 && response.status <= 399) {
			return rawResponse ? response as unknown as P : response.response as P;
		} else {
			throwError(() => {
				let errorMessage = 'HTTP error';

				if (response.status >= 400 && response.status <= 499) {
					errorMessage = 'HTTP Client error';
				}

				if (response.status >= 500 && response.status <= 599) {
					errorMessage = 'HTTP Server error';
				}

				return {
					statusCode : response.status,
					message : errorMessage,
					error : { error : errorMessage, },
					request : response.request,
					response : response.response,
				};
			});

			return undefined as unknown as P; // Fixes typing limitation
		}
	}

	// </editor-fold desc="validation">

	// <editor-fold desc="interceptor and error handling">

	private publishResponse(response: AjaxResponse<unknown>): void {
		this.interceptor.next({
			url : response.request.url || '',
			method : response.request.method || '',
			statusCode : response.status,
			message : 'Success',
			response : response.response,
		});
	}

	/**
	 * Handle Http operation that failed.
	 *
	 * @param operation - name of the operation that failed
	 *
	 * @returns {Observable<P>} Response
	 */
	private handleError<P>(operation = 'operation'): (error: AjaxError) => Observable<P> {
		return (error: AjaxError | Error): Observable<P> => {
			if (error instanceof AjaxError) {
				this.interceptor.next({
					url : error.request.url,
					method : error.request.method,
					statusCode : error.status,
					message : `${operation} method failed`,
					response : error.response,
				});

				return throwError(() => {
					return {
						note : `${operation} method failed`,
						statusCode : error.status,
						message : error.message,
						response : error.response,
					};
				});
			} else {
				this.interceptor.next({
					url : '',
					method : '',
					statusCode : -1,
					message : error.message,
					response : null,
				});

				return throwError(() => {
					return {
						note : `${operation} method failed`,
						statusCode : -1,
						message : error.message,
						response : null,
					};
				});
			}
		};
	}

	// </editor-fold desc="interceptor and error handling">
}
