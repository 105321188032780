











/**
 * Button
 *
 * @copyright Wallboard Inc. 2019
 * @version V1.0.0
 * @author  Gabor Fabian, David Fabian
 * @utf-8   ő
 */
import { Component, Vue, Prop } from 'vue-property-decorator';
import { StatusTypes, SizeTypes } from '@/services/common/ui.service';

@Component({
	name : 'wb-button',
})
export default class WbButton extends Vue {
	@Prop({ default : false, })
	public slotted!: boolean;

	@Prop({ default : '', })
	public label!: string;

	@Prop({ default : '', })
	public icon!: string;

	@Prop({ default : true, })
	public textWrap!: boolean;

	@Prop({ default : '', })
	public reference!: string;

	@Prop({ default : StatusTypes.primary, })
	public status!: string;

	@Prop({ default : SizeTypes.normal, })
	public size!: string;

	@Prop({ default : false, })
	public disabled!: boolean;

	public get containerClasses(): {[x: string]: boolean; nowrap: boolean} {
		return {
			[this.status] : !!this.status,
			[this.size] : !!this.size,
			nowrap : !this.textWrap,
		};
	}

	public created(): void {
		// eslint-disable-next-line no-console
		console.log('%c CREATE WbButton ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbButton ', 'background: purple; color: #FFF');
	}

	public onClick(): void {
		if (!this.disabled) {
			this.$emit('on-click');
		}
	}
}
