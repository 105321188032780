var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wb-checkbox",class:{'disabled' : _vm.model.disabled,},on:{"click":function($event){_vm.model.disabled ? function (){} : _vm.onInput()}}},[(_vm.model.value)?_c('span',{staticClass:"icon wbn wb-icon-check"}):_vm._e(),(
                _vm.model.status.attr === 'required' ||
                _vm.model.status.attr === 'email' ||
                _vm.model.status.attr === 'sameAs' ||
                _vm.model.status.attr === 'custom')?_c('wb-input-growl',{class:{
				'success' : _vm.model.status.class === _vm.statusTypes.success,
				'danger' : _vm.model.status.class === _vm.statusTypes.danger,
				// TODO: need to be refactored
				'left' : true,
			}},[_vm._v(" "+_vm._s(_vm.model.status.txt)+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }