var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wb-pagination"},[_c('div',{class:{
            disabled : !_vm.isBackArrowUsable,
            button : true,
            back : true,
        },on:{"click":function($event){return _vm.onClickGuard(_vm.pageNavigationTypes.PREVIOUS)}}},[_c('span',{staticClass:"wbn wb-icon-chevron-left"})]),(_vm.isNumberDotVisible)?[_c('div',{class:{
            disabled : !_vm.isMinUsable,
            number : true,
        },on:{"click":function($event){return _vm.onClickGuard(_vm.pageNavigationTypes.FIRST)}}},[_vm._v(_vm._s(_vm.min))]),_c('div',{class:{
            disabled : !_vm.isMinUsable,
            dots : true,
        }},[_vm._v("...")])]:_vm._e(),_c('wb-select',{attrs:{"model":_vm.model.fields.pageNumber},on:{"on-select":function (value) { return _vm.updateSelectedPage(Number(value)); }}}),(_vm.isNumberDotVisible)?[_c('div',{class:{
            disabled : !_vm.isMaxUsable,
            dots : true,
        }},[_vm._v("...")]),_c('div',{class:{
            disabled : !_vm.isMaxUsable,
            number : true,
        },on:{"click":function($event){return _vm.onClickGuard(_vm.pageNavigationTypes.LAST)}}},[_vm._v(_vm._s(_vm.max))])]:_vm._e(),_c('div',{class:{
            disabled : !_vm.isNextArrowUsable,
            button : true,
            back : true,
        },on:{"click":function($event){return _vm.onClickGuard(_vm.pageNavigationTypes.NEXT)}}},[_c('span',{staticClass:"wbn wb-icon-chevron-right"})])],2)}
var staticRenderFns = []

export { render, staticRenderFns }