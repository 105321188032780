








import { Component, Vue } from 'vue-property-decorator';

@Component({
	name : 'wb-app',
	components : {},
})
export default class WbApp extends Vue {
	/**
	 * CREATED
	 */
	public created(): void {
		// eslint-disable-next-line no-console
		console.info('%c CREATE WbApp ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbApp ', 'background: purple; color: #FFF');
	}
}
