












import { Component, Vue, Prop } from 'vue-property-decorator';
import { BusyIndicatorSizeTypes, BusyIndicatorTypes, BusyIndicatorModes } from '@/services/common/ui.service';

@Component({
	name : 'wb-busy-indicator',
})
export default class WbBusyIndicator extends Vue {
	@Prop({ default : BusyIndicatorModes.inner, })
	public mode!: string;

	@Prop({ default : BusyIndicatorTypes.img, })
	public type!: string;

	@Prop({ default : require('@/assets/images/busy-indicator/busyindicator.gif'), })
	public imgSrc!: string;

	@Prop({ default : 'wbn wb-icon-reload', })
	public icon!: string;

	@Prop({ default : BusyIndicatorSizeTypes.normal, })
	public size!: string;

	public busyIndicatorTypes = BusyIndicatorTypes;
	public busyIndicatorModes = BusyIndicatorModes;
	public busyIndicatorSizeTypes = BusyIndicatorSizeTypes;
	/**
	 * CREATED
	 */
	public created(): void {
		// eslint-disable-next-line no-console
		console.log('%c INIT uiElements.WbBusyIndicator ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		// eslint-disable-next-line no-console
		console.info('%c DESTROY uiElements.WbBusyIndicator ', 'background: purple; color: #FFF');
	}
}
