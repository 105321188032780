






import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
	name : 'wb-horizontal-menu-logo',
})
export default class WbHorizontalMenuLogo extends Vue {
	@Prop({ default : require('@/assets/images/logo.png'), })
	public imgSrc!: string;

	/**
	 * CREATED
	 */
	public created(): void {
		// eslint-disable-next-line no-console
		console.info('%c CREATE WbHorizontalMenuLogo ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbHorizontalMenuLogo ', 'background: purple; color: #FFF');
	}
}
