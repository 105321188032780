import { Observable } from 'rxjs';
import { HttpService } from '@/services/common/http.service';
import { UserRoles } from '@/services/payloads/user.payload';

export enum StatusOfConfirmEmail {
	none = 'none',
	success = 'success',
	invalidVerification = 'invalid_verification',
	verificationExpired = 'verification_expired'
}

export enum StatusOfChangePassword {
	none = 'none',
	success = 'success',
	invalidPublicKey = 'invalid_public_key',
	passwordTooShort = 'password_too_short',
	passwordWeak = 'password_weak'
}

// <editor-fold desc="DTOs">
export interface ILoginDto {
    email: string;
    password: string;
}

export interface IRegisterDto {
	organization: string;
	firstname: string;
	lastname: string;
	email: string;
	password: string;
	captchaToken: string;
}

export interface IForgottenPasswordDto {
	email: string;
	captchaToken: string;
}

export interface IChangePasswordDto {
	password: string;
	captchaToken: string;
}
// </editor-fold desc="DTOs">

// <editor-fold desc="Payloads">
export interface IJwtUserPayload {
	id: number;
	email: string;
	role: UserRoles;
}

export interface IAccessTokenPayload {
	'access_token': string;
	'refresh_token': string;
}

export interface IRegisterPayload {
	'access_token': string;
	'refresh_token': string;
}

export interface IConfirmEmailAddressPayload {
	'access_token': string;
	'refresh_token': string;
}

export interface IForgottenPasswordPayload {
	'status': string;
}

export interface IChangePasswordPayload {
	'status': StatusOfChangePassword;
}
// </editor-fold desc="Payloads">

export interface IAuthApiService {
    isLoggedIn: () => Observable<IJwtUserPayload>;
    login: (loginDto: ILoginDto) => Observable<IAccessTokenPayload>;
	refreshAccessToken(): Observable<unknown>;
	logout: () => Observable<unknown>;

	register(registerDto: IRegisterDto): Observable<IAccessTokenPayload>;
	confirmEmailAddress(token: string): Observable<IConfirmEmailAddressPayload>;
	requestEmailToResetPassword(forgottenPasswordDto: IForgottenPasswordDto): Observable<IForgottenPasswordPayload>;
	changePassword(changePasswordDto: IChangePasswordDto, token: string): Observable<IChangePasswordPayload>;
}

export class AuthApiService implements IAuthApiService {
	private $http: HttpService;

	private serviceRootUrl = '/api/auth';

	constructor(_http: HttpService) {
		this.$http = _http;
	}

	// Login - logout
	public isLoggedIn(): Observable<IJwtUserPayload> {
		return this.$http.get(
			'isLoggedIn',
			`${this.serviceRootUrl}/is-logged-in`
		);
	}

	public login(loginDto: ILoginDto): Observable<IAccessTokenPayload> {
		return this.$http.post(
			'login',
			`${this.serviceRootUrl}/login`,
			{
				body : loginDto,
			}
		);
	}

	public refreshAccessToken(): Observable<unknown> {
		return this.$http.post(
			'refresh',
			`${this.serviceRootUrl}/refresh`
		);
	}

	public logout(): Observable<unknown> {
		return this.$http.post(
			'logout',
			`${this.serviceRootUrl}/logout`
		);
	}

	// Registration
	public register(registerDto: IRegisterDto): Observable<IAccessTokenPayload> {
		return this.$http.post(
			'register',
			`${this.serviceRootUrl}/register`,
			{
				body : registerDto,
			}
		);
	}

	public confirmEmailAddress(token: string): Observable<IConfirmEmailAddressPayload> {
		return this.$http.put(
			'confirmEmailAddress',
			`/api/auth/verify/${token}`
		);
	}

	// Forgotten Password
	public requestEmailToResetPassword(forgottenPasswordDto: IForgottenPasswordDto): Observable<IForgottenPasswordPayload> {
		return this.$http.post(
			'requestEmailToResetPassword',
			`${this.serviceRootUrl}/forgotten-password`,
			{
				body : forgottenPasswordDto,
			}
		);
	}

	public changePassword(changePasswordDto: IChangePasswordDto, token: string): Observable<IChangePasswordPayload> {
		return this.$http.post(
			'changePassword',
			`${this.serviceRootUrl}/change-password/${token}`,
			{
				body : changePasswordDto,
			}
		);
	}
}
