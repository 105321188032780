/* forms */
import WbFormGroup from './forms/wb-form-group/wb-form-group.vue';
import WbFormItem from './forms/wb-form-item/wb-form-item.vue';
import WbFormLabel from './forms/wb-form-label/wb-form-label.vue';
import WbInputGroup from './forms/wb-input-group/wb-input-group.vue';
/* forms */

/* inputs */
import WbButton from './inputs/wb-button/wb-button.vue';
import WbButtonText from './inputs/wb-button-text/wb-button-text.vue';
import WbCheckbox from './inputs/wb-checkbox/wb-checkbox.vue';
import WbInput from './inputs/wb-input/wb-input.vue';
import WbAreaInput from './inputs/wb-area-input/wb-area-input.vue';
import WbDatePicker from './inputs/wb-date-picker/wb-date-picker.vue';
import WbSearchableInput from './inputs/wb-searchable-input/wb-searchable-input.vue';
import WbSelect from './inputs/wb-select/wb-select.vue';
import WbSelectSimple from './inputs/wb-select-simple/wb-select-simple.vue';
import WbSwitch from './inputs/wb-switch/wb-switch.vue';
/* inputs */

/* modals */
import WbConfirm from './modals/wb-confirm/wb-confirm.vue';

import WbModal from './modals/wb-modal/wb-modal.vue';
import WbModalBody from './modals/wb-modal/wb-modal-body/wb-modal-body.vue';
import WbModalFooter from './modals/wb-modal/wb-modal-footer/wb-modal-footer.vue';
import WbModalHeader from './modals/wb-modal/wb-modal-header/wb-modal-header.vue';
/* modals */

/* wb-panel */
import WbPanel from './wb-panel/wb-panel.vue';
import WbPanelHeader from './wb-panel/wb-panel-header/wb-panel-header.vue';
import WbPanelToolbar from './wb-panel/wb-panel-toolbar/wb-panel-toolbar.vue';
import WbPanelBody from './wb-panel/wb-panel-body/wb-panel-body.vue';
import WbPanelFooter from './wb-panel/wb-panel-footer/wb-panel-footer.vue';
/* wb-panel */

/* wb-sidebar */
import WbSidebar from '@/components/ui-elements/wb-sidebar/wb-sidebar.vue';
import WbSidebarItem from '@/components/ui-elements/wb-sidebar/wb-sidebar-item/wb-sidebar-item.vue';
/* wb-sidebar */

/* position */
import WbPositionElement from '@/components/ui-elements/wb-position-element/wb-position-element.vue';
/* position */

/* scroll */
import WbScrollElement from '@/components/ui-elements/wb-scroll-element/wb-scroll-element.vue';
/* scroll */

/* wb-card */
import WbCard from './wb-card/wb-card.vue';
import WbCardHeader from './wb-card/wb-card-header/wb-card-header.vue';
import WbCardBody from './wb-card/wb-card-body/wb-card-body.vue';
import WbCardFooter from './wb-card/wb-card-footer/wb-card-footer.vue';
/* wb-card */

/* wb-pagination */
import WbPagination from './wb-pagination/wb-pagination.vue';
/* wb-pagination */

/* wb-busy-indicator */
import WbBusyIndicator from './wb-busy-indicator/wb-busy-indicator.vue';
/* wb-busy-indicator */

import WbGrowl from './wb-growl/wb-growl.vue';

import WbHorizontalMenuContainer from '../../views/wb-app/wb-site/wb-horizontal-menu-container/wb-horizontal-menu-container.vue';

/* wb-transformable */
import WbTransformable from './wb-transformable/wb-transformable.vue';
/* wb-transformable */

export default {
	WbFormGroup,
	WbFormItem,
	WbFormLabel,
	WbInputGroup,

	WbButton,
	WbButtonText,
	WbCheckbox,
	WbInput,
	WbAreaInput,
	WbDatePicker,
	WbSearchableInput,
	WbSelect,
	WbSelectSimple,
	WbSwitch,

	WbConfirm,

	WbModal,
	WbModalBody,
	WbModalFooter,
	WbModalHeader,

	WbPositionElement,

	WbScrollElement,

	WbGrowl,

	WbBusyIndicator,

	WbPagination,

	WbPanel,
	WbPanelHeader,
	WbPanelToolbar,
	WbPanelBody,
	WbPanelFooter,

	WbSidebar,
	WbSidebarItem,

	WbCard,
	WbCardHeader,
	WbCardBody,
	WbCardFooter,

	WbHorizontalMenuContainer,

	WbTransformable,
};
