import Router, { Route } from 'vue-router';

import { AuthStoreService } from '@/services/auth-store.service';
import { IMenuService, MenuService } from '@/services/menu-store.service';
import { UserRoles } from '@/services/payloads/user.payload';

const WbSite = () => import(/* webpackChunkName: "WbSite" */ '../views/wb-app/wb-site/wb-site.vue');

const WbAuth = () => import(/* webpackChunkName: "WbSite" */ '../views/wb-app/auth/wb-auth.vue');
const WbLogin = () => import(/* webpackChunkName: "WbSite" */ '../views/wb-app/auth/wb-login/wb-login.vue');
const WbConfirmEmailAddress = () => import(/* webpackChunkName: "WbSite" */ '../views/wb-app/auth/wb-confirm-email-address/wb-confirm-email-address.vue');
const WbRegistration = () => import(/* webpackChunkName: "WbSite" */ '../views/wb-app/auth/wb-registration/wb-registration.vue');
const WbForgottenPassword = () => import(/* webpackChunkName: "WbSite" */ '../views/wb-app/auth/wb-forgotten-password/wb-forgotten-password.vue');
const WbChangePassword = () => import(/* webpackChunkName: "WbSite" */ '../views/wb-app/auth/wb-change-password/wb-change-password.vue');

const WbInfo = () => import(/* webpackChunkName: "WbInfo" */ '../views/wb-app/wb-info/wb-info.vue');
const WbCookiePolicy = () => import(/* webpackChunkName: "WbInfo" */ '../views/wb-app/wb-info/wb-cookie-policy/wb-cookie-policy.vue');
const WbDataPolicy = () => import(/* webpackChunkName: "WbInfo" */ '../views/wb-app/wb-info/wb-data-policy/wb-data-policy.vue');
const WbTermsAndConditions = () => import(/* webpackChunkName: "WbInfo" */ '../views/wb-app/wb-info/wb-terms-and-conditions/wb-terms-and-conditions.vue');

const WbRouteContainer = () => import(/* webpackChunkName: "WbSite" */ '../views/wb-app/wb-site/wb-route-container/wb-route-container.vue');

// Only admin
const WbAdminHome = () => import(/* webpackChunkName: "admin" */ '../views/wb-app/wb-site/admin/wb-admin-home/wb-admin-home.vue');
const WbAdminUsers = () => import(/* webpackChunkName: "admin" */ '../views/wb-app/wb-site/admin/wb-admin-users/wb-admin-users.vue');
const WbAdminPaymentHistory = () => import(/* webpackChunkName: "admin" */'../views/wb-app/wb-site/admin/wb-admin-payment-history/wb-admin-payment-history.vue');
const WbAdminBalance = () => import(/* webpackChunkName: "admin" */ '../views/wb-app/wb-site/admin/wb-admin-payment-history/wb-admin-balance/wb-admin-balance.vue');
const WbAdminOrders = () => import(/* webpackChunkName: "admin" */ '../views/wb-app/wb-site/admin/wb-admin-payment-history/wb-admin-orders/wb-admin-orders.vue');
const WbAdminAppsAndFirmwares = () => import(/* webpackChunkName: "admin" */ '../views/wb-app/wb-site/admin/wb-admin-apps-and-firmwares/wb-admin-apps-and-firmwares.vue');
const WbAdminApps = () => import(/* webpackChunkName: "admin" */ '../views/wb-app/wb-site/admin/wb-admin-apps-and-firmwares/wb-admin-apps/wb-admin-apps.vue');
const WbAdminFirmwares = () => import(/* webpackChunkName: "admin" */ '../views/wb-app/wb-site/admin/wb-admin-apps-and-firmwares/wb-admin-firmwares/wb-admin-firmwares.vue');
const WbAdminAndroidFirmwares = () => import(/* webpackChunkName: "admin" */ '../views/wb-app/wb-site/admin/wb-admin-apps-and-firmwares/wb-admin-android-firmwares/wb-admin-android-firmwares.vue');
const WbAdminPrices = () => import(/* webpackChunkName: "admin" */ '../views/wb-app/wb-site/admin/wb-admin-apps-and-firmwares/wb-admin-prices/wb-admin-prices.vue');

const WbAdminLog = () => import(/* webpackChunkName: "adminLog" */ '../views/wb-app/wb-site/admin/wb-admin-log/wb-admin-log.vue');
const WbSystemLog = () => import(/* webpackChunkName: "adminLog" */ '../views/wb-app/wb-site/admin/wb-admin-log/wb-admin-system-log/wb-admin-system-log.vue');
const WbUsersLog = () => import(/* webpackChunkName: "adminLog" */ '../views/wb-app/wb-site/admin/wb-admin-log/wb-admin-user-log/wb-admin-user-log.vue');

// Only User
const WbUserHome = () => import(/* webpackChunkName: "user" */ '../views/wb-app/wb-site/user/wb-user-home/wb-user-home.vue');

// For Both
const WbUserProfile = () => import(/* webpackChunkName: "WbSite" */ '../views/wb-app/wb-site/wb-user-profile/wb-user-profile.vue');

export class RouterService {
	private $auth: AuthStoreService;
	private $menu: IMenuService;
	private router: Router = new Router({
		mode : 'history',
	});

	constructor(
		_auth: AuthStoreService,
		_menu: MenuService
	) {
		// eslint-disable-next-line no-console
		console.info('%c INIT SERVICE ROUTER ', 'background: green; color: #FFF');

		this.$auth = _auth;
		this.$menu = _menu;

		this.init();
	}

	private async authenticationGuard(to: Route, from: Route, next: (dto: { name: 'logout' | 'wbLogin'; } | boolean | void) => void): Promise<void> {
		if ((!from || !from.name) && to && to.name) {
			next();
		} else {
			next(false);
			const isLoggedIn = this.$auth.getIsLoggedIn();

			if (isLoggedIn) {
				next({ name : 'logout', });
			} else {
				next({ name : 'wbLogin', });
			}
		}
	}

	private async isLoggedInGuard(to: Route, from: Route, next: (dto: { name: 'wbLogin'; } | void) => void): Promise<void> {
		const isLoggedIn = await this.$auth.getIsLoggedIn();

		if (isLoggedIn) {
			next();
		} else {
			next({ name : 'wbLogin', });
		}
	}

	private async alreadyLoggedInGuard(to: Route, from: Route, next: (dto: { name: 'wbSite'; } | void) => void): Promise<void> {
		const isLoggedIn = await this.$auth.getIsLoggedIn();

		if (isLoggedIn) {
			next({ name : 'wbSite', });
		} else {
			next();
		}
	}

	private async roleGuard(to: Route, from: Route, next: (dto: { name: 'wbLogin'; } | void) => void, requiredRoles: UserRoles[]): Promise<void> {
		const userRole = await this.$auth.getUserRole();

		if (requiredRoles.indexOf(userRole) > -1) {
			await this.$menu.reload();
			next();
		} else {
			next({ name : 'wbLogin', });
		}
	}

	private init() {
		this.router = new Router({
			mode : 'history',
			base : '/',
			routes : [
				{ path : '*', name : 'default', redirect : { name : 'wbSite', }, },
				{
					path : '/auth',
					name : 'wbAuth',
					component : WbAuth,
					beforeEnter : (to: Route, from: Route, next: () => void) => {
						return this.alreadyLoggedInGuard(to, from, next);
					},
					children : [
						{
							path : 'login',
							name : 'wbLogin',
							component : WbLogin,
						},
						{
							path : 'confirm-email-address/:token',
							name : 'wbConfirmEmailAddress',
							component : WbConfirmEmailAddress,
						},
						{
							path : 'registration',
							name : 'wbRegistration',
							component : WbRegistration,
						},
						{
							path : 'forgotten-password',
							name : 'wbForgottenPassword',
							component : WbForgottenPassword,
						},
						{
							path : 'new-password/:token',
							name : 'wbNewPassword',
							component : WbChangePassword,
						},
						{
							path : 'set-password/:token',
							name : 'wbChangePassword',
							component : WbChangePassword,
							meta : { type : 'addUser', },

						}
					],
				},
				{
					path : '/info',
					name : 'wbInfo',
					component : WbInfo,
					redirect : { name : 'wbCookiePolicy', },
					children : [
						{
							path : 'cookie-policy',
							name : 'wbCookiePolicy',
							component : WbCookiePolicy,
						},
						{
							path : 'data-policy',
							name : 'wbDataPolicy',
							component : WbDataPolicy,
						},
						{
							path : 'terms-and-conditions',
							name : 'wbTermsAndConditions',
							component : WbTermsAndConditions,
						}
					],
				},
				{
					path : '/site',
					name : 'wbSite',
					component : WbSite,
					beforeEnter : (to: Route, from: Route, next: () => void) => {
						return this.isLoggedInGuard(to, from, next);
					},
					children : [
						// Admin Routes
						{
							path : 'admin',
							name : 'admin',
							component : WbRouteContainer,
							children : [
								{ path : 'home', name : 'adminHome', component : WbAdminHome, },
								{ path : 'users', name : 'adminUsers', component : WbAdminUsers, },
								{
									path : 'payment-history',
									name : 'adminPaymentHistory',
									component : WbAdminPaymentHistory,
									redirect : { name : 'adminBalance', },
									children : [
										{ path : 'balance', name : 'adminBalance', component : WbAdminBalance, },
										{ path : 'orders', name : 'adminOrders', component : WbAdminOrders, }
									],
								},
								{
									path : 'apps-and-firmwares',
									name : 'adminAppsAndFirmwares',
									component : WbAdminAppsAndFirmwares,
									redirect : { name : 'adminApps', },
									children : [
										{ path : 'apps', name : 'adminApps', component : WbAdminApps, },
										{ path : 'firmwares', name : 'adminFirmwares', component : WbAdminFirmwares, },
										{ path : 'android-firmwares', name : 'adminAndroidFirmwares', component : WbAdminAndroidFirmwares, },
										{ path : 'prices', name : 'adminPrices', component : WbAdminPrices, }
									],
								},
								{
									path : 'log',
									name : 'adminLog',
									component : WbAdminLog,
									redirect : { name : 'systemLog', },
									children : [
										{ path : 'system', name : 'systemLog', component : WbSystemLog, },
										{ path : 'user', name : 'userLog', component : WbUsersLog, }
									],
								},
								{ path : 'profile', name : 'adminProfile', component : WbUserProfile, }
							],
							beforeEnter : (to: Route, from: Route, next: () => void) => {
								return this.roleGuard(to, from, next, [UserRoles.SUPERADMIN, UserRoles.ADMIN]);
							},
						},
						// User Routes
						{
							path : 'user',
							name : 'user',
							component : WbRouteContainer,
							children : [
								{ path : 'home', name : 'userHome', component : WbUserHome, },
								{ path : 'profile', name : 'userProfile', component : WbUserProfile, }
							],
							beforeEnter : (to: Route, from: Route, next: () => void) => {
								return this.roleGuard(to, from, next, [UserRoles.USER]);
							},
						}
					],
				}
			],
		});
	}

	public getRouter(): Router {
		return this.router;
	}
}
