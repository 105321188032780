import { Observable } from 'rxjs';

import {
	AndroidFirmwareType,
	IAndroidFirmware
} from '@/services/app-and-firmware-store.service';
import { HttpService, FetchResponse } from '@/services/common/http.service';

import { IListDto } from '@/services/dtos/shared.dto';
import { IStatusOkPayload } from '@/services/payloads/status.payload';

// <editor-fold desc="DTOs">
export interface IListAndroidFirmwaresDto extends IListDto {
	showDisabled?: boolean;
}

export interface IModifyAndroidFirmwareDto {
	id: number;
	name: string;
	version: string;
	description: string;
	isLatest: boolean;
	type: AndroidFirmwareType;
}

export interface IListOneAndroidFirmwareDto {
	id: number;
}

export interface IToggleAndroidFirmwareDto {
	id: number;
}

export interface IDeleteAndroidFirmwareDto {
	id: number;
}
// </editor-fold desc="DTOs">

// <editor-fold desc="Payloads">
export interface IAndroidFirmwarePayload {
	id: number;
	name: string;
	type: AndroidFirmwareType;
	version: string;
	originalFilename: string;
	description: string;
	size: number;
	isLatest: boolean;
	isEnabled: boolean;
	createdAt: Date;
}

export interface IListAndroidFirmwaresPayload {
	count: number;
	androidFirmwares: IAndroidFirmware[];
}

export interface IListOneAndroidFirmwarePayload {
	id: number;
	name: string;
	type: AndroidFirmwareType;
	version: string;
	originalFilename: string;
	description: string;
	size: number;
	isLatest: boolean;
	isEnabled: boolean;
	createdAt: Date;
}
// </editor-fold desc="Payloads">

export interface IAndroidFirmwareApiService {
	getAndroidFirmwares(
		listAndroidFirmwaresDto: Partial<IListAndroidFirmwaresDto> & Pick<IListAndroidFirmwaresDto, 'pageLength' | 'page'>
	): Observable<IListAndroidFirmwaresPayload>;
	getOneAndroidFirmware(listOneAndroidFirmwaresDto: IListOneAndroidFirmwareDto): Observable<IListOneAndroidFirmwarePayload>;

	modifyAndroidFirmware(modifyAndroidFirmwareDto: Partial<IModifyAndroidFirmwareDto>): Observable<IAndroidFirmwarePayload>;

	enableAndroidFirmware(androidFirmwareDto: IToggleAndroidFirmwareDto): Observable<IStatusOkPayload>;
	disableAndroidFirmware(androidFirmwareDto: IToggleAndroidFirmwareDto): Observable<IStatusOkPayload>;

	uploadAndroidFirmware(file: File): Observable<FetchResponse | null>;
	deleteAndroidFirmware(deleteAndroidFirmwareDto: IDeleteAndroidFirmwareDto): Observable<IStatusOkPayload>;
}

export class AndroidFirmwareApiService implements IAndroidFirmwareApiService {
	private serviceRootUrl = '/api/android-firmware';

	constructor(
		private readonly $http: HttpService
	) {
		// eslint-disable-next-line no-console
		console.info('%c INIT ANDROID FIRMWARE API SERVICE ', 'background: green; color: #FFF');

		this.fixScope();
		this.initState();
	}

	private fixScope(): void {
		// I know it is empty
	}

	private initState(): void {
		// I know it is empty
	}

	public getAndroidFirmwares(
		listAndroidFirmwaresDto: Partial<IListAndroidFirmwaresDto> & Pick<IListAndroidFirmwaresDto, 'pageLength' | 'page'>
	): Observable<IListAndroidFirmwaresPayload> {
		return this.$http.get<IListAndroidFirmwaresPayload>(
			'getAndroidFirmwares',
			this.serviceRootUrl,
			{
				params : listAndroidFirmwaresDto,
			}
		);
	}

	public getOneAndroidFirmware(listOneAndroidFirmwareDto: IListOneAndroidFirmwareDto): Observable<IListOneAndroidFirmwarePayload> {
		return this.$http.get(
			'getAndroidFirmware',
			`${this.serviceRootUrl}/${listOneAndroidFirmwareDto.id}`
		);
	}

	public modifyAndroidFirmware(modifyAndroidFirmwareDto: Partial<IModifyAndroidFirmwareDto>): Observable<IAndroidFirmwarePayload> {
		return this.$http.put(
			'modifyAndroidFirmware',
			`${this.serviceRootUrl}/${modifyAndroidFirmwareDto.id}`,
			{
				body : modifyAndroidFirmwareDto,
			}
		);
	}

	public enableAndroidFirmware(toggleAndroidFirmwareDto: IToggleAndroidFirmwareDto): Observable<IStatusOkPayload> {
		return this.$http.put(
			'enableAndroidFirmware',
			`${this.serviceRootUrl}/enable/${toggleAndroidFirmwareDto.id}`
		);
	}

	public disableAndroidFirmware(toggleAndroidFirmwareDto: IToggleAndroidFirmwareDto): Observable<IStatusOkPayload> {
		return this.$http.put(
			'disableAndroidFirmware',
			`${this.serviceRootUrl}/disable/${toggleAndroidFirmwareDto.id}`
		);
	}

	public uploadAndroidFirmware(file: File): Observable<FetchResponse |null> {
		return this.$http.upload(
			'uploadAndroidFirmware',
			this.serviceRootUrl,
			file
		).response$;
	}

	public deleteAndroidFirmware(deleteAndroidFirmwareDto: IDeleteAndroidFirmwareDto): Observable<IStatusOkPayload> {
		return this.$http.delete(
			'uploadAndroidFirmware',
			`${this.serviceRootUrl}/${deleteAndroidFirmwareDto.id}`
		);
	}
}
