

























































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { IModelValue, IOption } from '@/services/common/form-validate.service';
import wbInputGrowl from '../wb-input-growl/wb-input-growl.vue';

enum StatusTypes {
	success = 'success',
	danger = 'error',
}

@Component({
	components : { wbInputGrowl, },
})
export default class WbSearchableInput extends Vue {
	public statusTypes = StatusTypes;

	@Prop()
	public model!: IModelValue;

	@Prop({ default : '', })
	public iconLeft!: string;

	@Prop({ default : '', })
	public textLeft!: string;

	@Prop({ default : '', })
	public iconRight!: string;

	@Prop({ default : '', })
	public textRight!: string;

	public inputType = 'text';
	public isOptionsVisible = false;

	public created(): void {
		// eslint-disable-next-line no-console
		console.log('%c INIT uiElements.Inputs.WbInput ', 'background: blue; color: #FFF');
	}

	public onFocus(): void {
		this.isOptionsVisible = true;
		this.$emit('open');
	}

	public handleInput(event: Event): void {
		const inputValue = (event.target as HTMLInputElement).value;
		if (this.model.type === 'int') {
			(event.target as HTMLInputElement).value = this.filterInt(inputValue);
		} else if (this.model.type === 'uInt') {
			(event.target as HTMLInputElement).value = this.filterInt(inputValue, true);
		} else if (this.model.type === 'float') {
			(event.target as HTMLInputElement).value = this.filterFloat(inputValue);
		} else if (this.model.type === 'uFloat') {
			(event.target as HTMLInputElement).value = this.filterFloat(inputValue, true);
		}

		this.isOptionsVisible = true;
		this.model.value = '';

		this.$emit('on-input', (event.target as HTMLInputElement).value);
	}

	public get sortedOptions(): IOption[] {
		const sortedOptions: IOption[] = [];

		if (this.model.componentValue && this.model.options) {
			this.model.options.forEach((option: IOption) => {
				if (
					String(option.label)
						.toLowerCase()
						.indexOf(`${this.model.componentValue}`.toLowerCase()) >= 0
				) {
					sortedOptions.push(option);
				}
			});

			return sortedOptions;
		} else {
			return this.model.options || [];
		}
	}

	public selectOptionValue(option: IOption): void {
		this.$emit('on-select', option.value);
		this.model.componentValue = option.label;
		this.isOptionsVisible = false;
	}

	public closeOptions(): void {
		setTimeout(() => {
			let matchingOption: IOption = { label : '', value : 0, };

			if (this.model.options && this.model.componentValue) {
				this.model.options.forEach((option: IOption) => {
					if (String(option.label).toLowerCase() === String(this.model.componentValue).toLowerCase()) {
						matchingOption = option;
					}
				});
			}

			if (matchingOption && matchingOption.value) {
				this.model.componentValue = matchingOption.label;
				this.model.value = matchingOption.value;
			} else {
				this.model.componentValue = '';
				this.model.value = '';
			}

			this.isOptionsVisible = false;
			this.$emit('close');
		}, 200);
	}

	public handleKeyDown(event: KeyboardEvent): void {
		if (event.which === 13) {
			this.$emit('on-enter');
		}
	}

	private filterInt(value: string | string, unsigned = false): string {
		const patternZeros = /^(0+[1-9][0-9]*)$/;
		const pattern = (unsigned === true) ? /^(0|[1-9][0-9]*)$/ : /^(-|0|0-|-*[1-9][0-9]*)$/;

		let filteredValue: number | string = +value;

		// Filter n first zero
		if (patternZeros.test(value)) {
			value = value.replace(/^[0]+/g, '');
			filteredValue = +value;
		}

		if (unsigned === false && (value === '0-' || value === '-')) {
			filteredValue = '-';
		} else if (!pattern.test(value) && value !== '') {
			// Filter all character except 0-9 and -
			const signOfTheNumber = (value.charAt(0) === '-' && unsigned === false) ? -1 : 1;

			filteredValue = +value.replace(/[^0-9]/g, '');
			filteredValue = signOfTheNumber * filteredValue;
		}

		return '' + filteredValue;
	}

	private filterFloat(value: string | string, unsigned = false): string {
		const patternZeros = /^(0+[1-9][0-9]*)$/;
		const pattern = (unsigned === true)
			? /^(0|0\.[0-9]*|[1-9][0-9]*|[1-9][0-9]*\.[0-9]*)$/
			: /^(-|0|0-|-{0,1}[1-9][0-9]*|-{0,1}[1-9][0-9]*\.[0-9]*|-{0,1}0\.[0-9]*)$/;
		const patternPart = (unsigned === true)
			? /^(0\.0*|[1-9][0-9]*\.0*|0\.0*|[1-9][0-9]*\.|0\.0*|[1-9][0-9]*\.[0-9]*0+)$/
			: /^(-|-0|-{0,1}0\.0*|-{0,1}[1-9][0-9]*\.|-{0,1}[1-9][0-9]*\.[0-9]*0+)$/;

		let filteredValue: number | string = +value;

		// Filter n first zero
		if (patternZeros.test(value)) {
			value = value.replace(/^[0]+/g, '');
			filteredValue = +value;
		}

		// Fix , as .
		if (value.indexOf(',') > -1) {
			value = value.replace(/,/g, '.');
		}

		if (unsigned === false && (value === '0-' || value === '-')) {
			// returns -
			filteredValue = '-';
		} else if (patternPart.test(value)) {
			// returns -, -0, 0.0..., -0.0...
			filteredValue = value;
		} else if (!pattern.test(value) && value !== '') {
			// Filter all character except 0-9, - and first dot
			const signOfTheNumber = (value.charAt(0) === '-' && unsigned === false) ? -1 : 1;
			const firstDot = value.search(/\./) + 1; // Index of first occurrence of (.)

			// Clear all dots except the first
			value = value.substr(0, firstDot) + value.slice(firstDot).replace(/\./g, '');

			// Clear all character except 0-9, .
			filteredValue = +value.replace(/[^0-9.]/g, '');
			filteredValue = signOfTheNumber * filteredValue;
		}

		return '' + filteredValue;
	}

	@Watch('model', { immediate : true, deep : true, })
	private onModelChanged(val: IModelValue /* , oldVal: IModelValue */): void {
		if (val.focus !== undefined && val.focus === true) {
			(this.$refs.input as HTMLInputElement).focus();
		}
	}
}
